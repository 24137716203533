import React from "react";
import { Button, OverlayTrigger, Tooltip as BootstrapTooltip, Form, ButtonGroup } from "react-bootstrap";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import preval from "preval.macro";

import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  dispatchGrayMap,
  dispatchLoginState,
  dispatchShowTooltip,
  resetState,
  setMapAutorotation,
  showInitialModal,
} from "../redux/actions";
import { selectDriverData, selectGrayMap, selectSetMapAutorotation, selectShowTooltip } from "../redux/selectors";
import TopInfoComponent from "../layout/TopInfoComponent";
import variableColors from "../_App.module.scss";
import useNetworkStatus from "../services/useNetworkStatus";
import infoIcon from "../assets/icons/infoIcon.svg";

const { textColor, appBackgroundColor, secondaryColor } = variableColors;

const HR = styled.hr`
  border-top: 2px dotted ${secondaryColor};
  margin: 0.5rem auto;
`;

//* Container for all Main Components (Pages)
export const ComponentContainer = styled.div<{ $isMobile: boolean }>`
  overflow-y: auto;
  padding-bottom: ${(props) => (props.$isMobile ? "var(--constantFooterHeight)" : "50px")};
  max-height: calc(100vh - var(--constantFooterHeight) - var(--constantHeaderHeight));
  min-height: ${(props) =>
    props.$isMobile
      ? "calc(100vh - var(--constantFooterHeight) - var(--constantHeaderHeight) - 100px)"
      : "calc(100vh - var(--constantFooterHeight) - var(--constantHeaderHeight))"};
  margin: 0 0.5rem;
  width: calc(100% - 1rem);
  margin-left: auto;
  margin-right: auto;
  max-width: 1024px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  gap: 1rem;
  background-color: ${appBackgroundColor};
  color: ${textColor};
  h2 {
    padding-top: 2rem;
  }
  position: relative;
  div.version-info {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    text-align: center;
    p {
      margin-bottom: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-content: center;
      gap: 0.25rem;
    }
  }
`;

const ButtonContainer = styled.div`
  margin-top: 1.5rem;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  form {
    margin-top: 1rem;
  }
`;

const Settings = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch: Dispatch = useAppDispatch();
  const { isMobile } = useNetworkStatus();

  const grayMapFromRedux: boolean = useAppSelector<boolean>(selectGrayMap);
  const showTooltipFromRedux: boolean = useAppSelector<boolean>(selectShowTooltip);
  const driverDataFromRedux: DriverData = useAppSelector<DriverData>(selectDriverData);
  const mapAutorotationFromRedux: boolean = useAppSelector<boolean>(selectSetMapAutorotation);

  const [grayMap, setGrayMap] = React.useState<boolean>(grayMapFromRedux || false);
  const [showBusTooltip, setShowBusTooltip] = React.useState<boolean>(showTooltipFromRedux || false);
  const [driverName, setDriverName] = React.useState<string>("");
  const [isMapAutoRotated, setIsMapAutoRotated] = React.useState<boolean>(mapAutorotationFromRedux || false);
  // console.log({ isMapAutoRotated });

  // const dateTimeStamp = preval`module.exports = new Date().toLocaleString("pl-PL");`;
  // console.log({ dateTimeStamp });

  // Logout
  const onLogout = async () => {
    await dispatch(dispatchLoginState(false));
    // await localStorage.removeItem("tableData");
    await localStorage.clear();
    await dispatch(resetState());
    await navigate("/");
    await window.location.reload();
  };

  //* Driver's name
  React.useEffect(() => {
    if (driverDataFromRedux && Object.keys(driverDataFromRedux)) {
      const firstName = driverDataFromRedux.first_name;
      const lastName = driverDataFromRedux.last_name;
      const fullName = `${firstName} ${lastName}`;
      setDriverName(fullName);
    }
  }, [driverDataFromRedux]);

  //* Dispatching switches to the Redux state (x3)
  React.useEffect(() => {
    dispatch(dispatchGrayMap(grayMap));
  }, [dispatch, grayMap]);

  React.useEffect(() => {
    dispatch(dispatchShowTooltip(showBusTooltip));
  }, [dispatch, showBusTooltip]);

  React.useEffect(() => {
    dispatch(setMapAutorotation(isMapAutoRotated));
  }, [dispatch, isMapAutoRotated]);

  return (
    <React.Fragment>
      <ComponentContainer $isMobile={isMobile}>
        <TopInfoComponent fontSize="90%" />
        <h2 style={{ textAlign: "center" }}>
          <span className="text-muted">Hello</span> <span className="span_bold">{driverName}</span>
        </h2>
        <ButtonContainer>
          <ButtonGroup>
            <Button
              onClick={async () => {
                await dispatch(showInitialModal(true));
                await navigate("/map");
              }}
              variant="warning"
            >
              Change Bus
            </Button>
            <Button onClick={onLogout} variant="danger">
              Logout
            </Button>
          </ButtonGroup>

          <Form>
            <OverlayTrigger
              placement={"auto"}
              trigger={["hover", "focus"]}
              overlay={<BootstrapTooltip id={"gray-map-tooltip"}>Gray Map</BootstrapTooltip>}
            >
              <Form.Check
                type="switch"
                id={"gray-map-switch"}
                label={"Grey Map"}
                onChange={() => setGrayMap(!grayMap)}
                checked={grayMap}
              />
            </OverlayTrigger>

            <OverlayTrigger
              placement={"auto"}
              trigger={["hover", "focus"]}
              overlay={<BootstrapTooltip id={"show-tooltips-tooltip"}>Show Tooltips</BootstrapTooltip>}
            >
              <Form.Check
                type="switch"
                id="show-tooltips-switch"
                label={"Show tooltip"}
                onChange={() => setShowBusTooltip(!showBusTooltip)}
                checked={showBusTooltip}
              />
            </OverlayTrigger>

            <OverlayTrigger
              placement={"auto"}
              trigger={["hover", "focus"]}
              overlay={<BootstrapTooltip id={"auto-rotate-tooltip"}>Auto rotate map</BootstrapTooltip>}
            >
              <Form.Check
                type="switch"
                id="auto-rotate-switch"
                label={"Auto rotate map"}
                onChange={() => setIsMapAutoRotated(!isMapAutoRotated)}
                checked={isMapAutoRotated}
              />
            </OverlayTrigger>
          </Form>
        </ButtonContainer>

        <div className="version-info">
          <HR />
          <p className="text-muted">
            Build Date: <span className="span_bold">{preval`module.exports = new Date().toLocaleString()`}</span>
          </p>
          <p className="text-muted small">
            <img src={infoIcon} alt="Info Icon" height={16} width={"auto"} /> Icons were taken from:
            <Button variant="link" href="https://www.iconfinder.com" target="_blank" size="sm">
              https://www.iconfinder.com
            </Button>
          </p>
        </div>
      </ComponentContainer>
    </React.Fragment>
  );
};

export default Settings;
