import React from "react";
import { Button, Modal, ProgressBar } from "react-bootstrap";
import styled from "styled-components";

import { showInformationModal } from "../redux/actions";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { selectCalculatedTripStatus, selectCurrentBusData, selectRouteInfo } from "../redux/selectors";
import directionIcon from "../assets/icons/directionIcon.svg";
import pauseIcon from "../assets/icons/pauseIcon.svg";
import { timeFromNow, timestampToString } from "../utils/helpers";
import NoBusInfo from "./NoBusInfo";
import variableColors from "../_App.module.scss";
import ProgressBarWithPoints from "./ProgressBarWithPoints";
import { lengthUnit } from "../config";

const { textColor, darkColor } = variableColors;

const HR = styled.hr`
  border-top: 2px dotted ${darkColor};
  margin: 2px auto;
  width: 100%;
`;

const ProgressDiv = styled.div`
  width: 100%;
  margin: 0.25rem auto;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  color: ${textColor};
  p.text-muted {
    font-size: 90%;
    font-style: italic;
  }
  div.row-1 {
    width: 100%;
  }
  div.row-2 {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    div {
      font-weight: bold;
    }
  }
`;

export const VehicleInfoBlock = styled.div`
  color: ${textColor};
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  justify-items: center;
  gap: 1px;
  p {
    margin-bottom: 0;
    &:hover {
      font-style: italic;
      font-size: 105%;
    }
  }
`;

const InfoModal = ({ showInfoModal }: { showInfoModal: boolean }): JSX.Element => {
  const dispatch: Dispatch = useAppDispatch();

  const currentBusDataFromRedux: BusInfo = useAppSelector<BusInfo>(selectCurrentBusData);
  const currentRouteDetails: RouteDetails = useAppSelector<RouteDetails>(selectRouteInfo);
  const calculatedTripStatusFromRedux: CalculatedTripInfo = useAppSelector<CalculatedTripInfo>(selectCalculatedTripStatus);
  // const currentTripInfo: TripInfo = useAppSelector<TripInfo>(selectTripInfo);

  const [currentBusData, setCurrentBusData] = React.useState<BusInfo | null>(null);
  const [routeInfo, setRouteInfo] = React.useState<RouteInfo | null>(null);
  //^ Calculation of current stop -> unnecessary
  const [calculatedTripStatus, setCalculatedTripStatus] = React.useState<CalculatedTripInfo | null>(null);
  // console.log("currentTripInfo:", currentTripInfo);

  //* Set route info
  React.useEffect(() => {
    if (currentRouteDetails) {
      setRouteInfo(currentRouteDetails?.routeInfo);
    }
  }, [currentRouteDetails]);

  //* Set currentBusDataFromRedux
  React.useEffect(() => {
    if (currentBusDataFromRedux && Object.keys(currentBusDataFromRedux).length) {
      setCurrentBusData(currentBusDataFromRedux);
    }
  }, [currentBusDataFromRedux]);

  //* Set calculatedTripStatusFromRedux
  React.useEffect(() => {
    if (calculatedTripStatusFromRedux && Object.keys(calculatedTripStatusFromRedux).length) {
      setCalculatedTripStatus(calculatedTripStatusFromRedux);
    }
  }, [calculatedTripStatusFromRedux]);

  //^ Calculation of current stop -> unnecessary
  // React.useEffect(() => {
  //   if (currentTripInfo && Object.keys(currentTripInfo).length && currentBusDataFromRedux) {
  //     const { schedule } = currentTripInfo;
  //     const nextStopId = currentBusDataFromRedux?.nextStopId;
  //     const nextStopName = currentBusDataFromRedux?.nextStopName;
  //     const nextStopIndex = schedule.findIndex((stop) => stop.stopId === nextStopId && stop.stopName === nextStopName);
  //     // console.log({ nextStopIndex });
  //     const currentStopIndex = nextStopIndex - 1;
  //     const currentStop = schedule[currentStopIndex];
  //     // console.log("currentStop:", currentStop.stopId, currentStop.stopName);
  //     const nextStop = schedule[nextStopIndex];
  //     // console.log("nextStop:", nextStop.stopId, nextStop.stopName);
  //   }
  // }, [currentBusDataFromRedux, currentTripInfo]);

  const BusInfoBodyModal = (
    currentBus: BusInfo,
    routeInformation: RouteInfo,
    calculatedTripStatus: CalculatedTripInfo
  ): JSX.Element => {
    return (
      <VehicleInfoBlock>
        <p>
          Route &diams; vehicle status:{" "}
          {routeInformation ? (
            <span
              className="span_bold"
              style={{
                display: "inline-block",
                textAlign: "center",
                minWidth: "40px",
                color: routeInformation?.textColor,
                backgroundColor: routeInformation?.color,
                padding: "3px 6px",
                fontSize: "1rem",
              }}
            >
              {currentBus.routeShortName || currentBus.routeId || "n/a"}
            </span>
          ) : (
            <span className="span_bold">n/a</span>
          )}{" "}
          &diams;{" "}
          {currentBus?.loc?.heading || currentBus?.loc?.heading === 0 ? (
            <img
              width={30}
              height={30}
              src={currentBus?.isAtStop === true ? pauseIcon : directionIcon}
              alt="Pause/Direction Icon"
              style={{
                transform:
                  currentBus?.isAtStop === false && currentBus?.loc?.heading ? `rotate(${currentBus.loc.heading}deg)` : "",
              }}
            />
          ) : (
            <span className="span_bold">n/a</span>
          )}
        </p>
        <HR />

        <ProgressDiv>
          <p className="text-muted">Trip progress</p>
          <div className="row-1">
            {/* //^ ProgressBar -> React Bootstrap */}
            <ProgressBar
              animated={true}
              striped={true}
              variant={
                currentBus.addedStatus === "busToFast"
                  ? "danger"
                  : currentBus.addedStatus === "busOnTime"
                  ? "success"
                  : currentBus.addedStatus === "delayedBus"
                  ? "warning"
                  : "secondary"
              }
              min={0}
              max={100}
              now={calculatedTripStatus.busPositionDistanceCoveredPercentage}
            />
          </div>
          <div className="row-2">
            <div>{calculatedTripStatus.busPositionDistanceCoveredPercentageString}</div>
            <div>{`${calculatedTripStatus.busPositionPolylineDistanceCovered.toFixed(
              2
            )}/${calculatedTripStatus.currentPolylineLength.toFixed(2)} ${lengthUnit}`}</div>
          </div>

          {/* //^ ProgressBar -> Points */}
          <p className="text-muted">Stops progress</p>
          <ProgressBarWithPoints percent={calculatedTripStatus?.busPositionDistanceCoveredPercentage} />
        </ProgressDiv>

        <HR />
        <p>
          Block: <span className="span_bold">{currentBus.block || "n/a"}</span>
        </p>
        <p>
          Block start: <span className="span_bold">{currentBus?.blockInfo?.startTime ?? "n/a"}</span>
        </p>
        <p>
          Block end: <span className="span_bold">{currentBus?.blockInfo?.endTime ?? "n/a"}</span>
        </p>
        <HR />
        <p>
          Trip (direction): <span className="span_bold">{currentBus?.trip || "n/a"}</span> (
          <span className="span_bold">{currentBus.direction || "n/a"}</span>)
        </p>
        <p>
          Trip start: <span className="span_bold">{currentBus?.blockInfo?.trip?.startTime ?? "n/a"}</span>
        </p>
        <p>
          Trip end: <span className="span_bold">{currentBus?.blockInfo?.trip?.endTime ?? "n/a"}</span>
        </p>
        <HR />
        <p>
          Headsign: <span className="span_bold">{currentBus?.headsign || "n/a"}</span>
        </p>
        <p>
          Next stop:{" "}
          <span className="span_bold">
            {currentBus?.nextStopName ?? "n/a"} ({currentBus?.nextStopId ?? "n/a"})
          </span>
        </p>
        <p>
          Vehicle name (ID): <span className="span_bold span_maroon">{currentBus?.vehicleName || "n/a"}</span> (
          <span className="span_bold span_maroon">{currentBus?.id || "n/a"}</span>)
        </p>

        <p>
          Status:{" "}
          <span className="span_bold" style={{ background: currentBus.colorStatus }}>
            {currentBus.translatedString || "n/a"}
          </span>
        </p>
        <p>
          Block state: <span className="span_bold">{currentBus.blockMthd}</span>
        </p>

        <p>
          Azimuth: <span className="span_bold">{currentBus.loc.heading ? `${currentBus.loc.heading}\u00B0 ` : "n/a"}</span>
        </p>
        <p>
          Last data update: <span className="span_bold">{timestampToString(currentBus.loc.time) || "n/a"}</span>
        </p>
        <p>
          Last data update (2): <span className="span_bold">{timeFromNow(currentBus.loc.time) || "n/a"}</span>
        </p>
      </VehicleInfoBlock>
    );
  };

  return (
    <React.Fragment>
      <Modal
        show={showInfoModal}
        fullscreen={"lg-down"}
        onHide={() => dispatch(showInformationModal(false))}
        dialogClassName=""
        centered={true}
        backdrop={false}
        keyboard={true}
      >
        <Modal.Header closeButton={true} closeLabel="Close">
          <Modal.Title>Detailed Vehicle Information</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {calculatedTripStatus && currentBusData && currentBusData?.id ? (
            BusInfoBodyModal(currentBusData, routeInfo!, calculatedTripStatus)
          ) : (
            <NoBusInfo />
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={() => dispatch(showInformationModal(false))}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default InfoModal;
