import React from "react";
import styled from "styled-components";
import * as turf from "@turf/turf";

import { useAppSelector } from "../redux/hooks";
import { selectCurrentBusData, selectRouteInfo, selectTripInfo } from "../redux/selectors";
import NoBusInfo from "../layout/NoBusInfo";
import TimePointsCircle from "./TimePointsCircle";
import variableColors from "../_App.module.scss";
import TimePointsCustomProgressBar from "./TimePointsCustomProgressBar";
import { lengthUnit } from "../config";
import { getPercent, limitNumberWithinRange } from "../utils/helpers";
import TopInfoComponent from "../layout/TopInfoComponent";
import { ComponentContainer } from "./Settings";
import useNetworkStatus from "../services/useNetworkStatus";

const { textColor, appBackgroundColor, secondaryColor } = variableColors;

const TimePointsContainer = styled(ComponentContainer)`
  margin-left: auto;
  margin-right: auto;
  max-width: 1024px;
  background-color: ${appBackgroundColor};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  height: 100%;
  gap: 0.5rem;
`;

const HeadSignP = styled.p`
  font-size: 1.2rem;
  margin-bottom: 0;
`;

const TimePoints = (): JSX.Element => {
  const { isMobile } = useNetworkStatus();

  const currentBusDataFromRedux: BusInfo = useAppSelector<BusInfo>(selectCurrentBusData);
  const currentRouteDetailsFromRedux: RouteDetails = useAppSelector<RouteDetails>(selectRouteInfo);
  const currentTripInfoFromRedux: TripInfo = useAppSelector<TripInfo>(selectTripInfo);

  const [currentData, setCurrentData] = React.useState<TimePointsCurrentData | null>(null);
  const [currentTripStops, setCurrentTripStops] = React.useState<Schedule[] | null>(null);
  const [progressBarStops, setProgressBarStops] = React.useState<{ previousStop: Schedule; nextStop: Schedule } | null>(
    null
  );
  const [currentSegmentPercentage, setCurrentSegmentPercentage] = React.useState<number>(0);
  // console.log("currentSegmentPercentage: ", currentSegmentPercentage);

  //* Two elems progress bar (Beads) -> Current trip stops
  React.useEffect(() => {
    if (currentTripInfoFromRedux && Object.keys(currentTripInfoFromRedux).length) {
      const { schedule } = currentTripInfoFromRedux;
      //* List of stops for current trip
      // console.log("schedule:", schedule);
      setCurrentTripStops(schedule);
    }
  }, [currentTripInfoFromRedux]);

  React.useEffect(() => {
    if (currentData && currentTripStops) {
      const nextStopId = currentData.nextStopId;
      // console.log("nextStopId: ", nextStopId);

      const nextStopIdIndex = currentTripStops.findIndex((busStop) => {
        return busStop.stopId === nextStopId;
      });
      // console.log({ nextStopIdIndex });

      const nextStop = currentTripStops[nextStopIdIndex];
      const previousStop = currentTripStops[nextStopIdIndex - 1];
      // console.log({ previousStop, nextStop });
      setProgressBarStops({ previousStop, nextStop });
    }
  }, [currentData, currentTripStops]);

  //* Set currentData
  React.useEffect(() => {
    if (
      currentBusDataFromRedux &&
      Object.keys(currentBusDataFromRedux).length &&
      currentRouteDetailsFromRedux &&
      currentTripInfoFromRedux &&
      Object.keys(currentTripInfoFromRedux).length
    ) {
      const addedStatus = currentBusDataFromRedux?.addedStatus;
      const addedStatus2 = currentBusDataFromRedux?.addedStatus2;
      const colorStatus = currentBusDataFromRedux?.colorStatus;
      const headsign = currentBusDataFromRedux?.headsign;
      const nextStopId = currentBusDataFromRedux?.nextStopId;
      const nextStopName = currentBusDataFromRedux?.nextStopName;
      const translatedString = currentBusDataFromRedux?.translatedString;
      const iconColor = currentBusDataFromRedux?.iconColor;
      const shortName = currentRouteDetailsFromRedux?.routeInfo?.shortName;
      const color = currentRouteDetailsFromRedux?.routeInfo?.color;
      const textColor = currentRouteDetailsFromRedux?.routeInfo?.textColor;
      const startTime = currentTripInfoFromRedux?.startTime;
      const endTime = currentTripInfoFromRedux?.endTime;
      const currentDataToSet = {
        addedStatus,
        addedStatus2,
        colorStatus,
        headsign,
        nextStopName,
        nextStopId,
        translatedString,
        shortName,
        color,
        textColor,
        iconColor,
        startTime,
        endTime,
      };
      setCurrentData(currentDataToSet);

      //* Percentage
      // console.log({ nextStopId });
      const tripSegment = currentTripInfoFromRedux?.tripPattern?.stopPaths?.filter((elem) => elem.stopId === nextStopId);
      // console.log({ tripSegment });
      const segmentLocations = tripSegment[0]?.locations;
      // console.log({ segmentLocations });

      //@ coordinatesConverter for Turf.js [lng, lat] !!!
      const segmentLocationsArrays = [] as [number, number][];
      for (let i = 0; i < segmentLocations?.length; i++) {
        segmentLocationsArrays.push([segmentLocations[i].lon!, segmentLocations[i].lat]);
      }
      // console.log("segmentLocationsArrays:", segmentLocationsArrays);

      if (segmentLocationsArrays && segmentLocationsArrays.length >= 2) {
        const currentPolylineTurf = turf.lineString(segmentLocationsArrays);
        // console.log("currentPolylineTurf:", currentPolylineTurf);
        const currentPolylineLength: number = Number(turf.length(currentPolylineTurf, { units: lengthUnit }).toFixed(2));
        // console.log({ currentPolylineLength });

        const currentBusPositionTurf = [currentBusDataFromRedux?.loc?.lon, currentBusDataFromRedux?.loc?.lat];
        // console.log("currentBusPositionTurf:", currentBusPositionTurf);
        const currentBusPositionPointTurf = turf.point(currentBusPositionTurf);
        // console.log({ currentBusPositionPointTurf });
        const currentBusPositionPointOnPolylineTurf = turf.nearestPointOnLine(
          currentPolylineTurf,
          currentBusPositionPointTurf,
          {
            units: lengthUnit,
          }
        );
        // console.log("currentBusPositionPointOnPolylineTurf:", currentBusPositionPointOnPolylineTurf);

        const busPositionDistanceCoveredPercentage = limitNumberWithinRange(
          getPercent(currentBusPositionPointOnPolylineTurf?.properties.location!, currentPolylineLength),
          0,
          100
        ) as number;
        // console.log({ busPositionDistanceCoveredPercentage });
        setCurrentSegmentPercentage(busPositionDistanceCoveredPercentage);
      }
    }
  }, [currentBusDataFromRedux, currentRouteDetailsFromRedux, currentTripInfoFromRedux]);

  return (
    <React.Fragment>
      <TimePointsContainer $isMobile={isMobile}>
        {currentData ? (
          <React.Fragment>
            <TopInfoComponent fontSize="90%" />

            <TimePointsCircle
              iconColor={currentData?.iconColor || secondaryColor}
              textColor={textColor}
              addedStatus2={currentData?.addedStatus2 || ""}
              startTime={currentData?.startTime}
              endTime={currentData?.endTime}
            />

            <HeadSignP>
              To: <span className="span_bold">{currentData.headsign}</span>
            </HeadSignP>

            {progressBarStops && progressBarStops ? (
              <TimePointsCustomProgressBar
                bgColor={currentData?.iconColor || secondaryColor}
                textColor={textColor}
                progressBarStops={progressBarStops}
                percent={currentSegmentPercentage}
              />
            ) : null}
          </React.Fragment>
        ) : (
          <NoBusInfo />
        )}
      </TimePointsContainer>
    </React.Fragment>
  );
};

export default TimePoints;
