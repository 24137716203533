import React from "react";

import homeSpriteIcons from "../assets/icons/homeSpriteIcons.svg";

const HomeIcon = ({ name, color, size }: { name: string; color: string; size: number }): JSX.Element => {
  // console.log({ name, color });
  return (
    <React.Fragment>
      <svg fill={color} width={size} height={size}>
        <use xlinkHref={`${homeSpriteIcons}#${name}`} />
      </svg>
    </React.Fragment>
  );
};

export default HomeIcon;
