import React from "react";
import styled from "styled-components";
import { Button } from "react-bootstrap";

import { useAppDispatch } from "../redux/hooks";
import { showInitialModal } from "../redux/actions";

const NoBusContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: calc(100vh - var(--constantFooterHeight) - var(--constantHeaderHeight));
  h1 {
    margin-top: 40px;
  }
  button {
    margin-bottom: 30px;
  }
`;

const NoBusInfo = (): JSX.Element => {
  const dispatch: Dispatch = useAppDispatch();

  // Dispatch showInitialModal - change bus
  const changeBusFunction = async () => {
    await dispatch(showInitialModal(true));
  };

  return (
    <React.Fragment>
      <NoBusContainer>
        <h2>No active vehicle selected</h2>
        <br />
        <Button size="sm" onClick={changeBusFunction}>
          Select a Vehicle
        </Button>
      </NoBusContainer>
    </React.Fragment>
  );
};

export default NoBusInfo;
