import React from "react";
import styled from "styled-components";

import { calculateTimeLength } from "../utils/helpers";

//^ Styled-Components - Circle - with props
const CenterCircle = styled.div<{ $colorStatus: string; $textColor: string }>`
  div.circle {
    border-color: ${(props) => props.$colorStatus};
    color: ${(props) => props.$textColor};
    border-style: solid;
    border-width: 1rem;
    width: 260px;
    height: 260px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    div.text-big {
      font-weight: bold;
      font-size: 2.5rem;
    }
    div.text-small {
      font-size: 1.2rem;
    }
  }
`;

const TimePointsCircle = ({
  iconColor,
  textColor,
  addedStatus2,
  startTime,
  endTime,
}: {
  iconColor: string;
  textColor: string;
  addedStatus2: string;
  startTime: string;
  endTime: string;
}): JSX.Element => {
  // console.log({ iconColor, textColor, translatedString });

  return (
    <React.Fragment>
      <CenterCircle $colorStatus={iconColor} $textColor={textColor}>
        <div className="circle">
          <div className="text-big">{addedStatus2}</div>
          <div className="text-small">
            <span className="span_bold">{calculateTimeLength(endTime)}</span> left
          </div>
          <div className="text-small">
            <span className="span_bold">{calculateTimeLength(startTime)}</span> elapsed
          </div>
        </div>
      </CenterCircle>
    </React.Fragment>
  );
};

export default TimePointsCircle;
