import React from "react";
import styled from "styled-components";

import { getCurrentDayOfTheWeek, timestampToString } from "../utils/helpers";
import useNetworkStatus from "../services/useNetworkStatus";

export const H4 = styled.h4<{ $fontSize: string }>`
  text-decoration: none;
  margin-bottom: 0;
  font-size: ${(props) => props.$fontSize};
`;

const TimePointsCurrentTime = ({ fontSize }: { fontSize?: string }): JSX.Element => {
  const { isMobile } = useNetworkStatus();
  // console.log({ fontSize });
  const [currentDate, setCurrentDate] = React.useState<Date>(new Date());
  // console.log({ currentDate });

  const separatedDate = (dateObject: Date) => {
    const dateString = timestampToString(dateObject);
    const dateArray = dateString.split(", ");
    const currentDateString = dateArray[0];
    const currentTimeString = dateArray[1];
    const currentWeekDay = getCurrentDayOfTheWeek(dateObject, isMobile);
    // console.log({ currentWeekDay, currentDateString, currentTimeString });
    return { currentWeekDay, currentDateString, currentTimeString };
  };

  React.useEffect(() => {
    const timer = setInterval(() => setCurrentDate(new Date()), 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  });

  return (
    <React.Fragment>
      <H4 $fontSize={fontSize || "calc(1.275rem + 0.3vw)"}>
        {separatedDate(currentDate).currentWeekDay}, {separatedDate(currentDate).currentDateString},{" "}
        <span className="span_bold">{separatedDate(currentDate).currentTimeString}</span>
      </H4>
    </React.Fragment>
  );
};

export default TimePointsCurrentTime;
