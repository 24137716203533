import React from "react";
import styled from "styled-components";
import { Container, Row, Form, Col, ButtonGroup, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { ComponentContainer } from "../Settings";
import TopInfoComponent from "../../layout/TopInfoComponent";
import useNetworkStatus from "../../services/useNetworkStatus";
import CommonTopInfoComponent, { CommonTopInfoComponentContainer } from "./CommonTopInfoComponent";
import { useAppSelector } from "../../redux/hooks";
import {
  selectCheckInTime,
  selectDriverData,
  selectPreInspectionResponseData,
  selectScheduleDriverToday,
  selectTripInfo,
} from "../../redux/selectors";
import { cutSeconds, getInspectionTime, infoNotify, routeDetailsUpdate } from "../../utils/helpers";
import variableColors from "../../_App.module.scss";

const { dangerColor, appBackgroundColorWhiteSmoke, secondaryColor } = variableColors;

const ReturnToYardContainer = styled(ComponentContainer)`
  background-color: inherit;
`;

const ReturnToYardFormContainer = styled(CommonTopInfoComponentContainer)`
  background-color: inherit;
  div.mb-3 {
    margin-top: 0;
    margin-bottom: 0.75rem !important;

    label {
      border-bottom: 1px solid ${dangerColor};
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      margin-bottom: 0.5rem !important;
    }
    input {
      border-top: none !important;
      border-radius: 0;
    }
    input.name-info {
      background-color: ${secondaryColor} !important;
      padding-left: 0.25rem !important;
      &::placeholder {
        color: ${appBackgroundColorWhiteSmoke};
      }
    }
    input.input-updatable {
      background-color: ${appBackgroundColorWhiteSmoke} !important;
      background-image: url("edit.svg") !important;
      background-repeat: no-repeat;
      background-position: right;
      background-size: 22px 22px;
      cursor: pointer;
    }
  }
`;

const ReturnToYard = (): JSX.Element => {
  const { isMobile } = useNetworkStatus();
  const navigate = useNavigate();

  const driverData: DriverData = useAppSelector<DriverData>(selectDriverData);
  const scheduleDriverTodayFromRedux: RootState = useAppSelector<RootState>(selectScheduleDriverToday);
  const checkInTimeFromRedux: string = useAppSelector<string>(selectCheckInTime);
  const preInspectionResponseDataFromRedux: PreInspectionResponseData =
    useAppSelector<PreInspectionResponseData>(selectPreInspectionResponseData);
  const currentTripInfoFromRedux: TripInfo = useAppSelector<TripInfo>(selectTripInfo);

  const [returnToYardData, setReturnToYardData] = React.useState<ReturnToYard | null>(null);
  const [returnToYardDataToPut, setReturnToYardDataToPut] = React.useState<YardPostPutData | null>(null);

  const onChangeData = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (returnToYardData) {
      setReturnToYardData({ ...returnToYardData, [event.target.name]: event.target.value });
    }
  };

  React.useEffect(() => {
    if (scheduleDriverTodayFromRedux && checkInTimeFromRedux && preInspectionResponseDataFromRedux) {
      setReturnToYardData({
        checkInTime: checkInTimeFromRedux, //* checkInTime: default value in Redux
        dateOfInspection: preInspectionResponseDataFromRedux?.result?.dateOfInspection || "n/a",
        leaveYardTime: scheduleDriverTodayFromRedux?.leave_yard_time || "n/a",
        leaveYardOdometer:
          scheduleDriverTodayFromRedux?.leave_yard_odometer || scheduleDriverTodayFromRedux?.latest_odometer || 0,
        firstStopTime:
          scheduleDriverTodayFromRedux?.first_stop_time ||
          cutSeconds(currentTripInfoFromRedux?.schedule?.[0]?.departureTime) ||
          "n/a",
        firstStopOdometer: scheduleDriverTodayFromRedux?.first_stop_odometer || 0,
        lastStopTime:
          scheduleDriverTodayFromRedux?.last_stop_time ||
          cutSeconds(currentTripInfoFromRedux?.schedule?.[currentTripInfoFromRedux?.schedule?.length - 1]?.arrivalTime) ||
          "n/a",
        lastStopOdometer: scheduleDriverTodayFromRedux?.last_stop_odometer || 0,
        returnToYardTime:
          scheduleDriverTodayFromRedux?.return_to_yard_time || scheduleDriverTodayFromRedux?.end_time || "n/a", //* end_time is correct? !
        returnToYardOdometer: scheduleDriverTodayFromRedux?.return_to_yard_odometer || 0,
      });
    } else {
      setReturnToYardData({
        checkInTime: "-", //* checkInTime: default value in Redux
        dateOfInspection: "n/a",
        leaveYardTime: "n/a",
        leaveYardOdometer: 0,
        firstStopTime: "n/a",
        firstStopOdometer: 0,
        lastStopTime: "n/a",
        lastStopOdometer: 0,
        returnToYardTime: "n/a",
        returnToYardOdometer: 0,
      });
    }
  }, [checkInTimeFromRedux, currentTripInfoFromRedux, preInspectionResponseDataFromRedux, scheduleDriverTodayFromRedux]);

  React.useEffect(() => {
    if (driverData && scheduleDriverTodayFromRedux) {
      const dataToSend: YardPostPutData = {
        client_id: Number(scheduleDriverTodayFromRedux?.route.client_id),
        driver_id: scheduleDriverTodayFromRedux?.driver_id as string,
        fields: [
          {
            field: "leave_yard_odometer",
            value: returnToYardData?.leaveYardOdometer!,
          },
          {
            field: "first_stop_odometer",
            value: returnToYardData?.firstStopOdometer!,
          },
          {
            field: "last_stop_odometer",
            value: returnToYardData?.lastStopOdometer!,
          },
          {
            field: "return_to_yard_odometer",
            value: returnToYardData?.returnToYardOdometer!,
          },
        ],
        // route_block_id: Number(scheduleDriverTodayFromRedux?.routeblock?.route_id), //* V1: 2023-09
        route_block_id: Number(scheduleDriverTodayFromRedux?.routeblock?.id), //* V2: 2024-04-26
        schedule_detail_id: Number(scheduleDriverTodayFromRedux?.id),
        vehicle_id: String(scheduleDriverTodayFromRedux?.vehicle_id),
      };
      setReturnToYardDataToPut(dataToSend);
    }
  }, [driverData, returnToYardData, scheduleDriverTodayFromRedux]);

  const putDataGoHome = async (): Promise<void> => {
    try {
      const putResponse = await routeDetailsUpdate(returnToYardDataToPut!, driverData?.token);
      // console.log("putResponse:", putResponse);
      if (putResponse.status === 200) {
        // console.log("putResponse.status:", putResponse.status);
        await infoNotify("success", "The data has been sent");
        await navigate("/");
      }
    } catch (error) {
      console.log((error: Error) => console.log(error));
      await infoNotify("error", "Error");
    }
  };

  const ReturnToYardForm = (returnToYardData: ReturnToYard): JSX.Element => {
    const {
      checkInTime,
      dateOfInspection,
      firstStopOdometer,
      firstStopTime,
      lastStopTime,
      lastStopOdometer,
      leaveYardTime,
      leaveYardOdometer,
      returnToYardTime,
      returnToYardOdometer,
    } = returnToYardData;

    return (
      <Form className="top-bar-info">
        <fieldset disabled={false}>
          <Container fluid={true}>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="return-to-yard-name">Info Name</Form.Label>
                  <Form.Control
                    id="return-to-yard-name"
                    placeholder="Check-In"
                    readOnly={true}
                    className="name-info"
                    disabled={true}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="return-to-yard-time">Actual Time</Form.Label>
                  <Form.Control
                    id="return-to-yard-time"
                    //* V1 -> editable
                    // value={checkInTime}
                    // name="checkInTime"
                    // className="input-updatable"
                    // onChange={onChangeData}
                    //* V2 -> not editable
                    defaultValue={checkInTime}
                    readOnly={true}
                    disabled={true}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Odometer</Form.Label>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Control
                    id="return-to-yard-name2"
                    placeholder="Vehicle pre-inspection"
                    readOnly={true}
                    className="name-info"
                    disabled={true}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Control
                    id="return-to-yard-time2"
                    //* V1 -> editable
                    // value={getInspectionTime(dateOfInspection)}
                    // name="dateOfInspection"
                    // className="input-updatable"
                    // onChange={onChangeData}
                    //* V2 -> not editable
                    defaultValue={getInspectionTime(dateOfInspection)}
                    readOnly={true}
                    disabled={true}
                  />
                </Form.Group>
              </Col>
              <Col>{null}</Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Control
                    id="return-to-yard-name3"
                    placeholder="Leave Yard"
                    readOnly={true}
                    className="name-info"
                    disabled={true}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Control
                    id="return-to-yard-time3"
                    value={leaveYardTime}
                    name="leaveYardTime"
                    className="input-updatable"
                    onChange={onChangeData}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Control
                    id="return-to-yard-odometer3"
                    value={leaveYardOdometer}
                    name="leaveYardOdometer"
                    className="input-updatable"
                    onChange={onChangeData}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Control
                    id="return-to-yard-name4"
                    placeholder="First Stop"
                    readOnly={true}
                    className="name-info"
                    disabled={true}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Control
                    id="return-to-yard-time4"
                    value={firstStopTime}
                    name="firstStopTime"
                    className="input-updatable"
                    onChange={onChangeData}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Control
                    id="return-to-yard-odometer4"
                    value={firstStopOdometer}
                    name="firstStopOdometer"
                    className="input-updatable"
                    onChange={onChangeData}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Control
                    id="return-to-yard-name5"
                    placeholder="Last Stop"
                    readOnly={true}
                    className="name-info"
                    disabled={true}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Control
                    id="return-to-yard-time5"
                    value={lastStopTime}
                    name="lastStopTime"
                    className="input-updatable"
                    onChange={onChangeData}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Control
                    id="return-to-yard-odometer5"
                    value={lastStopOdometer}
                    name="lastStopOdometer"
                    className="input-updatable"
                    onChange={onChangeData}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Control
                    id="return-to-yard-name6"
                    placeholder="Return To Yard"
                    readOnly={true}
                    className="name-info"
                    disabled={true}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Control
                    id="return-to-yard-time6"
                    value={returnToYardTime}
                    name="returnToYardTime"
                    className="input-updatable"
                    onChange={onChangeData}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Control
                    id="return-to-yard-odometer6"
                    value={returnToYardOdometer}
                    name="returnToYardOdometer"
                    className="input-updatable"
                    onChange={onChangeData}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Container>
        </fieldset>

        <ButtonGroup className="w-100 mt-1">
          <Button
            type="button"
            variant="warning"
            className="w-100"
            onClick={() => putDataGoHome()}
            disabled={driverData && returnToYardDataToPut ? false : true}
          >
            Send data and go to home page
          </Button>
        </ButtonGroup>
      </Form>
    );
  };

  return (
    <React.Fragment>
      <ReturnToYardContainer $isMobile={isMobile}>
        <TopInfoComponent fontSize="90%" />
        <CommonTopInfoComponent />
        <ReturnToYardFormContainer>{returnToYardData && ReturnToYardForm(returnToYardData)}</ReturnToYardFormContainer>
      </ReturnToYardContainer>
    </React.Fragment>
  );
};

export default ReturnToYard;
