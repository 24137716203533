import React from "react";
import { CircleMarker, Popup } from "react-leaflet";
import * as turf from "@turf/turf";

import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { selectChosenAgency, selectRouteInfo, selectTripInfo } from "../redux/selectors";
import { coordinatesConverter, getPercent, limitNumberWithinRange, removeDuplicates } from "../utils/helpers";
import { lengthUnit } from "../config";
import { dispatchTripStatus, dispatchTripStatusProgress } from "../redux/actions";

// TODO:  ??? check number of stops equals to number of trip stops!!! (should be from trip not from route) ???
const MapBusTripInfo = ({ currentBusData }: { currentBusData: BusInfo }): JSX.Element => {
  const dispatch: Dispatch = useAppDispatch();

  const currentTripInfo: TripInfo = useAppSelector<TripInfo>(selectTripInfo);
  const currentRouteDetails: RouteDetails = useAppSelector<RouteDetails>(selectRouteInfo);
  const chosenAgencyFromRedux: Agency = useAppSelector<Agency>(selectChosenAgency);

  const [polylinePointsLocationsArraysTurf, setPolylinePointsLocationsArraysTurf] = React.useState<Point[] | null>(null);
  const [currentTripStops, setCurrentTripStops] = React.useState<BusStop[] | null>(null);
  // console.log("currentTripStops: ", currentTripStops);

  //* Trip Polyline Shape
  React.useEffect(() => {
    if (currentTripInfo) {
      // console.log("currentTripInfo:", currentTripInfo);
      const polylineLocations = [] as Point2[];
      for (let i = 0; i < currentTripInfo?.tripPattern?.stopPaths.length; i++) {
        for (let j = 0; j < currentTripInfo?.tripPattern?.stopPaths[i].locations.length; j++) {
          polylineLocations.push(currentTripInfo?.tripPattern?.stopPaths[i].locations[j]);
        }
      }
      // console.log("polylineLocations:", polylineLocations);
      const filteredPolylineLocations = removeDuplicates(polylineLocations);

      //^ coordinatesConverter for Turf.js [lng, lat] !!!
      const polylineLocationsArrays = coordinatesConverter(filteredPolylineLocations);
      setPolylinePointsLocationsArraysTurf(polylineLocationsArrays);
    }
  }, [currentTripInfo]);

  //^ Calculating Trip ProgressBar - coordinates for Turf.js [lng, lat] !!!
  React.useEffect(() => {
    if (polylinePointsLocationsArraysTurf && polylinePointsLocationsArraysTurf.length >= 2 && currentBusData) {
      const currentPolylineTurf = turf.lineString(polylinePointsLocationsArraysTurf);
      // console.log("currentPolylineTurf:", currentPolylineTurf);
      const currentPolylineLength: number = Number(turf.length(currentPolylineTurf, { units: lengthUnit }).toFixed(2));
      // console.log({ currentPolylineLength });

      const currentBusPositionTurf = [currentBusData?.loc?.lon, currentBusData?.loc?.lat];
      // console.log("currentBusPositionTurf:", currentBusPositionTurf);
      const currentBusPositionPointTurf = turf.point(currentBusPositionTurf);
      // console.log({ currentBusPositionPointTurf });
      const currentBusPositionPointOnPolylineTurf = turf.nearestPointOnLine(
        currentPolylineTurf,
        currentBusPositionPointTurf,
        {
          units: lengthUnit,
        }
      );
      // console.log("currentBusPositionPointOnPolylineTurf:", currentBusPositionPointOnPolylineTurf);

      const calculatedTripInfoTurf: CalculatedTripInfo = {
        currentPolylineSegmentsNumber: (polylinePointsLocationsArraysTurf.length - 1) as number,
        currentPolylineLength: currentPolylineLength as number,
        currentBusPositionTurf: currentBusPositionTurf as [number, number],
        nearestPointOnPolylineTurf: currentBusPositionPointOnPolylineTurf?.geometry?.coordinates as [number, number],
        busPositionToPolylineDistance: currentBusPositionPointOnPolylineTurf?.properties.dist as number,
        busPositionPolylineSegmentIndex: currentBusPositionPointOnPolylineTurf?.properties.index as number,
        busPositionPolylineDistanceCovered: currentBusPositionPointOnPolylineTurf?.properties?.location as number,

        busPositionDistanceCoveredPercentage: limitNumberWithinRange(
          getPercent(currentBusPositionPointOnPolylineTurf?.properties.location!, currentPolylineLength),
          0,
          100
        ) as number,

        busPositionDistanceCoveredPercentageString: `${limitNumberWithinRange(
          getPercent(currentBusPositionPointOnPolylineTurf?.properties.location!, currentPolylineLength),
          0,
          100
        )}%` as string,
      };
      // console.log("calculatedTripInfoTurf:", calculatedTripInfoTurf);
      dispatch(dispatchTripStatus(calculatedTripInfoTurf));
    }
  }, [currentBusData, dispatch, polylinePointsLocationsArraysTurf]);

  //* Current trip stops
  React.useEffect(() => {
    if (
      currentTripInfo &&
      Object.keys(currentTripInfo).length &&
      currentRouteDetails &&
      Object.keys(currentRouteDetails).length &&
      chosenAgencyFromRedux
    ) {
      const { schedule } = currentTripInfo;
      const { agencyId } = chosenAgencyFromRedux;
      //* List of stops for current trip
      // console.log("schedule:", schedule);

      //* Current Direction and Stops for the direction
      const selectedDirection = currentRouteDetails?.selectedDirection;
      // console.log({ selectedDirection });

      //* SweetWater only
      if (agencyId === ("33174" as string)) {
        const currentTripStops = currentRouteDetails?.lineStops;
        const currentTripStopsArray = currentTripStops[0]?.stop;
        for (let i = 0; i < currentTripStopsArray.length; i++) {
          for (let j = 0; j < schedule.length; j++) {
            if (currentTripStopsArray[i].id === schedule[j].stopId) {
              currentTripStopsArray[i].departureTime = schedule[j].departureTime;
            }
          }
        }
        setCurrentTripStops(currentTripStopsArray);
      }

      //* Other agencies
      if (selectedDirection && agencyId !== ("33174" as string)) {
        const currentTripStops = currentRouteDetails?.lineStops.filter(
          (stopSet: BusStopDirection) => stopSet.id === selectedDirection
        );
        const currentTripStopsArray = currentTripStops[0]?.stop;
        // console.log("currentTripStopsArray:", currentTripStopsArray);
        for (let i = 0; i < currentTripStopsArray.length; i++) {
          for (let j = 0; j < schedule.length; j++) {
            if (currentTripStopsArray[i].id === schedule[j].stopId) {
              currentTripStopsArray[i].departureTime = schedule[j].departureTime;
            }
          }
        }
        setCurrentTripStops(currentTripStopsArray);
      }
    }
  }, [chosenAgencyFromRedux, currentRouteDetails, currentTripInfo]);

  //^ Calculating Trip ProgressBar2 - coordinates for Turf.js [lng, lat] !!!
  React.useEffect(() => {
    if (currentTripStops && polylinePointsLocationsArraysTurf) {
      // console.log("currentTripStops:", currentTripStops);
      //* Current polyline shape in Turf.js convention!
      const currentPolylineTurf = turf.lineString(polylinePointsLocationsArraysTurf);
      // console.log("currentPolylineTurf:", currentPolylineTurf);

      const stopsArrayTurf = coordinatesConverter(currentTripStops);
      // console.log("stopsArrayTurf:", stopsArrayTurf);

      const currentPolylineLength: number = Number(turf.length(currentPolylineTurf, { units: lengthUnit }).toFixed(2));
      // console.log({ currentPolylineLength });

      const stopsArrayTurfNearestPoint = stopsArrayTurf.map((elem: Point) =>
        turf.nearestPointOnLine(currentPolylineTurf, elem, { units: lengthUnit })
      );
      // console.log("stopsArrayTurfNearestPoint:", stopsArrayTurfNearestPoint);

      for (let i = 0; i < currentTripStops.length; i++) {
        for (let j = 0; j < stopsArrayTurfNearestPoint.length; j++) {
          if (i === j) {
            // console.log({ i, j });
            currentTripStops[i].nearestPoint = {
              lat: stopsArrayTurfNearestPoint[j]?.geometry.coordinates[1],
              lon: stopsArrayTurfNearestPoint[j]?.geometry.coordinates[0],
            };
            currentTripStops[i].distance = stopsArrayTurfNearestPoint[j]?.properties?.dist as number;
            currentTripStops[i].location = Number(stopsArrayTurfNearestPoint[j]?.properties?.location?.toFixed(3)) as number;
            currentTripStops[i].currentPolylineLength = currentPolylineLength as number;
          }
        }
      }
      // console.log("currentTripStops:", currentTripStops);
      dispatch(dispatchTripStatusProgress(currentTripStops));
    }
  }, [currentTripStops, dispatch, polylinePointsLocationsArraysTurf]);

  //* Colors - no constants
  const stopsOptions = { fillColor: "turquoise", color: "firebrick", fillOpacity: 0.8 };
  const TripStops = (): JSX.Element => {
    return (
      <React.Fragment>
        {currentTripStops &&
          (currentTripStops.map((elem: BusStop, index: number) => (
            <CircleMarker
              pathOptions={stopsOptions}
              key={String(elem.lat + " " + elem.lon + " " + index)}
              center={{
                lat: elem.lat,
                lng: elem.lon,
              }}
              radius={6}
            >
              <Popup>
                <div>
                  Name: <span className="span_bold">{elem.name}</span>
                  <br />
                  ID: <span className="span_bold">{elem.id}</span>
                  <br />
                  Departure Time: <span className="span_bold">{elem.departureTime || "n/a"}</span>
                </div>
              </Popup>
            </CircleMarker>
          )) as JSX.Element[])}
      </React.Fragment>
    );
  };

  return <React.Fragment>{currentTripStops ? <TripStops /> : null}</React.Fragment>;
};

export default MapBusTripInfo;
