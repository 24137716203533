import React from "react";
import styled from "styled-components";
import { Button, ButtonGroup, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { ComponentContainer } from "./Settings";
import TopInfoComponent from "../layout/TopInfoComponent";
import useNetworkStatus from "../services/useNetworkStatus";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { dispatchCheckInTime, getScheduleDriverForToday } from "../redux/actions";
import { selectDriverData, selectDriverPinNumber, selectScheduleDriverToday, selectTripInfo } from "../redux/selectors";
import { NoDataContainer } from "./HomeComponent";

import variableColors from "../_App.module.scss";
import { cutSeconds, getTimeZoneTime } from "../utils/helpers";

const { textColor, dangerColor, appBackgroundColor } = variableColors;

export const RouteInformationContainer = styled(ComponentContainer)`
  background-color: inherit;

  & > form.route-information {
    button {
      text-transform: uppercase;
    }
    fieldset {
      div.container-fluid {
        div.row {
          div.col {
            padding-left: 0.25rem;
            padding-right: 0.25rem;
            &:first-child {
              padding-left: 0;
            }
            &:last-child {
              padding-right: 0;
            }
            div.mb-3 {
              margin-bottom: initial;
            }
          }
        }
      }
      div.mb-3,
      div.mb-3.input-group {
        label.form-label {
          color: ${textColor};
          font-size: 90%;
          margin-bottom: 0.25rem;
          padding-left: 2px;
          width: 100%;
        }
        input {
          color: initial;
          font-size: 95%;
          padding-left: 2px;
          padding-right: 2px;
          font-weight: bold;
          border-top: 1px solid ${dangerColor};
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          background-color: ${appBackgroundColor};
        }
      }
      div.mb-3.input-group {
        margin-left: auto;
        margin-right: auto;
        width: 80%;
        span {
          border-top: 1px solid ${dangerColor};
          border-top-left-radius: 0;
          background-color: ${appBackgroundColor};
          font-size: 90%;
          width: 50%;
        }
        input {
          font-size: 95%;
          width: 50%;
          text-align: center;
        }
      }
    }
  }
`;

const RouteInformation = (): JSX.Element => {
  const { isMobile } = useNetworkStatus();
  const navigate = useNavigate();

  const dispatch: Dispatch = useAppDispatch();
  const driverDataFromRedux: DriverData = useAppSelector<DriverData>(selectDriverData);
  const driverPIN = useAppSelector<number>(selectDriverPinNumber);
  const scheduleDriverTodayFromRedux: RootState = useAppSelector<RootState>(selectScheduleDriverToday);
  const currentTripInfoFromRedux: TripInfo = useAppSelector<TripInfo>(selectTripInfo);
  // console.log("scheduleDriverTodayFromRedux:", scheduleDriverTodayFromRedux);

  const [driverData, setDriverData] = React.useState<DriverData | null>(null);
  const [scheduleDriverToday, setScheduleDriverToday] = React.useState<ScheduleDriverToday | null>(null);
  // console.log("scheduleDriverToday:", scheduleDriverToday);

  const checkInFunction = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    await dispatch(dispatchCheckInTime(getTimeZoneTime()));
    await navigate("/pre_inspection");
  };

  //* Driver's Info
  React.useEffect(() => {
    if (driverDataFromRedux && Object.keys(driverDataFromRedux)) {
      setDriverData(driverDataFromRedux);
    }
  }, [driverDataFromRedux]);

  const getScheduleDriverToday = React.useCallback(() => {
    // console.log({ driverPIN });
    if (driverPIN && driverDataFromRedux) {
      const driverToken = driverDataFromRedux?.token as string;
      dispatch(getScheduleDriverForToday(driverPIN, driverToken));
    }
  }, [dispatch, driverDataFromRedux, driverPIN]);

  React.useEffect(() => {
    getScheduleDriverToday();
  }, [getScheduleDriverToday]);

  React.useEffect(() => {
    if (driverData && scheduleDriverTodayFromRedux && Object.keys(scheduleDriverTodayFromRedux)?.length) {
      // console.log("scheduleDriverTodayFromRedux:", scheduleDriverTodayFromRedux);
      //* Can be "n/a" - data in not send!
      const driverName = `${driverData?.first_name} ${driverData?.last_name}` || "n/a";
      const employeeId = driverData?.id;
      const vehicleName = scheduleDriverTodayFromRedux?.vehicle?.description || "n/a";
      const currentRoute = scheduleDriverTodayFromRedux?.route?.description || "n/a";
      const currentBlock = scheduleDriverTodayFromRedux?.routeblock?.description || "n/a";
      const currentTrip = currentTripInfoFromRedux?.blockId || "n/a";
      const currentSchedule = {
        reportTime: scheduleDriverTodayFromRedux?.report_time || "n/a",
        leaveYard: scheduleDriverTodayFromRedux?.leave_yard_time || "n/a",
        firstStop:
          scheduleDriverTodayFromRedux?.stops?.[0]?.departure_time ||
          cutSeconds(currentTripInfoFromRedux?.schedule?.[0]?.departureTime) ||
          "n/a",
        lastStop:
          scheduleDriverTodayFromRedux?.stops?.[scheduleDriverTodayFromRedux?.stops?.length - 1]?.arrival_time ||
          cutSeconds(currentTripInfoFromRedux?.schedule?.[currentTripInfoFromRedux?.schedule?.length - 1]?.arrivalTime) ||
          "n/a",
        checkOut: scheduleDriverTodayFromRedux?.punch_out_time || "n/a",
      };
      setScheduleDriverToday({
        driverName,
        employeeId,
        vehicleName,
        currentRoute,
        currentBlock,
        currentSchedule,
        currentTrip,
      });
    }
  }, [currentTripInfoFromRedux, driverData, scheduleDriverTodayFromRedux]);

  const RouteInformationForm = ({ scheduleDriverToday }: { scheduleDriverToday: ScheduleDriverToday }): JSX.Element => {
    const {
      driverName,
      employeeId,
      vehicleName,
      currentRoute,
      currentBlock,
      currentSchedule: { reportTime, leaveYard, firstStop, lastStop, checkOut },
    } = scheduleDriverToday;
    return (
      <Form className="route-information">
        <fieldset disabled>
          <Container fluid={true}>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="driver_name">Driver</Form.Label>
                  <Form.Control id="drivers_name" placeholder="n/a" defaultValue={driverName} readOnly={true} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="employee_id">Employee ID</Form.Label>
                  <Form.Control id="employee_id" placeholder="n/a" defaultValue={employeeId} readOnly={true} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="vehicle_name">Vehicle</Form.Label>
                  <Form.Control id="vehicle_name" placeholder="n/a" defaultValue={vehicleName} readOnly={true} />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="current_route">Route</Form.Label>
                  <Form.Control id="current_route" placeholder="n/a" defaultValue={currentRoute} readOnly={true} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="current_block">Block</Form.Label>
                  <Form.Control id="current_block" placeholder="n/a" defaultValue={currentBlock} readOnly={true} />
                </Form.Group>
              </Col>
            </Row>
            <br />

            <h5 className="text-center mb-3">Schedule</h5>
            <InputGroup className="mb-3">
              <InputGroup.Text>Report time:</InputGroup.Text>
              <Form.Control defaultValue={reportTime} readOnly={true} />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text>Leave yard:</InputGroup.Text>
              <Form.Control defaultValue={leaveYard} readOnly={true} />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text>First stop:</InputGroup.Text>
              <Form.Control defaultValue={firstStop} readOnly={true} />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text>Last stop:</InputGroup.Text>
              <Form.Control defaultValue={lastStop} readOnly={true} />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text>Check-out:</InputGroup.Text>
              <Form.Control defaultValue={checkOut} readOnly={true} />
            </InputGroup>
          </Container>
        </fieldset>

        <p className="small text-body-secondary muted mb-3 mt-3">
          <small>
            If the routeDetails above is correct click Check-in to proceed. Otherwise, contact dispatch or your immediate
            supervisor for any changes in the Schedule. DO NOT ACCEPT the route if you got the wrong one. Refresh data when
            you get a confirmation for it.
          </small>
        </p>

        <ButtonGroup className="w-100 mt-3">
          <Button type="button" variant="danger" className="w-50" onClick={getScheduleDriverToday}>
            Refresh data
          </Button>
          <Button type="submit" variant="primary" className="w-50" onClick={(event) => checkInFunction(event)}>
            Check-in
          </Button>
        </ButtonGroup>
      </Form>
    );
  };

  return (
    <React.Fragment>
      <RouteInformationContainer $isMobile={isMobile}>
        <TopInfoComponent fontSize="90%" />
        <br />
        {scheduleDriverToday ? (
          <RouteInformationForm scheduleDriverToday={scheduleDriverToday} />
        ) : (
          <NoDataContainer>
            <h2>No schedule info</h2>
          </NoDataContainer>
        )}
      </RouteInformationContainer>
    </React.Fragment>
  );
};

export default RouteInformation;
