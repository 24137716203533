import React from "react";
import { CircleMarker, Marker, Tooltip } from "react-leaflet";
import styled from "styled-components";

import { returnRadius } from "../utils/helpers";
import directionIcon from "../assets/icons/directionIcon.svg";
import pauseIcon from "../assets/icons/pauseIcon.svg";
import { createBusIcon } from "./MapIcon";
import { showInformationModal } from "../redux/actions";
import { useAppDispatch } from "../redux/hooks";

import variableColors from "../_App.module.scss";

const { secondaryColor, customSecondaryColor } = variableColors;

const BusTooltip = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  gap: 0.25rem;
`;

const MapVehicleComponent = ({
  currentBusData,
  routeInfo,
  showBusTooltip,
  mapAutorotationFromRedux,
}: {
  currentBusData: BusInfo;
  routeInfo: RouteInfo;
  showBusTooltip: boolean;
  mapAutorotationFromRedux: boolean;
}): JSX.Element => {
  // console.log("currentBusData:", currentBusData);
  const dispatch: Dispatch = useAppDispatch();

  //* Colors - not constants
  const pathOptionsRed = { fillColor: "OrangeRed", color: "Red", fillOpacity: 0.8 };
  const pathOptionsYellow = { fillColor: "Yellow", color: "Yellow", fillOpacity: 0.8 };

  const iconColor = currentBusData?.iconColor || secondaryColor;
  const strokeColor = currentBusData?.strokeColor || secondaryColor;
  // console.log({ iconColor, strokeColor, });

  const currentRotation = mapAutorotationFromRedux === true ? 0 : currentBusData?.loc?.heading | 0;

  const VehicleIcon = (): JSX.Element => {
    return (
      <Marker
        position={{
          lat: currentBusData.loc.lat,
          lng: currentBusData.loc.lon,
        }}
        icon={createBusIcon(iconColor, strokeColor, currentRotation)}
        eventHandlers={{
          // dblclick: () => console.log("currentBusData:", currentBusData),
          click: () => dispatch(showInformationModal(true)),
        }}
      >
        {showBusTooltip ? (
          <Tooltip permanent={true}>
            <BusTooltip>
              {currentBusData?.loc?.heading || currentBusData?.loc?.heading === 0 ? (
                <img
                  width={24}
                  height={24}
                  src={currentBusData?.isAtStop === true ? pauseIcon : directionIcon}
                  alt="Pause/Direction Icon"
                  style={{
                    transform:
                      currentBusData?.isAtStop === false && currentBusData?.loc?.heading
                        ? `rotate(${currentBusData.loc.heading}deg)`
                        : "",
                  }}
                />
              ) : null}
              {routeInfo ? (
                <span
                  className="span_bold"
                  style={{
                    display: "inline-block",
                    textAlign: "center",
                    minWidth: "36px",
                    color: routeInfo?.textColor,
                    backgroundColor: routeInfo?.color,
                    padding: "2px 4px",
                    fontSize: "1rem",
                  }}
                >
                  {currentBusData.routeShortName || currentBusData.routeId || "n/a"}
                </span>
              ) : null}
              <span style={{ color: customSecondaryColor, fontWeight: "bold", fontSize: "1rem" }}>
                {currentBusData?.vehicleName || currentBusData?.id || "n/a"}
              </span>
            </BusTooltip>
          </Tooltip>
        ) : null}
        <CircleMarker
          center={{
            lat: currentBusData.loc.lat,
            lng: currentBusData.loc.lon,
          }}
          pathOptions={currentBusData.schAdh > 0 ? pathOptionsRed : pathOptionsYellow}
          radius={
            //* Colors - not constants
            currentBusData?.colorStatus === "orangered" ||
            currentBusData?.colorStatus === "yellow" ||
            currentBusData?.addedStatus === "busToFast" ||
            currentBusData?.addedStatus === "delayedBus"
              ? returnRadius(currentBusData.schAdh)
              : 0
          }
        />
      </Marker>
    );
  };

  return <React.Fragment>{currentBusData && <VehicleIcon />}</React.Fragment>;
};

export default MapVehicleComponent;
