//* Initial dispatch
export const DISPATCH_CHOSEN_AGENCY = "DISPATCH_CHOSEN_AGENCY";

//* First Set
export const GET_AGENCY_INFO = "GET_AGENCY_INFO";

export const GET_BUSES = "GET_BUSES";

export const DISPATCH_BUS_ID = "DISPATCH_BUS_ID";

export const GET_CURRENT_BUS_DATA = "GET_CURRENT_BUS_DATA";

export const DISPATCH_CURRENT_ZOOM = "DISPATCH_CURRENT_ZOOM";

export const GET_ROUTE_DETAILS = "GET_ROUTE_DETAILS";

export const SHOW_INFO_MODAL = "SHOW_INFO_MODAL";

export const GET_TRIP_INFO = "GET_TRIP_INFO";

export const DISPATCH_TRIP_STATUS = "DISPATCH_TRIP_STATUS";

export const DISPATCH_TRIP_STATUS_PROGRESS = "DISPATCH_TRIP_STATUS_PROGRESS";

//* New Action Types
export const DISPATCH_LOGIN_STATUS = "DISPATCH_LOGIN_STATUS";

export const GET_CREDENTIALS = "GET_CREDENTIALS";

export const GET_LOGIN_DATA = "GET_LOGIN_DATA";

export const SHOW_CHANGE_BUS_MODAL = "SHOW_CHANGE_BUS_MODAL";

export const SET_GRAY_MAP = "SET_GRAY_MAP";

export const SET_SHOW_TOOLTIP = "SET_SHOW_TOOLTIP";

export const SET_MAP_AUTOROTATION = "SET_MAP_AUTOROTATION";

export const GET_ROUTES_DRIVER = "GET_ROUTES_DRIVER";

export const GET_SCHEDULE_DETAILS_TODAY = "GET_SCHEDULE_DETAILS_TODAY";

export const GET_SCHEDULE_DRIVER_TODAY = "GET_SCHEDULE_DRIVER_TODAY";

export const SET_DRIVER_PIN = "SET_DRIVER_PIN";

export const GET_INSPECTION_CATEGORIES = "GET_INSPECTION_CATEGORIES";

export const POST_PRE_INSPECTION_DATA = "POST_PRE_INSPECTION_DATA";

export const DISPATCH_CHECK_IN_TIME = "DISPATCH_CHECK_IN_TIME";

export const RESET_STATE = "RESET_STATE";

//* New Action Types_2

export const DISPATCH_DRIVER_MANIFEST_MOVIL_ID = "DISPATCH_DRIVER_MANIFEST_MOVIL_ID";
