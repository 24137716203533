import Leaflet from "leaflet";

import iconUrlLocation from "../assets/icons/locationIcon.svg";

export const locationIcon: Leaflet.Icon = new Leaflet.Icon({
  iconUrl: iconUrlLocation,
  iconAnchor: [14, 28],
  popupAnchor: [0, -28],
  // tooltipAnchor: [0, 0],
  iconSize: [28, 28],
  className: "bus_icon",
});
