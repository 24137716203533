import React from "react";
import ReactGA from "react-ga4";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./App.scss";
import MapComponent from "./components/MapComponent";
import HomeComponent from "./components/HomeComponent";
import Settings from "./components/Settings";
import TimePoints from "./components/TimePoints";
import Layout from "./layout/Layout";
import { appName, fetchInterval } from "./config";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import {
  dispatchCurrentBusId,
  getAgencyInfo,
  getAllBusesList,
  getCurrentBusInfo,
  getRouteDetails,
  getScheduleDetailsForToday,
  getTripInfo,
  showInformationModal,
} from "./redux/actions";
import InitialModal from "./layout/InitialModal";
import {
  selectAgencyId,
  selectChosenAgency,
  selectCredentials,
  selectCurrentBusData,
  selectCurrentBusId,
  selectDriverRoutes,
  selectFromBusesActiveArray,
  selectFromBusesNonActiveArray,
  selectLoginStatus,
  selectScheduleDetails,
  selectShowInformationModal,
  selectShowInitialModal,
} from "./redux/selectors";
import InfoModal from "./layout/InfoModal";
import LoginComponent from "./layout/LoginComponent";
import { dateToString } from "./utils/helpers";
import RouteInformation from "./components/RouteInformation";
import PreInspection from "./components/post_data/PreInspection";
import PassengersCount from "./components/post_data/PassengersCount";
import LeavingYard from "./components/post_data/LeavingYard";
import ReturnToYard from "./components/post_data/ReturnToYard";

//* Google Analytics
const TRACKING_ID = process.env.REACT_APP_googleTrackingId as string;
ReactGA.initialize(TRACKING_ID);

function App(): JSX.Element {
  const dispatch: Dispatch = useAppDispatch();

  const chosenAgencyFromRedux: Agency = useAppSelector<Agency>(selectChosenAgency);
  const busesArrayActiveFromRedux: BusArrayObject[] = useAppSelector<BusArrayObject[]>(selectFromBusesActiveArray);
  const busesArrayNonActiveFromRedux: BusArrayObject[] = useAppSelector<BusArrayObject[]>(selectFromBusesNonActiveArray);
  const currentBusIdFromRedux: BusArrayObject = useAppSelector<BusArrayObject>(selectCurrentBusId);
  const showInformationModalFromRedux: boolean = useAppSelector<boolean>(selectShowInformationModal);
  const loginStatusFromRedux: boolean = useAppSelector<boolean>(selectLoginStatus);
  const showInitialModalFromRedux: boolean = useAppSelector<boolean>(selectShowInitialModal);
  const currentBusDataFromRedux: BusInfo = useAppSelector<BusInfo>(selectCurrentBusData);
  const credentialsFromRedux: boolean = useAppSelector<boolean>(selectCredentials);
  const agencyIdFromRedux: string = useAppSelector<string>(selectAgencyId);
  const driverRoutesFromRedux: DriverRoute[] = useAppSelector<DriverRoute[]>(selectDriverRoutes);
  const driverScheduleDetailsFromRedux: ScheduleDetails[] = useAppSelector<ScheduleDetails[]>(selectScheduleDetails);

  const [currentBusId, setCurrentBusId] = React.useState<string>("");
  const [showChangeBusModal, setShowChangeBusModal] = React.useState<boolean>(false);
  const [showInfoModal, setShowInfoModal] = React.useState<boolean>(false);
  const [currentBus, setCurrentBus] = React.useState<BusInfo | null>(null);

  //* Show Initial Modal (change bus)
  React.useEffect(() => {
    setShowChangeBusModal(showInitialModalFromRedux);
  }, [showInitialModalFromRedux]);

  //* Setting app name
  React.useEffect(() => {
    document.title = appName;
  });

  //* Initial dispatching showInformationModal, showSettingsModal, showPostModal to false
  React.useEffect(() => {
    dispatch(showInformationModal(false));
  }, [dispatch]);

  //* Setting showInfoModal from showInformationModalFromRedux
  React.useEffect(() => {
    setShowInfoModal(showInformationModalFromRedux);
  }, [showInformationModalFromRedux]);

  //* Initial fetch - get initial agency info + get all vehicles' Ids
  React.useEffect(() => {
    if (chosenAgencyFromRedux && chosenAgencyFromRedux?.agencyName) {
      (async function () {
        // console.log("chosenAgencyFromRedux?.agencyName:", chosenAgencyFromRedux?.agencyName);
        await dispatch(getAgencyInfo());
        await dispatch(getAllBusesList());
      })();
    }
  }, [chosenAgencyFromRedux, dispatch]);

  //* Initial - dispatch current busId
  React.useEffect(() => {
    if (currentBusId && busesArrayActiveFromRedux) {
      const currentBusObject_Active: BusArrayObject = busesArrayActiveFromRedux.filter(
        (elem) => elem.busId === currentBusId
      )[0];
      const currentBusObject2_NonActive: BusArrayObject = busesArrayNonActiveFromRedux.filter(
        (elem) => elem.busId === currentBusId
      )[0];
      const currentBusObject = currentBusObject_Active || currentBusObject2_NonActive;
      // console.log({ currentBusObject });
      dispatch(dispatchCurrentBusId(currentBusObject));
    }
  }, [busesArrayActiveFromRedux, busesArrayNonActiveFromRedux, currentBusId, dispatch]);

  //* Get current busId data every fetchInterval
  React.useEffect(() => {
    if (currentBusIdFromRedux) {
      // console.log({ currentBusIdFromRedux });
      const setFetchInterval = () => {
        dispatch(getCurrentBusInfo(currentBusIdFromRedux.busId));
        // console.log("currentBusIdFromRedux data was fetched", new Date().toLocaleTimeString());
      };
      setFetchInterval();
      const fetchingInterval = setInterval(setFetchInterval, fetchInterval) as NodeJS.Timer;
      return () => {
        clearInterval(fetchingInterval);
        // console.log("currentBusIdFromRedux interval was cleared");
      };
    }
  }, [currentBusIdFromRedux, dispatch]);

  //^ Set current Bus to the local state
  React.useEffect(() => {
    if (currentBusDataFromRedux) {
      setCurrentBus(currentBusDataFromRedux);
    }
  }, [currentBusDataFromRedux]);

  //^ Get route info
  React.useEffect(() => {
    if (currentBus) {
      // console.log({ currentBus });
      const routeId = currentBus?.routeId;
      const currentDirection = currentBus?.direction || "";
      const currentTrip = currentBus?.trip;
      // console.log({ routeId, currentDirection, currentTrip });
      if (routeId && currentTrip) {
        dispatch(getRouteDetails(routeId, currentDirection));
        dispatch(getTripInfo(currentTrip));
      }
    }
  }, [currentBus, dispatch]);

  //@ Get scheduleDetailsByDate - today
  React.useEffect(() => {
    if (credentialsFromRedux && agencyIdFromRedux) {
      dispatch(getScheduleDetailsForToday(dateToString(new Date()), agencyIdFromRedux));
    }
  }, [agencyIdFromRedux, credentialsFromRedux, dispatch]);

  //@ Get current driver's vehicle name and setCurrentBusId
  React.useEffect(() => {
    if (
      driverRoutesFromRedux &&
      driverScheduleDetailsFromRedux &&
      busesArrayActiveFromRedux &&
      busesArrayNonActiveFromRedux
    ) {
      // console.log({ driverRoutesFromRedux, driverScheduleDetailsFromRedux });
      const driverRoute = driverRoutesFromRedux[0]?.route || "n/a";
      const driverBlock = driverRoutesFromRedux[0]?.block || "n/a";
      const driverSchedule = driverScheduleDetailsFromRedux?.filter(
        (elem) => elem?.route === driverRoute && elem?.route_block === driverBlock
      );
      // console.log({ driverRoute, driverBlock, driverSchedule });
      const driverVehicleName = driverSchedule[0]?.vehicle;
      // console.log({ driverVehicleName });
      const allBusesArrayFromRedux = ([] as BusArrayObject[]).concat(
        busesArrayActiveFromRedux,
        busesArrayNonActiveFromRedux
      );
      // console.log("allBusesArrayFromRedux:", allBusesArrayFromRedux);
      const currentDriverBus = allBusesArrayFromRedux?.filter((elem) => elem?.vehicleName === driverVehicleName);
      // console.log("currentDriverBus:", currentDriverBus);
      const currentDriverBusId = currentDriverBus[0]?.busId;
      // console.log({ currentDriverBusId });
      setTimeout(() => {
        setCurrentBusId(currentDriverBusId);
      }, 500);
    }
  }, [busesArrayActiveFromRedux, busesArrayNonActiveFromRedux, driverRoutesFromRedux, driverScheduleDetailsFromRedux]);

  return (
    <React.Fragment>
      {loginStatusFromRedux ? (
        <React.Fragment>
          <Routes>
            <Route element={<Layout />}>
              <Route path="/" element={<HomeComponent />} />
              <Route path="route_info" element={<RouteInformation />} />

              <Route path="pre_inspection" element={<PreInspection />} />
              <Route path="leaving_yard" element={<LeavingYard />} />
              <Route path="passenger_count" element={<PassengersCount />} />
              <Route path="return_to_yard" element={<ReturnToYard />} />

              <Route path="timepoints" element={<TimePoints />} />
              <Route path="map" element={<MapComponent />} />
              <Route path="settings" element={<Settings />} />
            </Route>
          </Routes>

          {/* //* Modals */}
          <InitialModal showChangeBusModal={showChangeBusModal} setCurrentBusId={setCurrentBusId} />
          <InfoModal showInfoModal={showInfoModal} />

          {/* //* Toastify */}
          <ToastContainer
            position="top-right"
            autoClose={4000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick={true}
            pauseOnFocusLoss={true}
            draggable={false}
            pauseOnHover
            theme="colored"
          />
        </React.Fragment>
      ) : (
        <LoginComponent />
      )}
    </React.Fragment>
  );
}

export default App;
