//^ Breakpoint: large, lg>=992

//- TA Miami-Dade
//* Imports
import logoTA from "./assets/logo/logoTA.png";
//* Eksports
export const logo = logoTA;
//* Api prefix
export const apiPrefixTaSchedule = process.env.REACT_APP_apiPrefixTaSchedule as string;
// Name app
export const defaultLanguage = "en-US";
export const appName = "TA Schedule";
// Fetch interval
export const fetchInterval = process.env.NODE_ENV === "development" ? 100 * 1000 : 10 * 1000; //+ fetchInterval
// Bus toleration settings
export const tooFastToleration = 60 * 1 * 1000;
export const delayToleration = -60 * 3 * 1000;
// Map setting
export const busZoom = 16;
export const positionAccuracy = 4;
export const lengthUnit = "miles";
export const timeZone = "America/New_York";

//* Initial bounds (in case TC server is down)
export const initialMapBounds = [
  [25, -83],
  [29, -80],
];

//^ Common Settings - TA Post Api: https://app.swaggerhub.com/apis-docs/transport-america/ta-scheduler/1.0.3
export const credentialsData = {
  grant_type: "client_credentials",
  client_id: process.env.REACT_APP_TA_client_id,
  client_secret: process.env.REACT_APP_TA_client_secret,
  scope: "*",
};
export const axiosHeaders = {
  "content-type": "application/json",
  accept: "application/json",
};
