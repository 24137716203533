import "moment/locale/pl";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import axios, { AxiosResponse } from "axios";

import {
  apiPrefixTaSchedule,
  axiosHeaders,
  defaultLanguage,
  delayToleration,
  positionAccuracy,
  timeZone,
  tooFastToleration,
} from "../config";
import variableColors from "../_App.module.scss";

const { successColor, warningColor, dangerColor, blackColor } = variableColors;

// Add color status
export const showColorStatus = (time: number): { status: string; iconColor: string; strokeColor: string } => {
  let status: string = "";
  let iconColor: string = "";
  let strokeColor: string = "";

  //* Colors were change into bootstrap's colors
  if (time >= delayToleration && time <= tooFastToleration) {
    status = successColor;
    iconColor = successColor;
    strokeColor = blackColor;
  } else if (time > tooFastToleration) {
    status = dangerColor;
    iconColor = dangerColor;
    strokeColor = blackColor;
  } else if (time < delayToleration) {
    status = warningColor;
    iconColor = warningColor;
    strokeColor = blackColor;
  } else {
    status = "";
    iconColor = "";
    strokeColor = "";
  }
  return { status, iconColor, strokeColor };
};

// String translation
export const translateString = (str: string, language: string): string => {
  let stringToReturn: string = "";

  if (!str) {
    return (stringToReturn = "");
  }
  if (language === "pl") {
    return (stringToReturn = str);
  }

  switch (language.includes("en")) {
    case str.includes("na czas"):
      stringToReturn = str.replace("na czas", "on time");
      break;
    case str.includes("wcześniej"):
      stringToReturn = str.replace("wcześniej", "too fast");
      break;
    case str.includes("później"):
      stringToReturn = str.replace("później", "too late");
      break;
    default:
      stringToReturn = str;
  }
  return stringToReturn;
};

// CircleMarker Radius
export const returnRadius = (num: number): number => {
  const newRadius = Math.cbrt(Math.abs(num)) / Math.E;
  const radiusToReturn = Math.max(newRadius, 10);
  // console.log({ radiusToReturn });
  return radiusToReturn;
};

export const shapeArrayOperations = (shapeDirection: Array<Location>) => {
  let arrayDirection = [];
  for (let i = 0; i < shapeDirection.length; i++) {
    arrayDirection.push(shapeDirection[i].loc);
  }
  // console.log("arrayDirection:", arrayDirection);

  const arrayOfArrays = [] as Line[];
  for (let i = 0; i < arrayDirection.length; i++) {
    arrayOfArrays.push(arrayDirection[i]);
  }

  arrayOfArrays.sort(function (line_A, line_B) {
    return line_A.length - line_B.length;
  });
  // console.log("arrayOfArrays:", arrayOfArrays);
  return arrayOfArrays;
};

export const locationAdjust = (latlng: LanLngPoint): { latStr: string; lngStr: string } => {
  const lat = Number(latlng.lat.toFixed(positionAccuracy));
  const long = Number(latlng.lng.toFixed(positionAccuracy));

  let latStr: string = "";
  let lngStr: string = "";

  if (lat >= 0) {
    latStr = `${lat}° N`;
  } else {
    latStr = `${-1 * lat}° S`;
  }
  if (long >= 0) {
    lngStr = `${long}° E`;
  } else {
    lngStr = `${-1 * long}° W`;
  }
  return { latStr, lngStr };
};

// DynamicTextColor -> function taken from: https://wunnle.com/dynamic-text-color-based-on-background
export const dynamicTextColor = (bgColor: string): string => {
  function getRGB(color: string): number {
    return parseInt(color as string, 16) || (Number(color) as number);
  }

  function getsRGB(color: string): number {
    return getRGB(color) / 255 <= 0.03928
      ? getRGB(color) / 255 / 12.92
      : Math.pow((getRGB(color) / 255 + 0.055) / 1.055, 2.4);
  }

  function getLuminance(hexColor: string): number {
    return (
      0.2126 * getsRGB(hexColor.substr(1, 2)) +
      0.7152 * getsRGB(hexColor.substr(3, 2)) +
      0.0722 * getsRGB(hexColor.substr(-2))
    );
  }

  function getContrast(colorToCheck: string, blackWhite: string): number {
    const L1 = getLuminance(colorToCheck);
    const L2 = getLuminance(blackWhite);
    return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05);
  }

  function getTextColor(bgColor: string): string {
    const whiteContrast = getContrast(bgColor, "#ffffff");
    const blackContrast = getContrast(bgColor, "#000000");
    // console.log({ whiteContrast, blackContrast });
    return whiteContrast > blackContrast ? "#ffffff" : "#000000";
  }
  return getTextColor(bgColor);
};

const padTo2Digits = (num: number) => {
  return num.toString().padStart(2, "0");
};

//* Date object to string: YYYY-MM-DD
export const dateToString = (selectedDate: Date) => {
  //* YYYY-MM-DD

  function formatDate(date: Date) {
    return [date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join("-");
  }
  const dateToExport = formatDate(selectedDate);
  // console.log("dateToExport:", dateToExport);
  return dateToExport.split("T")[0];
};

// timeStamp to localeString
export const timestampToString = (num: number | Date) => {
  if (typeof num === "number" || String(num).length === 10) {
    num = (num as number) * 1000;
  }
  const date = typeof num === "number" ? new Date(num) : num;

  const strDate = date.toLocaleString(defaultLanguage, {
    timeZone: timeZone,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  // console.log({strDate})
  return strDate;
};

// timeFromNow
export const timeFromNow = (time: number) => {
  if (String(time).length === 10) {
    time = (time as number) * 1000;
  }
  const dateToSend = moment(time).locale(defaultLanguage).fromNow();
  return dateToSend;
};

//* calculateTimeLength -> works with America/New_York time zone!
// Todo: what when the bus is delayed?
export const calculateTimeLength = (endTime: string): string => {
  const dateString = timestampToString(new Date());
  const startTimeZoneNow = dateString.split(" ")[1];
  // console.log({ endTime, startTimeZoneNow });

  const startTimeArray = [
    Number(startTimeZoneNow.split(":")[0]),
    Number(startTimeZoneNow.split(":")[1]),
    Number(startTimeZoneNow.split(":")[2]),
  ].map((elem) => Math.abs(elem));

  const endTimeArray = [Number(endTime.split(":")[0]), Number(endTime.split(":")[1]), Number(endTime.split(":")[2])].map(
    (elem) => Math.abs(elem)
  );

  const secondsStartTime = startTimeArray[0] * 3600 + startTimeArray[1] * 60 + startTimeArray[0];
  const secondsEndTime = endTimeArray[0] * 3600 + endTimeArray[1] * 60 + endTimeArray[0];

  const totalSeconds = Math.abs(secondsStartTime - secondsEndTime);
  // console.log({ totalSeconds });

  if (totalSeconds === 0) {
    return "---";
  }

  let minutes = Math.floor(totalSeconds / 60);
  const hours = Math.floor(minutes / 60);
  const seconds = totalSeconds % 60;
  //* If seconds are greater than 30, round minutes up (optional)
  minutes = seconds >= 30 ? minutes + 1 : minutes;
  minutes = minutes % 60;

  const stringToReturn =
    hours >= 1 ? `${padTo2Digits(hours)}h : ${padTo2Digits(minutes)}min` : `${padTo2Digits(minutes)} min`;
  // console.log({ stringToReturn });
  return stringToReturn;
};

// Remove duplicates from array of objects
export const removeDuplicates = (array: Point2[]): Point2[] => {
  const uniqueArr = array.filter((obj: Point2, index: number) => {
    return index === array.findIndex((object: Point2) => obj.lat === object.lat && obj.lon === object.lon);
  });
  return uniqueArr;
};

//- coordinatesConverter for Turf.js [lng, lat] !!!
export const coordinatesConverter = (array: Point2[]): Point[] => {
  const locationsArrays = [] as Point[];
  for (let i = 0; i < array.length; i++) {
    let internalArray: [number, number] = [array[i].lon as number, array[i].lat as number];
    locationsArrays.push(internalArray);
  }
  return locationsArrays;
};

// Limit numbers between 0 and 100
export const limitNumberWithinRange = (num: number, min?: number, max?: number) => {
  const MIN = min || 0;
  const MAX = max || 100;
  return Math.min(Math.max(num, MIN), MAX);
};

// Get Percent
export const getPercent = (num: number, totalLength: number): number => {
  const percent = Number(((100 * num || 0) / totalLength).toFixed(2));
  // console.log({ percent });
  return percent;
};

// Day of the Week: full name / short
export const getCurrentDayOfTheWeek = (today: Date, isMobile: boolean): string => {
  // console.log({ isMobile });
  const weekDaysLong = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const weekDaysShort = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const weekDays = isMobile ? weekDaysShort : weekDaysLong;
  const currentDayOfTheWeek = weekDays[today.getDay()];
  return currentDayOfTheWeek;
};

// Inspection items - deep copy
export const getInspectionItems = (inspectionSet: PreInspection): PreInspectionItem[] => {
  const itemsArray = inspectionSet?.items;
  const stateItemsArray = itemsArray.map((item) => {
    return Object.assign(
      {},
      { id: item.id, description: item.description, status: item.status, isrequired: item.isrequired }
    );
  });
  // const stateItemsArrayDeepCopy =structuredClone(stateItemsArray);
  const stateItemsArrayDeepCopy = JSON.parse(JSON.stringify(stateItemsArray));
  // console.log("stateItemsArrayDeepCopy:", stateItemsArrayDeepCopy, stateItemsArrayDeepCopy === stateItemsArray);
  return stateItemsArrayDeepCopy;
};

//* Pre-inspection together items
export const concatPreInspectionItem = (
  breakSystemInspectionState: PreInspectionItem[],
  insideInspectionState: PreInspectionItem[],
  outsideInspectionState: PreInspectionItem[]
): PreInspectionItem[] => {
  const preInspectionTogether = ([] as PreInspectionItem[]).concat(
    breakSystemInspectionState,
    insideInspectionState,
    outsideInspectionState
  );
  return preInspectionTogether;
};

//* Pre-inspection items preparation to send to the api
export const prepareVehicleInspectionDetails = (
  preInspectionTogether: PreInspectionItem[]
): {
  item_id: number;
  pass: boolean;
}[] => {
  let vehicleInspectionDetailsToSend = preInspectionTogether.map((item) => {
    return Object.assign({}, { item_id: item.id, pass: item.status });
  });
  vehicleInspectionDetailsToSend = vehicleInspectionDetailsToSend.sort((a, b) => a.item_id - b.item_id);
  return vehicleInspectionDetailsToSend;
};

// dateString to timeString
export const getInspectionTime = (date: string | Date): string => {
  // console.log({ date });
  if (typeof date === "string" && (date === "n/a" || date === "-")) {
    return "n/a";
  } else if (typeof date === "string") {
    const fullTimeString = date.split(" ")[1];
    const timeString = fullTimeString.substring(0, 5);
    return timeString;
  } else if (date instanceof Date) {
    const dateString = date.toString();
    // console.log({dateString});
    const fullTimeString = dateString.split(" ")[4];
    const timeString = fullTimeString.substring(0, 5);
    return timeString;
  } else {
    return "n/a";
  }
};

export const getTimeZoneTime = (): string => {
  // console.log({ date });
  const timeZoneNow = moment(new Date()).tz(timeZone);
  // console.log({ timeZoneNow });
  const fullTimeString = timeZoneNow.toString().split(" ")[4];
  const timeString = fullTimeString.substring(0, 5);
  // console.log({ timeString });
  return timeString;
};

//* Api - routeDetails create
export const routeDetailsAdd = async (dataToPost: YardPostPutData, driverToken: string): Promise<any> => {
  // console.log("dataToPost:", dataToPost);
  const URL = `${apiPrefixTaSchedule}/api/v2/DriverManifests/add`;
  const axiosConfig = {
    headers: { ...axiosHeaders, Authorization: `Bearer ${driverToken}` },
  };
  const response = await axios.post(URL, dataToPost, axiosConfig).catch((error) => {
    if (error.response) {
      console.error("error.response.status:", error.response.status);
    }
    if (error.message) {
      console.error("error.message:", error.message);
    }
  });
  return response;
};

//* Api - routeDetails update
export const routeDetailsUpdate = async (dataToPut: YardPostPutData, driverToken: string): Promise<any> => {
  // console.log("dataToPut:", dataToPut);
  const URL = `${apiPrefixTaSchedule}/api/v2/DriverManifests/routeDetails/update`;
  const axiosConfig = {
    headers: { ...axiosHeaders, Authorization: `Bearer ${driverToken}` },
  };
  const response = await axios.put(URL, dataToPut, axiosConfig).catch((error) => {
    if (error.response) {
      console.error("error.response.status:", error.response.status);
    }
    if (error.message) {
      console.error("error.message:", error.message);
    }
  });
  // await console.log("response:", response);
  return response;
};

//* Api - Create Count Passenger
export const createCountPassenger = async (
  dataToPost: PassengersCreateUpdate,
  driverToken: string
): Promise<void | AxiosResponse<any, any>> => {
  // console.log("dataToPost:", dataToPost);
  const URL = `${apiPrefixTaSchedule}/api/v1/driverManifestMovilStops/save`;
  const axiosConfig = {
    headers: { ...axiosHeaders, Authorization: `Bearer ${driverToken}` },
  };
  const response = await axios.post(URL, dataToPost, axiosConfig).catch((error) => {
    if (error.response) {
      console.error("error.response.status:", error.response.status);
    }
    if (error.message) {
      console.error("error.message:", error.message);
    }
  });
  // await console.log("response:", response);
  return response;
};

// //* Api - Update Passengers Count (TripSummary.tsx) - component REMOVED!
// export const passengersUpdate = async (
//   dataToPut: PassengersCreateUpdate,
//   driverToken: string
// ): Promise<void | AxiosResponse<any, any>> => {
//   // console.log("dataToPut:", dataToPut);
//   const URL = `${apiPrefixTaSchedule}/api/v2/DriverManifests/passengers/update`;
//   const axiosConfig = {
//     headers: { ...axiosHeaders, Authorization: `Bearer ${driverToken}` },
//   };

//   const response = axios.put(URL, dataToPut, axiosConfig).catch((error) => {
//     if (error.response) {
//       console.error("error.response.status:", error.response.status);
//     }
//     if (error.message) {
//       console.error("error.message:", error.message);
//     }
//   });
//   // await console.log("response:", response);
//   return response;
// };

export const cutSeconds = (str: string): string | null => {
  if (!str) return null;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hours, minutes, seconds] = str?.split(":");
  const newStr = `${hours}:${minutes}`;
  // console.log("newStr:", newStr);
  return newStr;
};

//* Toastify
export const infoNotify = (infoType: string, message: string) => {
  if (infoType === "error") {
    toast.error(message);
  } else if (infoType === "warning") {
    toast.warning(message);
  } else if (infoType === "success") {
    toast.success(message);
  } else if (infoType === "info") {
    toast.info(message);
  } else {
    toast(message);
  }
};
