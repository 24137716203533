import React from "react";
import { ThreeCircles } from "react-loader-spinner";
import styled from "styled-components";

import variableColors from "../_App.module.scss";

const { primaryColor } = variableColors;

const SpinnerDiv = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

const AppSpinner = (): JSX.Element => {
  return (
    <React.Fragment>
      <SpinnerDiv>
        <ThreeCircles
          height="120"
          width="120"
          color={primaryColor}
          ariaLabel="three-circles-rotating"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          outerCircleColor=""
          innerCircleColor=""
          middleCircleColor=""
        />
      </SpinnerDiv>
    </React.Fragment>
  );
};

export default AppSpinner;
