import React from "react";
import styled from "styled-components";
import { Container, Row, Form, Col, ButtonGroup, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { ComponentContainer } from "../Settings";
import TopInfoComponent from "../../layout/TopInfoComponent";
import useNetworkStatus from "../../services/useNetworkStatus";
import CommonTopInfoComponent, { CommonTopInfoComponentContainer } from "./CommonTopInfoComponent";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  selectCheckInTime,
  selectDriverData,
  selectPreInspectionResponseData,
  selectScheduleDriverToday,
  selectTripInfo,
} from "../../redux/selectors";
import { cutSeconds, getInspectionTime, infoNotify, routeDetailsAdd, routeDetailsUpdate } from "../../utils/helpers";
import variableColors from "../../_App.module.scss";
import { dispatchDriverManifestMovilId } from "../../redux/actions";

const { dangerColor, appBackgroundColorWhiteSmoke, secondaryColor } = variableColors;

const LeavingYardContainer = styled(ComponentContainer)`
  background-color: inherit;
`;

const LeavingYardFormContainer = styled(CommonTopInfoComponentContainer)`
  background-color: inherit;
  div.mb-3 {
    margin-top: 0;
    margin-bottom: 0.75rem !important;
    label {
      border-bottom: 1px solid ${dangerColor};
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      margin-bottom: 0.5rem !important;
    }
    input {
      border-top: none !important;
      border-radius: 0;
    }
    input.name-info {
      background-color: ${secondaryColor} !important;
      padding-left: 0.25rem !important;
      &::placeholder {
        color: ${appBackgroundColorWhiteSmoke};
      }
    }
    input.input-updatable {
      background-color: ${appBackgroundColorWhiteSmoke} !important;
      background-image: url("edit.svg") !important;
      background-repeat: no-repeat;
      background-position: right;
      background-size: 22px 22px;
      cursor: pointer;
    }
  }
`;

const LeavingYard = (): JSX.Element => {
  const { isMobile } = useNetworkStatus();
  const navigate = useNavigate();
  const dispatch: Dispatch = useAppDispatch();

  const driverData: DriverData = useAppSelector<DriverData>(selectDriverData);
  const scheduleDriverTodayFromRedux: RootState = useAppSelector<RootState>(selectScheduleDriverToday);
  const checkInTimeFromRedux: string = useAppSelector<string>(selectCheckInTime);
  const preInspectionResponseDataFromRedux: PreInspectionResponseData =
    useAppSelector<PreInspectionResponseData>(selectPreInspectionResponseData);
  const currentTripInfoFromRedux: TripInfo = useAppSelector<TripInfo>(selectTripInfo);

  const [latestOdometerFirstStop, setLatestOdometerFirstStop] = React.useState<number>(0);
  const [leavingYardData, setLeavingYardData] = React.useState<LeavingYard | null>(null);
  const [leavingYardDataToPostPut, setLeavingYardDataToPostPut] = React.useState<YardPostPutData | null>(null);
  // console.log({ leavingYardDataToPostPut });

  React.useEffect(() => {
    if (scheduleDriverTodayFromRedux && checkInTimeFromRedux && preInspectionResponseDataFromRedux) {
      const leaveYard = scheduleDriverTodayFromRedux?.leave_yard_time || "n/a";
      const firstStop =
        scheduleDriverTodayFromRedux?.stops?.[0]?.departure_time ||
        cutSeconds(currentTripInfoFromRedux?.schedule?.[0]?.departureTime) ||
        "n/a";
      const dateOfInspection = preInspectionResponseDataFromRedux?.result?.dateOfInspection || "n/a";

      setLatestOdometerFirstStop(scheduleDriverTodayFromRedux?.latest_odometer || 0);
      setLeavingYardData({
        leaveYard,
        firstStop,
        dateOfInspection,
        checkInTime: checkInTimeFromRedux, //* checkInTime: default value in Redux
        latestOdometer: scheduleDriverTodayFromRedux?.latest_odometer || 0,
      });
    } else {
      setLeavingYardData({
        leaveYard: "n/a",
        firstStop: "n/a",
        dateOfInspection: "n/a",
        checkInTime: "-", //* checkInTime: default value in Redux
        latestOdometer: 0,
      });
      setLatestOdometerFirstStop(0);
    }
  }, [
    checkInTimeFromRedux,
    currentTripInfoFromRedux?.schedule,
    preInspectionResponseDataFromRedux,
    scheduleDriverTodayFromRedux,
  ]);

  React.useEffect(() => {
    if (driverData && scheduleDriverTodayFromRedux) {
      const dataToSend: YardPostPutData = {
        client_id: Number(scheduleDriverTodayFromRedux?.route.client_id),
        driver_id: scheduleDriverTodayFromRedux?.driver_id as string,
        fields: [
          {
            field: "leave_yard_odometer",
            value: leavingYardData?.latestOdometer!,
          },
          {
            field: "first_stop_odometer",
            value: latestOdometerFirstStop,
          },
        ],
        // route_block_id: Number(scheduleDriverTodayFromRedux?.routeblock?.route_id), //* V1: 2023-09
        route_block_id: Number(scheduleDriverTodayFromRedux?.routeblock?.id), //* V2: 2024-04-26
        schedule_detail_id: Number(scheduleDriverTodayFromRedux?.id),
        vehicle_id: String(scheduleDriverTodayFromRedux?.vehicle_id),
      };
      setLeavingYardDataToPostPut(dataToSend);
    }
  }, [driverData, latestOdometerFirstStop, leavingYardData, scheduleDriverTodayFromRedux]);

  const postPutNavigate = async (path: string, color: string): Promise<void> => {
    try {
      const leavingYardDataToPost = Object.assign({}, leavingYardDataToPostPut!);
      delete leavingYardDataToPost.fields;
      // console.log("leavingYardDataToPost:", leavingYardDataToPost);

      //* /api/v2/DriverManifests/add
      const postResponse = await routeDetailsAdd(leavingYardDataToPost!, driverData?.token);
      // console.log("postResponse:", postResponse);

      const driverManifestMovilIdPost = postResponse?.data?.id;
      // console.log("driverManifestMovilIdPost:", driverManifestMovilIdPost);

      if (postResponse.status === 200 || postResponse.status === 201) {
        // console.log("postResponse.status:", postResponse.status);

        //* /api/v2/DriverManifests/routeDetails/update
        const putResponse = await routeDetailsUpdate(leavingYardDataToPostPut!, driverData?.token);
        // console.log("putResponse:", putResponse);
        const driverManifestMovilIdPut = putResponse?.data?.id;
        // console.log("driverManifestMovilIdPut:", driverManifestMovilIdPut);

        if (putResponse.status === 200 || putResponse.status === 201) {
          // console.log("putResponse.status:", putResponse.status);
          const driverManifestMovilId: number = driverManifestMovilIdPost || driverManifestMovilIdPut;
          // console.log("driverManifestMovilId:", driverManifestMovilId);
          await dispatch(dispatchDriverManifestMovilId(driverManifestMovilId));
          await infoNotify(color, "The data has been sent");
          await navigate(path);
        }
      }
    } catch (error) {
      console.log((error: Error) => console.log(error));
      await infoNotify("error", "Error");
    }
  };

  const LeavingYardForm = (leavingYardData: LeavingYard): JSX.Element => {
    const { checkInTime, dateOfInspection, leaveYard, firstStop, latestOdometer } = leavingYardData;

    return (
      <Form className="top-bar-info">
        <fieldset disabled={false}>
          <Container fluid={true}>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="leaving-yard-name">Info Name</Form.Label>
                  <Form.Control
                    id="leaving-yard-name"
                    placeholder="Check-In"
                    readOnly={true}
                    className="name-info"
                    disabled={true}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="leaving-yard-time">Actual Time</Form.Label>
                  <Form.Control id="leaving-yard-time" defaultValue={checkInTime} readOnly={true} disabled={true} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Odometer</Form.Label>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Control
                    id="leaving-yard-name2"
                    placeholder="Vehicle pre-inspection"
                    readOnly={true}
                    className="name-info"
                    disabled={true}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Control
                    id="leaving-yard-time2"
                    defaultValue={getInspectionTime(dateOfInspection)}
                    readOnly={true}
                    disabled={true}
                  />
                </Form.Group>
              </Col>
              <Col>{null}</Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Control
                    id="leaving-yard-name3"
                    placeholder="Leave Yard"
                    readOnly={true}
                    className="name-info"
                    disabled={true}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Control id="leaving-yard-time3" defaultValue={leaveYard} readOnly={true} disabled={true} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Control id="leaving-yard-odometer3" defaultValue={latestOdometer} readOnly={true} disabled={true} />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Control
                    id="leaving-yard-name4"
                    placeholder="First Stop"
                    readOnly={true}
                    className="name-info"
                    disabled={true}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Control id="leaving-yard-time4" defaultValue={firstStop} readOnly={true} disabled={true} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Control
                    className="input-updatable"
                    disabled={false}
                    id="leaving-yard-odometer4"
                    value={latestOdometerFirstStop}
                    readOnly={false}
                    onChange={(event) => setLatestOdometerFirstStop(Number(event.target.value))}
                  />
                </Form.Group>
              </Col>
            </Row>
            <br />
          </Container>
        </fieldset>

        <ButtonGroup className="w-100 mt-1">
          <Button
            type="button"
            variant="success"
            className="w-50"
            onClick={() => postPutNavigate("/map", "success")}
            disabled={driverData && leavingYardDataToPostPut ? false : true}
          >
            Send data and go to map
          </Button>
          <Button
            type="button"
            variant="primary"
            className="w-50"
            onClick={() => postPutNavigate("/passenger_count", "info")}
            disabled={driverData && leavingYardDataToPostPut ? false : true}
          >
            Send data and go to pax count
          </Button>
        </ButtonGroup>
      </Form>
    );
  };

  return (
    <React.Fragment>
      <LeavingYardContainer $isMobile={isMobile}>
        <TopInfoComponent fontSize="90%" />
        <CommonTopInfoComponent />
        <LeavingYardFormContainer>{leavingYardData && LeavingYardForm(leavingYardData)}</LeavingYardFormContainer>
      </LeavingYardContainer>
    </React.Fragment>
  );
};

export default LeavingYard;
