import React, { ReactNode } from "react";
import styled, { css } from "styled-components";
import { Button, ButtonGroup, FloatingLabel, Form, ListGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { ComponentContainer } from "../Settings";
import TopInfoComponent from "../../layout/TopInfoComponent";
import useNetworkStatus from "../../services/useNetworkStatus";
import variableColors from "../../_App.module.scss";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getInspectionCategories, postPreInspectionData } from "../../redux/actions";
import {
  selectDriverData,
  selectDriverPinNumber,
  selectPreInspectionResponseData,
  selectPreTripInspectionData,
  selectScheduleDriverToday,
} from "../../redux/selectors";
import { PRE_TRIP_INSPECTION } from "../../data/constants";
import {
  concatPreInspectionItem,
  dateToString,
  getInspectionItems,
  infoNotify,
  prepareVehicleInspectionDetails,
} from "../../utils/helpers";
import CanvasESignature from "./CanvasESignature";

const { darkColor, appBackgroundColor, successColor, dangerColor, appBackgroundColorWhiteSmoke } = variableColors;

const PreInspectionContainer = styled(ComponentContainer)`
  background-color: ${appBackgroundColor};
  gap: 0.25rem;
  h3 {
    padding-top: 0rem;
  }
`;

const InspectionHeader = styled.h3`
  text-transform: uppercase;
  width: 100%;
  background-color: ${darkColor};
  color: ${appBackgroundColorWhiteSmoke};
  text-align: center;
  margin-top: 1.5rem;
`;

const flexMixin = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
`;

const inputMixin = css`
  input {
    background-color: ${appBackgroundColorWhiteSmoke};
    border-color: ${appBackgroundColorWhiteSmoke};
  }
  div.status-ok {
    input:checked {
      background-color: ${successColor};
      border-color: ${successColor};
    }
  }
  div.status-bad {
    input:checked {
      background-color: ${dangerColor};
      border-color: ${dangerColor};
    }
  }
`;

const InlineListItem = styled.div`
  width: 100%;
  ${flexMixin}
  justify-content: space-between;
  div.switch-item {
    width: 50%;
    overflow: hidden;
  }
`;

const InlineListItem2 = styled(InlineListItem)`
  font-size: 90%;
  font-weight: bold;
  padding-left: 0.5rem;
  margin-top: 0.5rem;
  select {
    max-width: 50%;
    text-align: center;
    font-weight: bold;
  }
  form {
    width: 50%;
    font-size: bold;
    ${flexMixin}
    justify-content: center;
    ${inputMixin};
  }
`;

const CommentsContainer = styled.div`
  font-size: 90%;
  padding: 0 0.5rem;
  margin-top: 0.5rem;
  width: 100%;
  textarea {
    background-color: ${appBackgroundColorWhiteSmoke};
  }
  form {
    ${flexMixin}
    justify-content: center;
    margin-top: 0.5rem;
    ${inputMixin};
    font-weight: bold;
  }
  button {
    margin-top: 1rem;
    font-weight: bold;
  }
`;

const PreInspection = (): JSX.Element => {
  const { isMobile } = useNetworkStatus();
  const dispatch: Dispatch = useAppDispatch();
  const navigate = useNavigate();

  const driverDataFromRedux: DriverData = useAppSelector<DriverData>(selectDriverData);
  const preTripInspectionDataFromRedux: PreInspection[] = useAppSelector<PreInspection[]>(selectPreTripInspectionData);
  const driverPIN = useAppSelector<number>(selectDriverPinNumber);
  const scheduleDriverTodayFromRedux: RootState = useAppSelector<RootState>(selectScheduleDriverToday);
  const preInspectionResponseDataFromRedux: PreInspectionResponseData =
    useAppSelector<PreInspectionResponseData>(selectPreInspectionResponseData);

  //* Pre-inspection Items - from api
  const [outsideInspection, setOutsideInspection] = React.useState<PreInspection | null>(null);
  const [insideInspection, setInsideInspection] = React.useState<PreInspection | null>(null);
  const [breakSystemInspection, setBreakSystemInspection] = React.useState<PreInspection | null>(null);
  //* Inspection items state - deep copy
  const [outsideInspectionState, setOutsideInspectionState] = React.useState<PreInspectionItem[]>([]);
  const [insideInspectionState, setInsideInspectionState] = React.useState<PreInspectionItem[]>([]);
  const [breakSystemInspectionState, setBreakSystemInspectionState] = React.useState<PreInspectionItem[]>([]);
  //* Other Pre-inspection state
  const [fuelLevelStatus, setFuelLevelStatus] = React.useState<string>("Full");
  const [oilLevelStatus, setOilLevelStatus] = React.useState<boolean>(true);
  const [coolantLevelStatus, setCoolantLevelStatus] = React.useState<boolean>(true);
  const [comment, setComment] = React.useState<string>("");
  //* Satisfactory/ Required repair
  const [busStatusState, setBusStatusState] = React.useState<boolean>(true);
  //* isBusReadyToGo
  const [isBusReadyToGo, setIsBusReadyToGo] = React.useState<boolean>(false);
  const [imageData, setImageData] = React.useState<string | null>("");
  const [preparedDataToSend, setPreparedDataToSend] = React.useState<RootState>(null);
  const [isNavigateButtonEnabled, setIsNavigateButtonEnabled] = React.useState<boolean>(false);
  // console.log("imageData:", imageData);

  //* Pre-inspection Items state - from api - switches handle
  const handleOnChangeFunction = (id: number, setStateFunction: Function, state: PreInspectionItem[]) => {
    const updatedState = state.map((inspectionItem) => {
      if (inspectionItem.id === id) {
        return { ...inspectionItem, status: !inspectionItem.status };
      }
      return inspectionItem;
    });
    setStateFunction(updatedState);
  };

  const onOilLevelStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event?.target?.value === "Full" ? setOilLevelStatus(true) : setOilLevelStatus(false);
  };

  const onCoolantLevelStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event?.target?.value === "Full" ? setCoolantLevelStatus(true) : setCoolantLevelStatus(false);
  };

  const onBusStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event?.target?.value === "Satisfactory" ? setBusStatusState(true) : setBusStatusState(false);
  };

  const onFuelLevelStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    // console.log(event?.target.value);
    setFuelLevelStatus(event.target.value);
  };

  //* Get pre-inspection categories
  React.useEffect(() => {
    if (driverDataFromRedux) {
      const driverToken = driverDataFromRedux?.token;
      // console.log({ driverToken });
      dispatch(getInspectionCategories(PRE_TRIP_INSPECTION, driverToken));
    }
  }, [dispatch, driverDataFromRedux]);

  //* Pre-inspection Items state - from api
  React.useEffect(() => {
    if (preTripInspectionDataFromRedux) {
      // console.log("preTripInspectionDataFromRedux", preTripInspectionDataFromRedux);
      const emptyPreInspection = {
        description: "",
        items: [],
      };
      const [outsideInspectionFromRedux, insideInspectionFromRedux, breakSystemInspectionFromRedux] =
        preTripInspectionDataFromRedux && preTripInspectionDataFromRedux.length
          ? [preTripInspectionDataFromRedux[0], preTripInspectionDataFromRedux[1], preTripInspectionDataFromRedux[2]]
          : [emptyPreInspection, emptyPreInspection, emptyPreInspection];
      setOutsideInspection(outsideInspectionFromRedux as PreInspection);
      setInsideInspection(insideInspectionFromRedux as PreInspection);
      setBreakSystemInspection(breakSystemInspectionFromRedux as PreInspection);
    }
  }, [preTripInspectionDataFromRedux]);

  //* Inspection items state - deep copy
  React.useEffect(() => {
    if (breakSystemInspection && insideInspection && outsideInspection) {
      setOutsideInspectionState(getInspectionItems(outsideInspection));
      setInsideInspectionState(getInspectionItems(insideInspection));
      setBreakSystemInspectionState(getInspectionItems(breakSystemInspection));
    }
  }, [breakSystemInspection, insideInspection, outsideInspection]);

  //* General bus state to unblock the load image button
  React.useEffect(() => {
    if (
      breakSystemInspectionState &&
      breakSystemInspectionState.length &&
      insideInspectionState &&
      insideInspectionState.length &&
      outsideInspectionState &&
      outsideInspectionState.length
    ) {
      const preInspectionTogether = concatPreInspectionItem(
        breakSystemInspectionState,
        insideInspectionState,
        outsideInspectionState
      );
      // console.log("preInspectionTogether:", preInspectionTogether);
      const preInspectionTogetherRequired = preInspectionTogether.filter((elem) => elem.isrequired === true);
      // console.log("preInspectionTogetherRequired:", preInspectionTogetherRequired);
      const preInspectionTogetherRequiredStatus: boolean[] = preInspectionTogetherRequired.map((elem) => elem.status);
      // console.log("preInspectionTogetherRequiredStatus:", preInspectionTogetherRequiredStatus);
      const allPositiveValues = preInspectionTogetherRequiredStatus.every((elem) => elem === true);
      // console.log("allPositiveValues:", allPositiveValues);
      allPositiveValues === true && busStatusState === true ? setIsBusReadyToGo(true) : setIsBusReadyToGo(false);
    }
  }, [breakSystemInspectionState, busStatusState, insideInspectionState, outsideInspectionState]);

  //* Preparing and sending data to send to the api
  React.useEffect(() => {
    if (
      breakSystemInspectionState &&
      breakSystemInspectionState.length &&
      insideInspectionState &&
      insideInspectionState.length &&
      outsideInspectionState &&
      outsideInspectionState.length &&
      busStatusState &&
      isBusReadyToGo &&
      imageData
    ) {
      const eSignature = imageData.split(";base64,")[1];
      const dateOfInspection = dateToString(new Date());
      const vehicleInspectionsDetails = prepareVehicleInspectionDetails(
        concatPreInspectionItem(breakSystemInspectionState, insideInspectionState, outsideInspectionState)
      );
      const dataToSend = {
        signature: eSignature,
        type: String(PRE_TRIP_INSPECTION),
        comments: comment,
        dateOfInspection: dateOfInspection,
        fuel: fuelLevelStatus,
        coolant: coolantLevelStatus,
        oil: oilLevelStatus,
        satisfactory: busStatusState,
        pin: String(driverPIN),
        schedule_detail_id: String(scheduleDriverTodayFromRedux?.id),
        vehicle_id: scheduleDriverTodayFromRedux?.vehicle_id,
        vehicle_inspections_details: vehicleInspectionsDetails,
      };
      // console.log("dataToSend:", dataToSend);
      setPreparedDataToSend(dataToSend);
    }
  }, [
    outsideInspectionState,
    insideInspectionState,
    breakSystemInspectionState,
    fuelLevelStatus,
    oilLevelStatus,
    coolantLevelStatus,
    comment,
    busStatusState,
    isBusReadyToGo,
    dispatch,
    driverPIN,
    scheduleDriverTodayFromRedux,
    imageData,
  ]);

  React.useEffect(() => {
    if (preInspectionResponseDataFromRedux && preInspectionResponseDataFromRedux?.status === true) {
      // console.log("preInspectionResponseDataFromRedux:", preInspectionResponseDataFromRedux);
      setIsNavigateButtonEnabled(true);
      infoNotify("success", "The data has been sent");
    }
  }, [preInspectionResponseDataFromRedux]);

  //* Send data to server
  const sendDataToServer = () => {
    if (preparedDataToSend && driverDataFromRedux) {
      const driverToken = driverDataFromRedux?.token;
      dispatch(postPreInspectionData(preparedDataToSend, driverToken));
    }
  };

  // Switches rendering from api
  const RenderedInspectionList = ({
    itemsToRender,
    setStateFunction,
    state,
  }: {
    itemsToRender: PreInspectionItem[];
    setStateFunction: Function;
    state: PreInspectionItem[];
  }): JSX.Element => {
    // console.log("itemsToRender:", itemsToRender);
    const doubledItemsToRender = [] as ReactNode[];

    itemsToRender.forEach((_elem: PreInspectionItem, index: number, array: PreInspectionItem[]) => {
      if (index % 2 === 0) {
        doubledItemsToRender.push(
          <ListGroup.Item variant="dark" key={index}>
            <Form>
              <InlineListItem>
                <div className="switch-item">
                  <Form.Check
                    type="checkbox"
                    id={String(array[index].id)}
                    name={String(array[index].id)}
                    label={array[index].description}
                    className={`vehicle-inspection ${array[index].isrequired ? "inspection-required" : ""}`}
                    reverse={false}
                    checked={array[index].status}
                    onChange={() => handleOnChangeFunction(array[index].id, setStateFunction, state)}
                  />
                </div>
                {array[index + 1] ? (
                  <div className="switch-item">
                    <Form.Check
                      type="checkbox"
                      id={String(array[index + 1].id)}
                      name={String(array[index + 1].id)}
                      label={array[index + 1].description}
                      className={`vehicle-inspection ${array[index].isrequired ? "inspection-required" : ""}`}
                      reverse={false}
                      checked={array[index + 1].status}
                      onChange={() => handleOnChangeFunction(array[index + 1].id, setStateFunction, state)}
                    />
                  </div>
                ) : null}
              </InlineListItem>
            </Form>
          </ListGroup.Item>
        );
      }
    });
    return <ListGroup className="w-100">{doubledItemsToRender}</ListGroup>;
  };

  //* Liquids rendering
  const LiquidsChecks = (): JSX.Element => {
    return (
      <React.Fragment>
        <InlineListItem2>
          Fuel:
          <Form.Select
            className="bg-dark-subtle text-body-emphasis"
            aria-label="Select fuel level"
            size="sm"
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => onFuelLevelStatusChange(event)}
          >
            <option>{fuelLevelStatus ? fuelLevelStatus : "Select fuel level"}</option>
            <option value="Full">Full</option>
            <option value="3/4">3/4</option>
            <option value="1/2">1/2</option>
            <option value="1/4">1/4</option>
          </Form.Select>
        </InlineListItem2>
        <InlineListItem2>
          Oil:
          <Form>
            <Form.Check
              className="status-ok"
              value="Full"
              inline={true}
              label="Full"
              name="Oil-Full"
              type={"radio"}
              id={"Oil-Full"}
              onChange={(event) => onOilLevelStatusChange(event)}
              checked={oilLevelStatus === true}
            />
            <Form.Check
              className="status-bad"
              value="Add"
              inline={true}
              label="Add"
              name="Oil-add"
              type={"radio"}
              id={"Oil-Add"}
              onChange={(event) => onOilLevelStatusChange(event)}
              checked={oilLevelStatus === false}
            />
          </Form>
        </InlineListItem2>
        <InlineListItem2>
          Coolant:
          <Form>
            <Form.Check
              className="status-ok"
              value="Full"
              inline={true}
              label="Full"
              name="Coolant-Full"
              type={"radio"}
              id={"Coolant-Full"}
              onChange={(event) => onCoolantLevelStatusChange(event)}
              checked={coolantLevelStatus === true}
            />
            <Form.Check
              className="status-bad"
              value="Add"
              inline={true}
              label="Add"
              name="Coolant-add"
              type={"radio"}
              id={"Coolant-Add"}
              onChange={(event) => onCoolantLevelStatusChange(event)}
              checked={coolantLevelStatus === false}
            />
          </Form>
        </InlineListItem2>
      </React.Fragment>
    );
  };

  //* Text Area Rendering
  const CommentTextArea = (): JSX.Element => {
    return (
      <React.Fragment>
        <Form.Group>
          <Form.Label className="fw-bold">Comments:</Form.Label>
          <FloatingLabel controlId="text-area-comments" label="Leave any comment here...">
            <Form.Control
              value={comment}
              onChange={(event) => {
                setComment(event.target.value);
              }}
              as="textarea"
              placeholder="Leave any comment here..."
              style={{ height: "7.5rem" }}
              size="sm"
            />
          </FloatingLabel>
        </Form.Group>
        <Form>
          <Form.Check
            className="status-ok"
            value="Satisfactory"
            inline={true}
            label="Satisfactory"
            name="Satisfactory"
            type={"radio"}
            id={"Satisfactory"}
            onChange={(event) => onBusStatusChange(event)}
            checked={busStatusState === true}
          />
          <Form.Check
            className="status-bad"
            value="Required repair"
            inline={true}
            label="Required repair"
            name="Required_repair"
            type={"radio"}
            id={"Required_repair"}
            onChange={(event) => onBusStatusChange(event)}
            checked={busStatusState === false}
          />
        </Form>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <PreInspectionContainer $isMobile={isMobile}>
        <TopInfoComponent fontSize="90%" />
        {breakSystemInspection && insideInspection && outsideInspection ? (
          <React.Fragment>
            <InspectionHeader>{`${outsideInspection?.description} (${outsideInspection?.items?.length})`}</InspectionHeader>
            <RenderedInspectionList
              itemsToRender={outsideInspectionState}
              setStateFunction={setOutsideInspectionState}
              state={outsideInspectionState}
            />

            <InspectionHeader>{`${insideInspection?.description} (${insideInspection?.items?.length})`}</InspectionHeader>
            <RenderedInspectionList
              itemsToRender={insideInspectionState}
              setStateFunction={setInsideInspectionState}
              state={insideInspectionState}
            />

            <InspectionHeader>{`${breakSystemInspection?.description} (${breakSystemInspection?.items?.length})`}</InspectionHeader>
            <RenderedInspectionList
              itemsToRender={breakSystemInspectionState}
              setStateFunction={setBreakSystemInspectionState}
              state={breakSystemInspectionState}
            />

            <LiquidsChecks />

            <CommentsContainer>
              {CommentTextArea()}

              <ButtonGroup vertical={true} className="w-100 mt-3">
                {/* Canvas eSignature */}
                <CanvasESignature isBusReadyToGo={isBusReadyToGo} imageData={imageData} setImageData={setImageData} />

                <Button
                  className="w-100"
                  onClick={sendDataToServer}
                  disabled={driverDataFromRedux && preparedDataToSend ? false : true}
                  variant={isNavigateButtonEnabled ? "success" : "warning"}
                >
                  {isNavigateButtonEnabled ? "The data has been sent" : "Send data to the server"}
                </Button>

                <Button
                  className="w-100"
                  onClick={() => navigate("/leaving_yard")}
                  disabled={isNavigateButtonEnabled ? false : true}
                  variant="secondary"
                >
                  Continue (go to Leave Yard)
                </Button>
              </ButtonGroup>
            </CommentsContainer>
          </React.Fragment>
        ) : null}
      </PreInspectionContainer>
    </React.Fragment>
  );
};

export default PreInspection;
