import React from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { AiFillMinusCircle, AiFillPlusCircle } from "react-icons/ai";

//* Reusable Input Component for PassengersCount.tsx
const InternalReusableInputComponent = ({
  label,
  value,
  onIncrement,
  onDecrement,
  minValue = 0,
  className = "",
}: {
  label: string;
  value: number;
  onIncrement: () => void;
  onDecrement: () => void;
  minValue?: number;
  className?: string;
}): JSX.Element => {
  return (
    <React.Fragment>
      <Form.Group className="passengers-update">
        <Form.Label className={className}>{label}</Form.Label>
        <InputGroup>
          <Button disabled={value <= minValue} variant="outline-danger" onClick={onDecrement}>
            <AiFillMinusCircle style={{ width: 22, height: "auto" }} />
          </Button>
          <Form.Control value={value} readOnly={true} disabled={true} id={`passenger-count-${label}`} />
          <Button disabled={false} variant="outline-success" onClick={onIncrement}>
            <AiFillPlusCircle style={{ width: 22, height: "auto" }} />
          </Button>
        </InputGroup>
      </Form.Group>
    </React.Fragment>
  );
};

export default InternalReusableInputComponent;
