import React from "react";
import styled from "styled-components";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";

import { selectTripProgressBarData } from "../redux/selectors";
import { useAppSelector } from "../redux/hooks";
import variableColors from "../_App.module.scss";

const { textColor, primaryColor, appBackgroundColorWhiteSmoke, appBackgroundColor, secondaryColor } = variableColors;

const StepContainer = styled.div`
  color: ${textColor};
  width: 16px;
  height: 60px;
  font-size: 12px;
  font-weight: bold;
  background-color: ${appBackgroundColor};
  border-radius: 60px;
  border: 1px solid ${textColor};
  display: flex;
  justify-content: center;
  align-items: center;
  writing-mode: vertical-lr;
  text-orientation: mixed;
  &.accomplished {
    background-color: ${primaryColor};
    color: ${appBackgroundColorWhiteSmoke};
  }
`;

const ProgressBarContainer = styled.div`
  max-width: calc(100% - 16px);
  min-height: 60px;
  margin-top: 1.8rem;
  margin-bottom: -1rem;
  margin-left: 8px;
  margin-right: 8px;
`;

const ProgressBarWithPoints = ({ percent }: { percent: number }): JSX.Element => {
  const currentTripProgressBarDataFromRedux: BusStop[] = useAppSelector<BusStop[]>(selectTripProgressBarData);

  const [tripProgressBarData, setTripProgressBarData] = React.useState<BusStop[] | null>(null);
  // console.log("tripProgressBarData:", tripProgressBarData);

  React.useEffect(() => {
    if (currentTripProgressBarDataFromRedux) {
      setTripProgressBarData(currentTripProgressBarDataFromRedux);
    }
  }, [currentTripProgressBarDataFromRedux]);

  return (
    <React.Fragment>
      <ProgressBarContainer>
        <ProgressBar percent={percent} unfilledBackground={secondaryColor}>
          {tripProgressBarData?.map((elem, index) => {
            return (
              <Step key={index + elem.id}>
                {({ accomplished }: { accomplished: string }) => (
                  <StepContainer className={`${accomplished ? "accomplished" : null}`}>{elem.id}</StepContainer>
                )}
              </Step>
            );
          })}
        </ProgressBar>
      </ProgressBarContainer>
    </React.Fragment>
  );
};

export default ProgressBarWithPoints;
