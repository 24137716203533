import React from "react";
import * as ReactDOMServer from "react-dom/server";
import Leaflet from "leaflet";

import MapBusIcon from "./MapBusIcon";

export const createBusIcon = (iconColor: string, strokeColor: string, currentRotation: number): Leaflet.DivIcon => {
  return Leaflet.divIcon({
    iconSize: [48, 48],
    iconAnchor: [24, 24],
    popupAnchor: [0, -24],
    tooltipAnchor: [0, 0],
    className: "bus_icon",
    html: ReactDOMServer.renderToString(
      <React.Fragment>
        <div
          style={{
            backgroundColor: "transparent",
            transform: `rotate(${currentRotation}deg)`,
          }}
        >
          <MapBusIcon iconColor={iconColor} strokeColor={strokeColor} />
        </div>
      </React.Fragment>
    ),
  });
};
