import React from "react";
import { CircleMarker, MapContainer, ScaleControl, TileLayer } from "react-leaflet";
import styled from "styled-components";
import L, { Map } from "leaflet";
import Control from "react-leaflet-custom-control";
import { Button, OverlayTrigger, Tooltip as BootstrapTooltip, ButtonGroup } from "react-bootstrap";
import { Geolocation } from "@capacitor/geolocation";
import "leaflet-rotate";

import { useAppDispatch, useAppSelector } from "../redux/hooks";
import AppSpinner from "../layout/AppSpinner";
import {
  selectCurrentBusData,
  selectGrayMap,
  selectMapExtents,
  selectRouteInfo,
  selectSetMapAutorotation,
  selectShowTooltip,
} from "../redux/selectors";
import MapVehicleComponent from "./MapVehicleComponent";
import { dispatchCurrentZoom, showInformationModal } from "../redux/actions";
import MapRouteDetails from "./MapRouteDetails";
import MapLocationIcon from "./MapLocationIcon";
import { busZoom, initialMapBounds } from "../config";
import { locationIcon } from "../utils/mapIcons";
import { locationAdjust } from "../utils/helpers";
import zoomInIcon from "../assets/icons/zoomInIcon.svg";
import zoomOutIcon from "../assets/icons/zoomOutIcon.svg";
import infoIcon from "../assets/icons/infoIcon.svg";
import MapBusTripInfo from "./MapBusTripInfo";
import useNetworkStatus from "../services/useNetworkStatus";
import variableColors from "../_App.module.scss";

const { secondaryColor, appBackgroundColorWhiteSmoke, orangeColor } = variableColors;

const CustomDivControl = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  justify-content: center;
  gap: 0.5rem;
`;

export const HR = styled.hr`
  margin: 0;
  border: 2px solid ${appBackgroundColorWhiteSmoke};
  width: 100%;
  opacity: 1;
`;

const MapComponent = (): JSX.Element => {
  const locateIconInitialColor: string = secondaryColor;

  const { isMobile } = useNetworkStatus();
  const dispatch: Dispatch = useAppDispatch();

  const mapExtents: MapExtent = useAppSelector<MapExtent>(selectMapExtents);
  const currentBusDataFromRedux: BusInfo = useAppSelector<BusInfo>(selectCurrentBusData);
  const currentRouteDetails: RouteDetails = useAppSelector<RouteDetails>(selectRouteInfo);
  const grayMapFromRedux: boolean = useAppSelector<boolean>(selectGrayMap);
  const showTooltipFromRedux: boolean = useAppSelector<boolean>(selectShowTooltip);
  const mapAutorotationFromRedux: boolean = useAppSelector<boolean>(selectSetMapAutorotation);
  // console.log({ mapAutorotationFromRedux });

  const [bounds, setBounds] = React.useState<Bounds>(initialMapBounds as Bounds);
  const [mapView, setMapView] = React.useState<Map | null>(null);
  const [currentBus, setCurrentBus] = React.useState<BusInfo | null>(null);
  const [locateIconCurrentColor, setLocateIconCurrentColor] = React.useState<string>(locateIconInitialColor);
  const [myLocation, setMyLocation] = React.useState<{ position: LanLngPoint; radius: number } | null>(null);
  const [routeInfo, setRouteInfo] = React.useState<RouteInfo | null>(null);
  const [showLoader, setShowLoader] = React.useState<boolean>(true);
  const [mapBearing, setMapBearing] = React.useState<number>(0);
  // console.log({ mapBearing });

  //* Map - mapBearing - rotate map
  React.useEffect(() => {
    if (mapView && mapAutorotationFromRedux) {
      mapView.setBearing(mapBearing);
    }
  }, [mapAutorotationFromRedux, mapBearing, mapView]);

  //* Set route info
  React.useEffect(() => {
    if (currentRouteDetails) {
      setRouteInfo(currentRouteDetails?.routeInfo);
    }
  }, [currentRouteDetails]);

  //* Show Loader onLoad
  React.useEffect(() => {
    if (bounds && bounds.flat(1).length === 4) {
      setTimeout(() => {
        setShowLoader(false);
      }, 250);
    }
  });

  // Set initial map extents
  React.useEffect(() => {
    if (mapExtents && Object.keys(mapExtents).length >= 1) {
      setTimeout(() => {
        setBounds([
          [mapExtents.minLat, mapExtents.minLon],
          [mapExtents.maxLat, mapExtents.maxLon],
        ]);
      }, 500);
    }
  }, [mapExtents]);

  //* Show Zoom - onLoad
  React.useEffect(() => {
    if (mapView) {
      const currentZoom = mapView.getZoom();
      // console.log({ currentZoom });
      dispatch(dispatchCurrentZoom(currentZoom));
    }
  }, [dispatch, mapView]);

  //* Show Zoom - onZoomChange
  React.useEffect(() => {
    if (!mapView) return;
    mapView.on("zoomend", () => {
      const currentZoom = mapView.getZoom();
      // console.log({ currentZoom });
      dispatch(dispatchCurrentZoom(currentZoom));
    });
  }, [dispatch, mapView]);

  //* Set current Bus to the local state
  React.useEffect(() => {
    if (currentBusDataFromRedux) {
      setCurrentBus(currentBusDataFromRedux);
    }
  }, [currentBusDataFromRedux]);

  //* Following the selected bus on the map + setMapBearing
  React.useEffect(() => {
    if (currentBus && mapView) {
      const currentBusPosition = currentBus?.loc;
      // console.log("currentBusPosition:", currentBusPosition);
      //* Set negated bearing for map autorotation
      setMapBearing(currentBusPosition?.heading * -1);
      mapView.flyTo({ lat: currentBusPosition.lat, lng: currentBusPosition.lon }, busZoom, {
        duration: 0.75,
        easeLinearity: 0.5,
      });
    }
  }, [currentBus, mapView]);

  const locateMe = async (): Promise<void> => {
    if (mapView) {
      await Geolocation.getCurrentPosition()
        .then((currentPosition) => {
          // console.log("currentPosition:", currentPosition);
          const {
            coords: { latitude, longitude, accuracy: radius },
          } = currentPosition;
          // console.log({ latitude, longitude, radius });

          const position = { lat: latitude, lng: longitude };
          mapView.flyTo(position, busZoom, {
            duration: 1.5,
            easeLinearity: 0.5,
          });
          const circle = L.circle(position, radius, {
            stroke: true,
            color: "blueviolet",
            weight: 3,
            opacity: 1,
            fill: true,
            fillOpacity: 0.25,
          });
          circle.addTo(mapView);
          setMyLocation({ position, radius });

          const markerHome = L.marker(position, { icon: locationIcon }).addTo(mapView);
          markerHome
            .bindPopup(
              `<em>You are here: </em><br>
            <b>${locationAdjust(position).latStr}, ${locationAdjust(position).lngStr}</b></br>
            Accuracy: <b>${radius.toFixed(0)} m</b>`
            )
            .openPopup();

          setTimeout(() => {
            setMyLocation(null);
            circle.remove();
            markerHome.remove();
            setLocateIconCurrentColor(locateIconInitialColor);
          }, 8000);
        })
        .catch((error) => {
          console.log({ error });
        });
    }
  };

  const mapZoomIn = (): void => {
    if (!mapView) return;
    if (mapView) {
      mapView.zoomIn();
    }
  };

  const mapZoomOut = (): void => {
    if (!mapView) return;
    if (mapView) {
      mapView.zoomOut();
    }
  };

  return (
    <React.Fragment>
      {!showLoader ? (
        <MapContainer
          ref={(ref) => {
            setMapView(ref);
          }}
          zoomSnap={0.1}
          zoomDelta={0.2}
          maxZoom={18}
          minZoom={5}
          bounds={bounds}
          scrollWheelZoom={true}
          //^ React .leaflet-container with condition!
          style={{
            width: "100%",
            height: isMobile
              ? "calc(100vh - var(--constantFooterHeight) - var(--constantHeaderHeight) - 50px)"
              : "calc(100vh - var(--constantFooterHeight) - var(--constantHeaderHeight))",
          }}
          //* Disable below, but <ZoomControl position="topright" /> enable
          zoomControl={false}
          doubleClickZoom={false}
          className={grayMapFromRedux ? "leaflet-grayscale" : ""}
          rotate={true}
          touchRotate={false}
          rotateControl={true}
        >
          <TileLayer
            maxNativeZoom={18}
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          {/* //* Scale Control */}
          <ScaleControl position="bottomleft" />
          {/* //^ Custom ZoomControl */}
          {/* <ZoomControl position="topright" /> */}

          {/* //* Custom Control */}
          <Control prepend={false} position="topright">
            <CustomDivControl>
              <ButtonGroup vertical={true}>
                <OverlayTrigger
                  placement={"left"}
                  trigger={["hover", "focus"]}
                  overlay={<BootstrapTooltip id={"zoom-in-tooltip"}>Zoom In</BootstrapTooltip>}
                >
                  <Button onClick={mapZoomIn} className="location-button" variant="secondary">
                    <img src={zoomInIcon} alt="Zoom In Icon" width="48" height="48" />
                  </Button>
                </OverlayTrigger>

                <HR />

                <OverlayTrigger
                  placement={"left"}
                  trigger={["hover", "focus"]}
                  overlay={<BootstrapTooltip id={"zoom-out-tooltip"}>Zoom Out</BootstrapTooltip>}
                >
                  <Button onClick={mapZoomOut} className="location-button" variant="secondary">
                    <img src={zoomOutIcon} alt="Zoom Out Icon" width="48" height="48" />
                  </Button>
                </OverlayTrigger>

                <HR />

                <OverlayTrigger
                  placement={"left"}
                  trigger={["hover", "focus"]}
                  overlay={<BootstrapTooltip id={"showInfoModalTooltip"}>More Information</BootstrapTooltip>}
                >
                  <Button
                    variant="secondary"
                    className="location-button"
                    onClick={() => dispatch(showInformationModal(true))}
                  >
                    <img src={infoIcon} alt="Zoom Out Icon" width="48" height="48" />
                  </Button>
                </OverlayTrigger>

                <HR />

                <OverlayTrigger
                  placement={"left"}
                  trigger={["hover", "focus"]}
                  overlay={<BootstrapTooltip id={"locateMeTooltip"}>Locate Me</BootstrapTooltip>}
                >
                  <Button
                    variant="outline-secondary"
                    className="location-button"
                    onClick={locateMe}
                    onMouseEnter={() => setLocateIconCurrentColor(appBackgroundColorWhiteSmoke)}
                    onMouseLeave={() => setLocateIconCurrentColor(locateIconInitialColor)}
                  >
                    <MapLocationIcon currentColor={locateIconCurrentColor} />
                  </Button>
                </OverlayTrigger>
              </ButtonGroup>
            </CustomDivControl>
          </Control>

          {/* //* myLocation: Circle + Icon -> below: little circle */}
          {myLocation && <CircleMarker center={myLocation?.position} radius={5} color={orangeColor} fillOpacity={1} />}

          {/* //* currentBusId Icon on the map */}
          {currentBus && (
            <MapVehicleComponent
              currentBusData={currentBus}
              routeInfo={routeInfo!}
              showBusTooltip={showTooltipFromRedux}
              mapAutorotationFromRedux={mapAutorotationFromRedux}
            />
          )}

          {/* //* currentBusId Route details */}
          <MapRouteDetails />
          {/* //* currentBusId Trip info */}
          {currentBus && <MapBusTripInfo currentBusData={currentBus} />}
        </MapContainer>
      ) : (
        <AppSpinner />
      )}
    </React.Fragment>
  );
};

export default MapComponent;
