import React from "react";
import { Button, FloatingLabel, Form, InputGroup } from "react-bootstrap";
import styled from "styled-components";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

import { appName, logo } from "../config";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { dispatchChosenAgency, dispatchLoginState, getCredentials, getLoginData, setDriverPIN } from "../redux/actions";
import { selectCredentials, selectDriverData } from "../redux/selectors";
import variableColors from "../_App.module.scss";
import { agenciesArray } from "../data/constants";

const { primaryColor, dangerColor } = variableColors;

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100vh;
  margin: 0 auto;
  form {
    button {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      gap: 0.25rem;
      img {
        margin-right: 0.5rem;
        width: 3rem;
        height: 3rem;
      }
    }
  }
`;

const LogoDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

const Img = styled.img`
  background-color: "transparent";
  width: auto;
  height: 68px;
`;

const H1 = styled.h1`
  margin-left: 10px;
  text-decoration: none;
`;

const LoginComponent = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch: Dispatch = useAppDispatch();
  const credentialsFromRedux: boolean = useAppSelector<boolean>(selectCredentials);
  const driverDataFromRedux: DriverData = useAppSelector<DriverData>(selectDriverData);
  // console.log("driverDataFromRedux:", driverDataFromRedux);

  const [agencyName, setAgencyName] = React.useState<string>("");
  const [pinNumber, setPinNumber] = React.useState<string>(
    process.env.NODE_ENV === "development" ? (process.env.REACT_APP_TA_Driver_PIN as string) : ""
  );
  const [showPass, setShowPass] = React.useState<boolean>(false);
  const [validated, setValidated] = React.useState<boolean>(false);
  const [incorrectPin, setIncorrectPin] = React.useState<boolean>(false);

  //* Init: Get Credentials (token) onLoad
  React.useEffect(() => {
    dispatch(getCredentials());
  }, [dispatch]);

  //@ Init: Dispatch Agency Data
  React.useEffect(() => {
    if (agencyName) {
      const agencyObj = agenciesArray.filter((agency) => agency.agencyName === agencyName)[0];
      // console.log("agencyObj:", agencyObj);
      dispatch(dispatchChosenAgency(agencyObj));
    }
  }, [agencyName, dispatch]);

  React.useEffect(() => {
    if (driverDataFromRedux) {
      // console.log("driverDataFromRedux:", driverDataFromRedux, typeof driverDataFromRedux);
      if (typeof driverDataFromRedux === "object" && Object.keys(driverDataFromRedux).length && driverDataFromRedux.token) {
        setValidated(true);
        setIncorrectPin(false);
        setTimeout(() => {
          (async function doLogin() {
            await dispatch(setDriverPIN(Number(pinNumber)));
            await dispatch(dispatchLoginState(true));
          })();
        }, 500);
      }
      if (typeof driverDataFromRedux === "string") {
        setValidated(false);
        setIncorrectPin(true);
      }
    }
  }, [dispatch, driverDataFromRedux, pinNumber]);

  //* Set loginState(true) + navigation to Home component
  const onButtonSubmit = async (event: React.FormEvent) => {
    await event.preventDefault();
    await dispatch(getLoginData(Number(pinNumber)));
    setTimeout(() => {
      navigate("/");
    }, 100);
  };

  return (
    <React.Fragment>
      <LoginContainer>
        <LogoDiv>
          <Img src={logo} alt="logo" />
          <H1>Schedule</H1>
        </LogoDiv>
        <Form className="mb-3 mt-3" onSubmit={onButtonSubmit} validated={validated}>
          <Form.Group>
            <Form.Text className="text-muted">Select the agency you work for</Form.Text>
            <FloatingLabel label={agencyName ? "Selected" : "Not selected"} controlId="formAgencyLogin">
              <Form.Select aria-label="Select an Agency" onChange={(event) => setAgencyName(event.target.value)}>
                {!agencyName ? <option>{`Select an Agency (${agenciesArray.length})`}</option> : null}
                {agenciesArray.map((agency: Agency, index: number) => {
                  return (
                    <option value={agency?.agencyName} key={index + agency?.agencyId} disabled={false}>
                      {agency?.agencyName}
                    </option>
                  );
                })}
              </Form.Select>
            </FloatingLabel>
          </Form.Group>

          <Form.Group>
            <Form.Text className="text-muted">Enter your PIN number to login to the app.</Form.Text>
            <InputGroup>
              <FloatingLabel label="Enter pin" controlId="formPinLogin">
                <Form.Control
                  type={showPass ? "tel" : "password"}
                  placeholder="Enter pin"
                  value={pinNumber}
                  onChange={(event) => setPinNumber(event.target.value)}
                  required={true}
                />
              </FloatingLabel>
              <InputGroup.Text id="show-hide-pass" onClick={() => setShowPass(!showPass)}>
                {showPass ? (
                  <BsFillEyeSlashFill style={{ color: dangerColor, width: 20, height: "auto", cursor: "pointer" }} />
                ) : (
                  <BsFillEyeFill style={{ color: primaryColor, width: 20, height: "auto", cursor: "pointer" }} />
                )}
              </InputGroup.Text>
            </InputGroup>

            {incorrectPin ? (
              <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
                Invalid PIN. Please enter a valid PIN number.
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Button
            className="mb-3 mt-3"
            variant={"primary"}
            size="lg"
            type="submit"
            disabled={agencyName && credentialsFromRedux && pinNumber && pinNumber.length >= 6 ? false : true}
          >
            <img src={window.location.origin + "/bus.svg"} alt="Bus primaryColor icon" />
            Login to <span className="span_bold">{appName}</span>
          </Button>
        </Form>
      </LoginContainer>
    </React.Fragment>
  );
};

export default LoginComponent;
