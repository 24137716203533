import React from "react";
import styled from "styled-components";
import { Button, ButtonGroup } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { AiFillHome } from "react-icons/ai";
import { BiSolidTimeFive } from "react-icons/bi";
import { FaMapMarked } from "react-icons/fa";
import { IoMailSharp, IoSettingsSharp } from "react-icons/io5";

import { useAppSelector } from "../redux/hooks";
import { selectCurrentBusData, selectRouteInfo } from "../redux/selectors";
import directionIcon from "../assets/icons/directionIcon.svg";
import pauseIcon from "../assets/icons/pauseIcon.svg";
import variableColors from "../_App.module.scss";

const { appBackgroundColor } = variableColors;

//^ FooterInfoContainerWrapper
const FooterInfoContainerWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: var(--constantFooterHeight);
`;

//* FooterInfoContainer
const FooterInfoContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  background: ${appBackgroundColor};
  max-width: 500px;
  height: var(--constantFooterHeight);
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    "info"
    "buttons";
  .item-buttons {
    grid-area: buttons;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    div {
      button {
        a {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          align-content: center;
          text-decoration: none;
          font-weight: bold;
          color: inherit;
        }
      }
    }
  }
  .item-info {
    /* //* Margins: */
    margin: 0.5rem 0.5rem auto 0.5rem;
    grid-area: info;
    font-size: large;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-content: center;
    gap: 0.25rem;
    span {
      font-weight: bold;
      font-size: larger;
      vertical-align: middle;
      text-align: center;
      padding: 2px 6px;
    }
  }
`;

const FooterInfoComponent = (): JSX.Element => {
  const currentBusDataFromRedux: BusInfo = useAppSelector<BusInfo>(selectCurrentBusData);
  const currentRouteDetails: RouteDetails = useAppSelector<RouteDetails>(selectRouteInfo);

  const [currentBusData, setCurrentBusData] = React.useState<BusInfo | null>(null);
  const [routeInfo, setRouteInfo] = React.useState<RouteInfo | null>(null);
  // console.log("routeInfo:", routeInfo);

  //* Set route info
  React.useEffect(() => {
    if (currentRouteDetails) {
      setRouteInfo(currentRouteDetails?.routeInfo);
    }
  }, [currentRouteDetails]);

  //* Set currentBusDataFromRedux
  React.useEffect(() => {
    if (currentBusDataFromRedux && Object.keys(currentBusDataFromRedux).length) {
      setCurrentBusData(currentBusDataFromRedux);
    }
  }, [currentBusDataFromRedux]);

  return (
    <React.Fragment>
      <FooterInfoContainerWrapper>
        <FooterInfoContainer>
          <div className="item-info">
            <div>
              Route:{" "}
              {routeInfo ? (
                <span
                  style={{
                    color: routeInfo?.textColor,
                    backgroundColor: routeInfo?.color,
                    minWidth: "40px",
                  }}
                >
                  {currentBusData!.routeShortName || currentBusData!.routeId || "n/a"}
                </span>
              ) : (
                <span>n/a</span>
              )}{" "}
              {currentBusData?.loc?.heading || currentBusData?.loc?.heading === 0 ? (
                <img
                  width={32}
                  height={32}
                  src={currentBusData?.isAtStop === true ? pauseIcon : directionIcon}
                  alt="Pause/Direction Icon"
                  style={{
                    transform:
                      currentBusData?.isAtStop === false && currentBusData?.loc?.heading
                        ? `rotate(${currentBusData.loc.heading}deg)`
                        : "",
                  }}
                />
              ) : (
                <span>n/a</span>
              )}
            </div>
            <div>
              Status: <span style={{ background: currentBusData?.colorStatus }}>{currentBusData?.translatedString}</span>
            </div>
          </div>

          <div className="item-buttons">
            <ButtonGroup className="w-100">
              <Button variant="dark" size="sm">
                <NavLink to="/">
                  <AiFillHome size="25px" />
                  Home
                </NavLink>
              </Button>

              <Button variant="dark" size="sm">
                <NavLink to={"/passenger_count"}>
                  <IoMailSharp size="25px" />
                  Pax Count
                </NavLink>
              </Button>

              <Button variant="dark" size="sm">
                <NavLink to={"/timepoints"}>
                  <BiSolidTimeFive size="25px" />
                  Timepoints
                </NavLink>
              </Button>

              <Button variant="dark" size="sm">
                <NavLink to={"/map"}>
                  <FaMapMarked size="25px" />
                  Map
                </NavLink>
              </Button>

              <Button variant="dark" size="sm">
                <NavLink to={"/settings"}>
                  <IoSettingsSharp size="25px" />
                  Settings
                </NavLink>
              </Button>
            </ButtonGroup>
          </div>
        </FooterInfoContainer>
      </FooterInfoContainerWrapper>
    </React.Fragment>
  );
};

export default FooterInfoComponent;
