import React from "react";

const MapBusIcon = ({ iconColor, strokeColor }: { iconColor: string; strokeColor: string }): JSX.Element => {
  // console.log({ iconColor, strokeColor });
  return (
    <React.Fragment>
      <svg
        id="Icons"
        version="1.1"
        viewBox="0 0 32 32"
        xmlSpace="preserve"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
          stroke={strokeColor}
          fill={iconColor}
          d="M29.9,28.6l-13-26c-0.3-0.7-1.4-0.7-1.8,0l-13,26c-0.2,0.4-0.1,0.8,0.2,1.1C2.5,30,3,30.1,3.4,29.9L16,25.1l12.6,4.9  c0.1,0,0.2,0.1,0.4,0.1c0.3,0,0.5-0.1,0.7-0.3C30,29.4,30.1,28.9,29.9,28.6z"
        />
      </svg>
    </React.Fragment>
  );
};
export default MapBusIcon;
