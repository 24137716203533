import React from "react";
import styled from "styled-components";
import { Col, Container, Form, Row } from "react-bootstrap";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { selectDriverData, selectDriverPinNumber, selectScheduleDriverToday } from "../../redux/selectors";
import variableColors from "../../_App.module.scss";
import { getScheduleDriverForToday } from "../../redux/actions";

const { textColor, dangerColor, appBackgroundColor } = variableColors;

//* Dry principle - code repetition
export const CommonTopInfoComponentContainer = styled.div`
  background-color: inherit;
  & > form.top-bar-info {
    fieldset {
      div.container-fluid {
        div.row {
          div.col {
            padding-left: 0.25rem;
            padding-right: 0.25rem;
            &:first-child {
              padding-left: 0;
            }
            &:last-child {
              padding-right: 0;
            }
            div.mb-3 {
              margin-bottom: initial;
            }
          }
        }
      }
      div.mb-3,
      div.mb-3.input-group {
        label.form-label {
          color: ${textColor};
          font-size: 90%;
          margin-bottom: 0.25rem;
          padding-left: 2px;
          width: 100%;
        }
        input {
          color: initial;
          font-size: 95%;
          padding-left: 2px;
          padding-right: 2px;
          font-weight: bold;
          border-top: 1px solid ${dangerColor};
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          background-color: ${appBackgroundColor};
        }
      }
    }
  }
`;

const CommonTopInfoComponent = (): JSX.Element => {
  const dispatch: Dispatch = useAppDispatch();

  const driverDataFromRedux: DriverData = useAppSelector<DriverData>(selectDriverData);
  const scheduleDriverTodayFromRedux: RootState = useAppSelector<RootState>(selectScheduleDriverToday);
  const driverPIN = useAppSelector<number>(selectDriverPinNumber);

  const [driverData, setDriverData] = React.useState<DriverData | null>(null);
  const [scheduleDriverToday, setScheduleDriverToday] = React.useState<ScheduleDriverToday | null>(null);
  // console.log("driverData:", driverData);

  //* Driver's Info
  React.useEffect(() => {
    if (driverDataFromRedux && Object.keys(driverDataFromRedux)) {
      setDriverData(driverDataFromRedux);
    }
  }, [driverDataFromRedux]);

  // If !scheduleDriverTodayFromRedux -> get: https://apibeta.taschedule.com/api/v1/movil/getScheduleTodayDriver
  React.useEffect(() => {
    if (driverPIN && driverDataFromRedux && !scheduleDriverTodayFromRedux) {
      const driverToken = driverDataFromRedux?.token as string;
      dispatch(getScheduleDriverForToday(driverPIN, driverToken));
    }
  }, [dispatch, driverDataFromRedux, driverPIN, scheduleDriverTodayFromRedux]);

  React.useEffect(() => {
    if (driverData && scheduleDriverTodayFromRedux) {
      // console.log("scheduleDriverTodayFromRedux:", scheduleDriverTodayFromRedux);
      const vehicleName = scheduleDriverTodayFromRedux?.vehicle?.description || "n/a";
      const currentRoute = scheduleDriverTodayFromRedux?.route?.description || "n/a";
      const currentBlock =
        `(${Number(scheduleDriverTodayFromRedux?.routeblock?.id)}) ${
          scheduleDriverTodayFromRedux?.routeblock?.description
        }` || "n/a";

      setScheduleDriverToday({
        //* Required by interface ScheduleDriverToday
        currentSchedule: {
          reportTime: undefined,
          leaveYard: undefined,
          firstStop: undefined,
          lastStop: undefined,
          checkOut: undefined,
        },
        vehicleName,
        currentRoute,
        currentBlock,
      });
    }
  }, [driverData, scheduleDriverTodayFromRedux]);

  const RouteInformationForm = ({ scheduleDriverToday }: { scheduleDriverToday: ScheduleDriverToday }): JSX.Element => {
    const { vehicleName, currentRoute, currentBlock } = scheduleDriverToday;

    return (
      <Form className="top-bar-info">
        <fieldset disabled>
          <Container fluid={true}>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="top-info-current_route">Route</Form.Label>
                  <Form.Control
                    id="top-info-current_route"
                    placeholder="n/a"
                    defaultValue={currentRoute || "n/a"}
                    readOnly={true}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="top-info-current_block">Block</Form.Label>
                  <Form.Control
                    id="top-info-current_block"
                    placeholder="n/a"
                    defaultValue={currentBlock || "n/a"}
                    readOnly={true}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="top-info-vehicle_name">Vehicle</Form.Label>
                  <Form.Control
                    id="top-info-vehicle_name"
                    placeholder="n/a"
                    defaultValue={vehicleName || "n/a"}
                    readOnly={true}
                  />
                </Form.Group>
              </Col>
            </Row>
            <br />
          </Container>
        </fieldset>
      </Form>
    );
  };

  return (
    <React.Fragment>
      <CommonTopInfoComponentContainer>
        {scheduleDriverToday ? (
          <RouteInformationForm scheduleDriverToday={scheduleDriverToday} />
        ) : (
          <h5 className="text-center mt-2">No route information available</h5>
        )}
      </CommonTopInfoComponentContainer>
    </React.Fragment>
  );
};

export default CommonTopInfoComponent;
