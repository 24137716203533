import React from "react";
import styled from "styled-components";

import useNetworkStatus from "../services/useNetworkStatus";
import variableColors from "../_App.module.scss";
import HomeIcon from "../components/HomeIcon";
import TimePointsCurrentTime from "../components/TimePointsCurrentTime";

const { textColor, dangerColor, successColor, primaryColor } = variableColors;

const TopInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  width: 100%;
  color: ${textColor};
  div {
    gap: 0.4rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    span {
      margin-left: 0;
    }
    &:nth-child(2) {
      span {
        margin-left: -2px;
      }
    }
  }
`;

const TopInfoComponent = ({ fontSize }: { fontSize: string }): JSX.Element => {
  const { isOnline, isMobile } = useNetworkStatus();
  return (
    <React.Fragment>
      <TopInfoContainer>
        <div>
          <div>
            {isOnline ? (
              <React.Fragment>
                <HomeIcon name="onlineIcon" color={successColor} size={17} /> <span className="span_success">Online</span>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <HomeIcon name="offlineIcon" color={dangerColor} size={17} /> <span className="span_danger">Offline</span>
              </React.Fragment>
            )}
          </div>
          <div>
            {isMobile ? (
              <React.Fragment>
                <HomeIcon name="mobileIcon" color={primaryColor} size={17} /> <span className="span_primary">Mobile</span>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <HomeIcon name="desktopIcon" color={primaryColor} size={17} /> <span className="span_primary">Desktop</span>
              </React.Fragment>
            )}
          </div>
        </div>
        <TimePointsCurrentTime fontSize={fontSize} />
      </TopInfoContainer>
    </React.Fragment>
  );
};

export default TopInfoComponent;
