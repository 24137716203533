import { combineReducers } from "redux";

import {
  DISPATCH_BUS_ID,
  DISPATCH_CHECK_IN_TIME,
  DISPATCH_CHOSEN_AGENCY,
  DISPATCH_CURRENT_ZOOM,
  DISPATCH_DRIVER_MANIFEST_MOVIL_ID,
  DISPATCH_LOGIN_STATUS,
  DISPATCH_TRIP_STATUS,
  DISPATCH_TRIP_STATUS_PROGRESS,
  GET_AGENCY_INFO,
  GET_BUSES,
  GET_CREDENTIALS,
  GET_CURRENT_BUS_DATA,
  GET_INSPECTION_CATEGORIES,
  GET_LOGIN_DATA,
  GET_ROUTES_DRIVER,
  GET_ROUTE_DETAILS,
  GET_SCHEDULE_DETAILS_TODAY,
  GET_SCHEDULE_DRIVER_TODAY,
  GET_TRIP_INFO,
  POST_PRE_INSPECTION_DATA,
  RESET_STATE,
  SET_DRIVER_PIN,
  SET_GRAY_MAP,
  SET_MAP_AUTOROTATION,
  SET_SHOW_TOOLTIP,
  SHOW_CHANGE_BUS_MODAL,
  SHOW_INFO_MODAL,
} from "./actionTypes";
import { getTimeZoneTime } from "../utils/helpers";

const initialState: RootState = {};

// Reducers
const agencyInfo = function (
  state = {
    ...initialState,
    loginStatus: false,
    grayMap: false,
    showTooltip: false,
    showInitialModal: false,
    isMapAutoRotated: false,
  },
  action: Dispatch
) {
  // console.log("action.payload:", action.payload);
  switch (action.type) {
    case DISPATCH_CHOSEN_AGENCY:
      return { ...state, ...action.payload };
    case GET_AGENCY_INFO:
      return { ...state, ...action.payload };
    case DISPATCH_CURRENT_ZOOM:
      return { ...state, ...action.payload };
    case SHOW_INFO_MODAL:
      return { ...state, ...action.payload };
    case DISPATCH_LOGIN_STATUS:
      return { ...state, ...action.payload };
    case GET_CREDENTIALS:
      return { ...state, ...action.payload };
    case GET_LOGIN_DATA:
      return { ...state, ...action.payload };
    case SHOW_CHANGE_BUS_MODAL:
      return { ...state, ...action.payload };
    case SET_GRAY_MAP:
      return { ...state, ...action.payload };
    case SET_SHOW_TOOLTIP:
      return { ...state, ...action.payload };
    case GET_ROUTES_DRIVER:
      return { ...state, ...action.payload };
    case GET_SCHEDULE_DETAILS_TODAY:
      return { ...state, ...action.payload };
    case GET_SCHEDULE_DRIVER_TODAY:
      return { ...state, ...action.payload };
    case SET_DRIVER_PIN:
      return { ...state, ...action.payload };
    case SET_MAP_AUTOROTATION:
      return { ...state, ...action.payload };

    case RESET_STATE:
      return { ...initialState, loginStatus: false };

    default:
      return state;
  }
};

const busesInfo = function (state = initialState, action: Dispatch) {
  // console.log("action.payload:", action.payload);
  switch (action.type) {
    case GET_BUSES:
      return { ...state, ...action.payload };
    case RESET_STATE:
      return initialState;

    default:
      return state;
  }
};

const currentBusInfo = function (state = { ...initialState, checkInTime: getTimeZoneTime() }, action: Dispatch) {
  switch (action.type) {
    case DISPATCH_BUS_ID:
      return { ...state, ...action.payload };
    case GET_CURRENT_BUS_DATA:
      return { ...state, ...action.payload };
    case GET_INSPECTION_CATEGORIES:
      return { ...state, ...action.payload };
    case POST_PRE_INSPECTION_DATA:
      return { ...state, ...action.payload };
    case DISPATCH_CHECK_IN_TIME:
      return { ...state, ...action.payload };
    case DISPATCH_DRIVER_MANIFEST_MOVIL_ID:
      return { ...state, ...action.payload };
    case RESET_STATE:
      return initialState;

    default:
      return state;
  }
};

const currentRouteTripDetails = function (state = initialState, action: Dispatch) {
  // console.log("action.payload:", action.payload);
  switch (action.type) {
    case GET_ROUTE_DETAILS:
      return { ...state, ...action.payload };
    case GET_TRIP_INFO:
      return { ...state, ...action.payload };
    case DISPATCH_TRIP_STATUS:
      return { ...state, ...action.payload };
    case DISPATCH_TRIP_STATUS_PROGRESS:
      return { ...state, ...action.payload };
    case RESET_STATE:
      return initialState;

    default:
      return state;
  }
};

// CombineReducer
const rootReducer = combineReducers({
  agencyInfo: agencyInfo,
  busesInfo: busesInfo,
  currentBusInfo: currentBusInfo,
  currentRouteTripDetails: currentRouteTripDetails,
});

export default rootReducer;
