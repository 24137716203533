import axios from "axios";

import {
  apiPrefixTaSchedule,
  axiosHeaders,
  credentialsData,
  defaultLanguage,
  delayToleration,
  tooFastToleration,
} from "../config";
import {
  DISPATCH_BUS_ID,
  DISPATCH_CHECK_IN_TIME,
  DISPATCH_CHOSEN_AGENCY,
  DISPATCH_CURRENT_ZOOM,
  DISPATCH_DRIVER_MANIFEST_MOVIL_ID,
  DISPATCH_LOGIN_STATUS,
  DISPATCH_TRIP_STATUS,
  DISPATCH_TRIP_STATUS_PROGRESS,
  GET_AGENCY_INFO,
  GET_BUSES,
  GET_CREDENTIALS,
  GET_CURRENT_BUS_DATA,
  GET_INSPECTION_CATEGORIES,
  GET_LOGIN_DATA,
  GET_ROUTES_DRIVER,
  GET_ROUTE_DETAILS,
  GET_SCHEDULE_DETAILS_TODAY,
  GET_SCHEDULE_DRIVER_TODAY,
  GET_TRIP_INFO,
  POST_PRE_INSPECTION_DATA,
  RESET_STATE,
  SET_DRIVER_PIN,
  SET_GRAY_MAP,
  SET_MAP_AUTOROTATION,
  SET_SHOW_TOOLTIP,
  SHOW_CHANGE_BUS_MODAL,
  SHOW_INFO_MODAL,
} from "./actionTypes";
import { dynamicTextColor, showColorStatus, translateString } from "../utils/helpers";
import store from "./store";

//* Initial dispatch
export const dispatchChosenAgency = (chosenAgency: Agency) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: DISPATCH_CHOSEN_AGENCY, payload: { chosenAgency } });
  } catch (error) {
    console.error("error:", error);
  }
};

//* First Set
export const getAgencyInfo = () => async (dispatch: Dispatch, getState: Fetch) => {
  const { agencyApiPrefix: apiPrefix, agencyId, apiKey } = await getState()?.agencyInfo?.chosenAgency;
  // console.log({ apiPrefix, agencyId, apiKey });

  await axios
    .get(`${apiPrefix}/v1/key/${apiKey}/agency/${agencyId}/command/agencyGroup`)
    .then(({ data }) => {
      // console.log("data:", data);
      const agencyInfo = {
        lang: data?.agency[0]?.lang,
        extent: data?.agency[0]?.extent,
      };
      // console.log("agencyInfo:", agencyInfo);
      dispatch({ type: GET_AGENCY_INFO, payload: agencyInfo });
    })
    .catch((error) => {
      if (error.response) {
        console.error("error.response.status:", error.response.status);
      }
      console.error("error.config:", error.config);
    });
};

export const getAllBusesList = () => async (dispatch: Dispatch, getState: Fetch) => {
  const { agencyApiPrefix: apiPrefix, agencyId, apiKey } = await getState()?.agencyInfo?.chosenAgency;

  //* Vehicles URL Long
  let vehiclesUrl: string = "";
  try {
    const URL = `${apiPrefix}/v1/key/${apiKey}/agency/${agencyId}/command/vehicleIds`;
    const res = await axios(URL);
    const ids = res?.data?.ids;
    const idsStrings = ids?.map((id: string) => `v=${id}&`);
    const stringOfIDs = idsStrings?.join("")?.slice(0, -1);
    vehiclesUrl = `${apiPrefix}/v1/key/${apiKey}/agency/${agencyId}/command/vehiclesDetails?numPreds=3&onlyAssigned=false&${stringOfIDs}`;
  } catch (error) {
    console.log("error:", error);
  }

  vehiclesUrl &&
    vehiclesUrl.length &&
    (await axios
      .get(vehiclesUrl)
      .then(({ data }) => {
        let busesArray = [] as BusArrayObject[];
        for (let i = 0; i < data?.vehicles.length; i++) {
          const busObject = {
            busId: data?.vehicles[i]?.id,
            vehicleName: data?.vehicles[i]?.vehicleName || "",
            value: data?.vehicles[i]?.id,
            label: data?.vehicles[i]?.vehicleName || data?.vehicles[i]?.id,
            active: data?.vehicles[i]?.schAdh || false,
          };
          busesArray.push(busObject);
        }

        for (let i = 0; i < busesArray.length; i++) {
          if (busesArray[i].active) {
            busesArray[i].label = `${busesArray[i].label} (A)`;
          }
        }

        //* Sort by vehicleName and budId
        busesArray = busesArray.sort((a, b) => a.vehicleName.localeCompare(b.vehicleName) || a.busId.localeCompare(b.busId));
        const busesArrayActive = busesArray.filter((elem) => elem.active !== false);
        const busesArrayNonActive = busesArray.filter((elem) => elem.active === false);

        dispatch({ type: GET_BUSES, payload: { busesArrayActive, busesArrayNonActive } });
      })
      .catch((error) => {
        if (error.response) {
          console.error("error.response.status:", error.response.status);
        }
      }));
};

export const dispatchCurrentBusId = (currentBusObject: BusArrayObject) => async (dispatch: Dispatch) => {
  try {
    // console.log({ currentBusObject });
    dispatch({ type: DISPATCH_BUS_ID, payload: { currentBusObject } });
  } catch (error) {
    console.error("error:", error);
  }
};

export const getCurrentBusInfo = (busId: string) => async (dispatch: Dispatch, getState: Fetch) => {
  const { agencyApiPrefix: apiPrefix, agencyId, apiKey } = await getState()?.agencyInfo?.chosenAgency;

  await axios
    .get(`${apiPrefix}/v1/key/${apiKey}/agency/${agencyId}/command/vehiclesDetails?v=${busId}&onlyAssigned=false`)
    .then(({ data }) => {
      // console.log("data:", data);
      const currentBusIdData = data?.vehicles[0] as BusInfo;

      // Additional Info
      currentBusIdData.addedStatus =
        currentBusIdData.schAdh >= delayToleration && currentBusIdData.schAdh <= tooFastToleration
          ? "busOnTime"
          : currentBusIdData.schAdh > tooFastToleration
          ? "busToFast"
          : currentBusIdData.schAdh < delayToleration
          ? "delayedBus"
          : "no-data";
      // Additional Info 2
      currentBusIdData.colorStatus = showColorStatus(currentBusIdData.schAdh)?.status || "";
      currentBusIdData.iconColor = showColorStatus(currentBusIdData.schAdh)?.iconColor || "";
      currentBusIdData.strokeColor = showColorStatus(currentBusIdData.schAdh)?.strokeColor || "";
      // translatedString
      currentBusIdData.translatedString = translateString(currentBusIdData.schAdhStr, defaultLanguage) || "";
      // console.log("currentBusIdData:", currentBusIdData);

      // Additional Info3 - Circle Component
      currentBusIdData.addedStatus2 =
        currentBusIdData.schAdh >= delayToleration && currentBusIdData.schAdh <= tooFastToleration
          ? "On time"
          : currentBusIdData.schAdh > tooFastToleration
          ? "To fast"
          : currentBusIdData.schAdh < delayToleration
          ? "Delayed"
          : "No-data";

      dispatch({ type: GET_CURRENT_BUS_DATA, payload: { currentBusIdData } });
    })
    .catch((error) => {
      if (error.response) {
        console.error("error.response.status:", error.response.status);
      }
    });
};

export const dispatchCurrentZoom = (currentZoom: number) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: DISPATCH_CURRENT_ZOOM, payload: { currentZoom } });
  } catch (error) {
    console.error({ error });
  }
};

export const getRouteDetails = (routeId: string, direction: string) => async (dispatch: Dispatch, getState: Fetch) => {
  const { agencyApiPrefix: apiPrefix, agencyId, apiKey } = await getState()?.agencyInfo?.chosenAgency;

  // console.log({ routeId, direction, agencyId });
  await axios
    .get(`${apiPrefix}/v1/key/${apiKey}/agency/${agencyId}/command/routesDetails?r=${routeId}`)
    .then(({ data }) => {
      // console.log("data:", data);
      let routeInfo = {
        id: data?.routes[0]?.id || "",
        name: data?.routes[0]?.name || "",
        shortName: data?.routes[0]?.shortName || "",
        longName: data?.routes[0]?.longName || "",
        color: `#${data?.routes[0]?.color}`,
        textColor: data?.routes[0]?.textColor ? `#${data?.routes[0]?.textColor}` : "#ffffff",
      };
      routeInfo = { ...routeInfo, textColor: dynamicTextColor(routeInfo.color) };
      // console.log("routeInfo:", routeInfo);

      const lineStops = data?.routes[0]?.direction;
      const lineShape = data?.routes[0]?.shape;
      const selectedDirection = direction;
      const routeDetails = {
        lineStops,
        lineShape,
        selectedDirection,
        routeInfo,
      };
      dispatch({ type: GET_ROUTE_DETAILS, payload: { routeDetails } });
    })
    .catch((error) => {
      if (error.response) {
        console.error("error.response.status:", error.response.status);
      }
    });
};

export const showInformationModal = (showInformationModal: boolean) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: SHOW_INFO_MODAL, payload: { showInformationModal } });
  } catch (error) {
    console.error({ error });
  }
};

export const getTripInfo = (currentTrip: string) => async (dispatch: Dispatch, getState: Fetch) => {
  const { agencyApiPrefix: apiPrefix, agencyId, apiKey } = await getState()?.agencyInfo?.chosenAgency;

  // console.log({ currentTrip });
  await axios
    .get(
      `${apiPrefix}/v1/key/${apiKey}/agency/${agencyId}/command/tripWithTravelTimes?tripId=${encodeURIComponent(
        currentTrip
      )}`
    )
    .then(({ data }) => {
      // console.log("data:", data);
      dispatch({ type: GET_TRIP_INFO, payload: { tripInfo: data } });
    })
    .catch((error) => {
      if (error.response) {
        console.error("error.response.status:", error.response.status);
      }
    });
};

export const dispatchTripStatus = (calculatedTripStatus: CalculatedTripInfo) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: DISPATCH_TRIP_STATUS, payload: { calculatedTripStatus } });
  } catch (error) {
    console.error({ error });
  }
};

export const dispatchTripStatusProgress = (tripProgressBarData: BusStop[]) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: DISPATCH_TRIP_STATUS_PROGRESS, payload: { tripProgressBarData } });
  } catch (error) {
    console.error({ error });
  }
};

//* New Actions
export const dispatchLoginState = (loginStatus: boolean) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: DISPATCH_LOGIN_STATUS, payload: { loginStatus } });
  } catch (error) {
    console.error({ error });
  }
};

export const getCredentials = () => async (dispatch: Dispatch) => {
  const URL = `${apiPrefixTaSchedule}/oauth/token`;

  axios
    .post(URL, credentialsData, { headers: axiosHeaders })
    .then(({ data }) => {
      // console.log({ data });
      dispatch({ type: GET_CREDENTIALS, payload: { credentials: data } });
    })
    .catch((error) => {
      if (error.response) {
        console.error("error.response.status:", error.response.status);
      }
      if (error.message) {
        console.error("error.message:", error.message);
      }
    });
};

export const getLoginData = (pinNumber: number) => async (dispatch: Dispatch) => {
  const URL = `${apiPrefixTaSchedule}/api/v1/movil/login`;
  if (store) {
    const token = store?.getState()?.agencyInfo?.credentials?.access_token;
    // console.log({ token });

    const axiosConfig = {
      headers: { ...axiosHeaders, Authorization: `Bearer ${token}` },
    };
    // console.log({ axiosConfig });

    axios
      .post(URL, { pin: pinNumber }, axiosConfig)
      .then(({ data }) => {
        // console.log({ data });
        if (data?.status === "success") {
          const driverData = data?.data;
          dispatch({ type: GET_LOGIN_DATA, payload: { driverData } });
        }
      })
      .catch((error) => {
        if (error) {
        }
        if (error.response) {
          console.error("error.response.status:", error.response.status);
        }
        if (error.message) {
          console.error("error.message:", error.message);
          dispatch({ type: GET_LOGIN_DATA, payload: { driverData: error.message } });
        }
      });
  }
};

export const showInitialModal = (showInitialModal: boolean) => async (dispatch: Dispatch) => {
  // console.log({ showInitialModal });
  try {
    dispatch({ type: SHOW_CHANGE_BUS_MODAL, payload: { showInitialModal } });
  } catch (error) {
    console.error({ error });
  }
};

export const dispatchGrayMap = (grayMap: boolean) => async (dispatch: Dispatch) => {
  // console.log({ grayMap });
  try {
    dispatch({ type: SET_GRAY_MAP, payload: { grayMap } });
  } catch (error) {
    console.error({ error });
  }
};

export const dispatchShowTooltip = (showTooltip: boolean) => async (dispatch: Dispatch) => {
  // console.log({ showTooltip });
  try {
    dispatch({ type: SET_SHOW_TOOLTIP, payload: { showTooltip } });
  } catch (error) {
    console.error({ error });
  }
};

export const setMapAutorotation = (isMapAutoRotated: boolean) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: SET_MAP_AUTOROTATION, payload: { isMapAutoRotated } });
  } catch (error) {
    console.error({ error });
  }
};

export const getRoutesDriver = (driverId: string, driverToken: string) => async (dispatch: Dispatch) => {
  const URL = `${apiPrefixTaSchedule}/api/v1/schedulesDetails/byDriverToday/${driverId}`;

  const axiosConfig = {
    headers: { ...axiosHeaders, Authorization: `Bearer ${driverToken}` },
  };
  // console.log({ driverId, driverToken });

  axios
    .get(URL, axiosConfig)
    .then((res) => {
      // console.log({res});
      if (res?.status === 200) {
        const driverRoutes = res?.data;
        // console.log("driverRoutes:", driverRoutes);
        dispatch({ type: GET_ROUTES_DRIVER, payload: { driverRoutes } });
      }
    })
    .catch((error) => {
      if (error.response) {
        console.error("error.response.status:", error.response.status);
      }
      if (error.message) {
        console.error("error.message:", error.message);
      }
    });
};

export const getScheduleDetailsForToday = (todayDate: string, agencyId: string) => async (dispatch: Dispatch) => {
  const URL = `${apiPrefixTaSchedule}/api/v1/scheduleDetails/byDate/${todayDate}`;
  if (store) {
    const token = store?.getState()?.agencyInfo?.credentials?.access_token;

    const axiosConfig = {
      headers: {
        ...axiosHeaders,
        Authorization: `Bearer ${token}`,
        namedb: agencyId === ("33311" as string) ? "TA_SCHEDULE_FORTLAUDERDALE" : "TA_SCHEDULE", //* Fort Lauderdale or other carriers!
      },
    };
    // console.log("axiosConfig?.headers:", axiosConfig?.headers);

    axios
      .get(URL, axiosConfig)
      .then(({ data }) => {
        // console.log({ data });
        if (data?.status === "success") {
          const scheduleDetails = data?.data;
          dispatch({ type: GET_SCHEDULE_DETAILS_TODAY, payload: { scheduleDetails } });
        }
      })
      .catch((error) => {
        if (error.response) {
          console.error("error.response.status:", error.response.status);
        }
        if (error.message) {
          console.error("error.message:", error.message);
        }
      });
  }
};

export const getScheduleDriverForToday = (pinNumber: number, driverToken: string) => async (dispatch: Dispatch) => {
  const URL = `${apiPrefixTaSchedule}/api/v1/movil/getScheduleTodayDriver`;

  const axiosConfig = {
    headers: { ...axiosHeaders, Authorization: `Bearer ${driverToken}` },
  };

  axios
    .post(URL, { pin: pinNumber }, axiosConfig)
    .then(({ data }) => {
      // console.log({ data });
      if (data?.status === "success") {
        const scheduleDriverData = data?.data;
        dispatch({ type: GET_SCHEDULE_DRIVER_TODAY, payload: { scheduleDriverData } });
      }
    })
    .catch((error) => {
      if (error.response) {
        console.error("error.response.status:", error.response.status);
      }
      if (error.message) {
        console.error("error.message:", error.message);
      }
    });
};

export const setDriverPIN = (driverPIN_Number: number) => async (dispatch: Dispatch) => {
  // console.log({ driverPIN_Number });
  try {
    dispatch({ type: SET_DRIVER_PIN, payload: { driverPIN_Number } });
  } catch (error) {
    console.error({ error });
  }
};

export const getInspectionCategories = (category: number, driverToken: string) => async (dispatch: Dispatch) => {
  const URL = `${apiPrefixTaSchedule}/api/v1/VehicleInspectionCategories/type/${category}`;

  const axiosConfig = {
    headers: { ...axiosHeaders, Authorization: `Bearer ${driverToken}` },
  };

  axios
    .get(URL, axiosConfig)
    .then(({ data }) => {
      // console.log({ data });
      dispatch({ type: GET_INSPECTION_CATEGORIES, payload: { preTripInspectionData: data } });
    })
    .catch((error) => {
      if (error.response) {
        console.error("error.response.status:", error.response.status);
      }
      if (error.message) {
        console.error("error.message:", error.message);
      }
    });
};

export const postPreInspectionData = (dataToSend: Object, driverToken: string) => async (dispatch: Dispatch) => {
  // console.log("dataToSend:", dataToSend);

  const URL = `${apiPrefixTaSchedule}/api/v1/VehicleInspections`;
  const axiosConfig = {
    headers: { ...axiosHeaders, Authorization: `Bearer ${driverToken}` },
  };

  axios
    .post(URL, dataToSend, axiosConfig)
    .then(({ data }) => {
      // console.log("data:", data);
      if (data?.transaction === true) {
        const preInspectionResponseData = data?.master;
        // console.log("responseData:", responseData);
        dispatch({ type: POST_PRE_INSPECTION_DATA, payload: { preInspectionResponseData } });
      }
    })
    .catch((error) => {
      if (error.response) {
        console.error("error.response.status:", error.response.status);
      }
      if (error.message) {
        console.error("error.message:", error.message);
      }
    });
};

export const dispatchCheckInTime = (checkInTime: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: DISPATCH_CHECK_IN_TIME, payload: { checkInTime } });
  } catch (error) {
    console.error({ error });
  }
};

export const resetState = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: RESET_STATE });
  } catch (error) {
    console.error({ error });
  }
};

//* New Actions_2
export const dispatchDriverManifestMovilId = (driverManifestMovilId: number) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: DISPATCH_DRIVER_MANIFEST_MOVIL_ID, payload: { driverManifestMovilId } });
  } catch (error) {
    console.error({ error });
  }
};
