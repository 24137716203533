import React from "react";
import { Button, ButtonGroup, Modal } from "react-bootstrap";
import SignatureCanvas from "react-signature-canvas";
import styled from "styled-components";

import variableColors from "../../_App.module.scss";

const { primaryColor, textColor } = variableColors;

const CanvasDiv = styled.div`
  width: 100%;
  height: "auto";
`;

const CanvasESignature = ({
  isBusReadyToGo,
  imageData,
  setImageData,
}: {
  isBusReadyToGo: boolean;
  imageData: string | null;
  setImageData: any;
}): JSX.Element => {
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [isEmpty, setIsEmpty] = React.useState<boolean>(true);

  const sigCanvas = React.useRef<SignatureCanvas>(null);

  const clear = (): void => {
    sigCanvas.current?.clear();
    setIsEmpty(true);
  };

  const empty = (): void => {
    const isCanvasEmpty = sigCanvas.current?.isEmpty();
    if (isCanvasEmpty === false || isCanvasEmpty === true) {
      setIsEmpty(isCanvasEmpty);
    }
  };

  const save = (): void => {
    const url = sigCanvas.current?.getTrimmedCanvas().toDataURL("image/png");
    if (url) {
      setImageData(url);
      setTimeout(() => {
        setShowModal(false);
      }, 250);
    }
  };

  return (
    <React.Fragment>
      {imageData ? (
        <img
          src={imageData}
          alt="eSignature Canvas"
          style={{
            display: "block",
            margin: "0 auto 1rem auto",
            border: `1px dotted ${textColor}`,
            width: "auto",
            height: "150px",
          }}
        />
      ) : null}

      <Button
        variant={imageData ? "success" : "primary"}
        className="w-100"
        disabled={isBusReadyToGo ? false : true}
        onClick={() => setShowModal(true)}
      >
        {imageData ? "e-Signature is ready to send" : "Click Here To Use e-Signature on File"}
      </Button>

      <CanvasDiv>
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          fullscreen={"lg-down"}
          dialogClassName=""
          centered={true}
          backdrop={false}
          keyboard={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>eSignature Modal</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SignatureCanvas
              ref={sigCanvas}
              penColor={primaryColor}
              onEnd={empty}
              canvasProps={{
                className: "signatureCanvas",
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <ButtonGroup>
              <Button variant="primary" onClick={save} disabled={isEmpty ? true : false}>
                Save and Close
              </Button>
              <Button variant="warning" onClick={clear}>
                Clear
              </Button>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Close
              </Button>
            </ButtonGroup>
          </Modal.Footer>
        </Modal>
      </CanvasDiv>
    </React.Fragment>
  );
};

export default CanvasESignature;
