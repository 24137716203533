export const PRE_TRIP_INSPECTION = 1;

export const POST_TRIP_INSPECTION = 2;

export const agenciesArray = [
  {
    agencyName: "Miami-Dade Transit",
    agencyId: "33132",
    agencyApiPrefix: "https://tcmdt.tracktrolley.com/api",
    apiKey: "f78a2e9a",
  },
  { agencyName: "City of Doral", agencyId: "13", agencyApiPrefix: "https://tcdt.tracktrolley.com/api", apiKey: "f78a2e9a" },
  {
    agencyName: "City of Miami Beach",
    agencyId: "2362",
    agencyApiPrefix: "https://tccomb.tracktrolley.com/api",
    apiKey: "f78a2e9a",
  },
  {
    agencyName: "City of Fort Lauderdale",
    agencyId: "33311",
    agencyApiPrefix: "https://tcfll.tracktrolley.com/api",
    apiKey: "f78a2e9a",
  },
  {
    agencyName: "City of Sweetwater",
    agencyId: "33174",
    agencyApiPrefix: "https://tcsw.tracktrolley.com/api",
    apiKey: "f78a2e9a",
  },
];
