import React from "react";
import styled from "styled-components";
import { Card, Button, Image } from "react-bootstrap";
import { BsFillTelephoneFill } from "react-icons/bs";
import { BiSolidCity } from "react-icons/bi";
import { FaLocationDot, FaRegAddressCard, FaRoute } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

import { selectDriverData, selectDriverPinNumber, selectDriverRoutes } from "../redux/selectors";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { ComponentContainer } from "./Settings";
import HomeAvatar from "./HomeAvatar";
import variableColors from "../_App.module.scss";
import { getRoutesDriver } from "../redux/actions";
import routeIcon from "../assets/icons/routeIcon.svg";
import TopInfoComponent from "../layout/TopInfoComponent";
import useNetworkStatus from "../services/useNetworkStatus";

const { appBackgroundColor } = variableColors;

const HomeComponentContainer = styled(ComponentContainer)<{ $isMobile: boolean }>`
  background-color: inherit;
  div.main-info {
    margin-top: ${(props) => (props.$isMobile ? "0.75rem" : "1.5rem")};
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    gap: ${(props) => (props.$isMobile ? "0.75rem" : "1.5rem")};
    div.card-header {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      align-content: center;
      color: ${appBackgroundColor};
      svg {
        width: 20px;
        height: auto;
        margin-right: 0.5rem;
      }
    }
    div.col-9 {
      p.card-text {
        margin-bottom: 0;
        font-size: ${(props) => (props.$isMobile ? "90%" : "initial")};
        svg {
          margin-right: 0.5rem;
        }
      }
    }
    div.col-3 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: center;
    }
    div.col-3.route-img {
      img {
        width: 4rem !important;
        height: 4rem !important;
      }
    }
  }
`;

export const NoDataContainer = styled.div`
  margin-top: 3rem;
  text-align: center;
`;

const HomeComponent = (): JSX.Element => {
  const navigate = useNavigate();
  const { isMobile } = useNetworkStatus();

  const dispatch: Dispatch = useAppDispatch();
  const driverDataFromRedux: DriverData = useAppSelector<DriverData>(selectDriverData);
  const driverRoutesFromRedux: DriverRoute[] = useAppSelector<DriverRoute[]>(selectDriverRoutes);
  const driverPIN = useAppSelector<number>(selectDriverPinNumber);

  const [driverData, setDriverData] = React.useState<DriverData | null>(null);
  const [driverRoutes, setDriverRoutes] = React.useState<DriverRoute[] | null>(null);
  // console.log("driverRoutes:", driverRoutes);

  //* Driver's Info
  React.useEffect(() => {
    if (driverDataFromRedux && Object.keys(driverDataFromRedux)) {
      setDriverData(driverDataFromRedux);
    }
  }, [driverDataFromRedux]);

  React.useEffect(() => {
    if (driverPIN && driverData) {
      const driverToken = driverData?.token as string;
      const driverId = driverData?.id as string;
      // console.log({ driverToken, driverId });
      dispatch(getRoutesDriver(driverId, driverToken));
    }
  }, [dispatch, driverData, driverPIN]);

  //* Driver's Routes
  React.useEffect(() => {
    if (driverRoutesFromRedux) {
      setDriverRoutes(driverRoutesFromRedux);
    }
  }, [driverRoutesFromRedux]);

  const DriverInfoCard = (): JSX.Element => {
    const currentUser = {
      firstName: driverData?.first_name || "n/a",
      lastName: driverData?.last_name || "n/a",
      email: driverData?.email || "n/a",
    };

    return (
      <Card style={{ width: "100%" }} bg="secondary" text="white" className="card-shadow">
        <Card.Header>
          <FaRegAddressCard /> Driver info
        </Card.Header>
        <Card.Body>
          <div className="row">
            <div className="col-3">
              <HomeAvatar user={currentUser} />
            </div>
            <div className="col-9">
              <Card.Title>
                {driverData?.first_name || driverData?.last_name
                  ? `${driverData?.first_name} ${driverData?.last_name}`
                  : "n/a"}
              </Card.Title>
              <Card.Text>
                <FaLocationDot />
                {driverData?.street_address || "n/a"}
              </Card.Text>
              <Card.Text>
                <BiSolidCity />
                {driverData?.city || driverData?.state || driverData?.zip
                  ? `${driverData?.city || ""} ${driverData?.state || ""} ${driverData?.zip || ""}`
                  : "n/a"}
              </Card.Text>
              <Card.Text>
                <BsFillTelephoneFill />
                {driverData?.phone || "n/a"}
              </Card.Text>
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  };

  const DriverRoutesCard = (): JSX.Element => {
    return (
      <Card style={{ width: "100%" }} bg="secondary" text="white" className="card-shadow">
        <Card.Header>
          <FaRoute /> Today's Routes
        </Card.Header>
        <Card.Body>
          <div className="row">
            <div className="col-3 route-img">
              <Image src={routeIcon} />
            </div>
            <div className="col-9">
              {driverRoutes &&
                driverRoutes.map((elem: DriverRoute, index: number) => {
                  return (
                    <div key={index}>
                      <Card.Title>{elem?.block || "n/a"}</Card.Title>
                      <Card.Text>{elem?.route || "n/a"}</Card.Text>
                      <Card.Text>{elem?.report_time || "n/a"}</Card.Text>
                      {index < driverRoutes.length - 1 ? <hr style={{ borderColor: appBackgroundColor }} /> : <br />}
                    </div>
                  );
                })}
            </div>
          </div>
        </Card.Body>
        <Card.Footer>
          <Button
            variant="primary"
            className="w-100"
            size="sm"
            disabled={driverData && driverRoutes ? false : true}
            onClick={() => navigate("/route_info")}
          >
            Continue
          </Button>
        </Card.Footer>
      </Card>
    );
  };

  return (
    <React.Fragment>
      <HomeComponentContainer $isMobile={isMobile}>
        <TopInfoComponent fontSize="90%" />

        <div className="main-info">
          {driverData ? (
            <React.Fragment>
              {driverData ? <DriverInfoCard /> : null}

              {driverRoutes && driverRoutes.length ? (
                <DriverRoutesCard />
              ) : (
                <NoDataContainer>
                  <h2>No routes info</h2>
                </NoDataContainer>
              )}
            </React.Fragment>
          ) : (
            <NoDataContainer>
              <h2>No driver info</h2>
            </NoDataContainer>
          )}
        </div>
      </HomeComponentContainer>
    </React.Fragment>
  );
};

export default HomeComponent;
