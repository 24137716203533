import React from "react";
import { Polyline } from "react-leaflet";

import { useAppSelector } from "../redux/hooks";
import { selectChosenAgency, selectRouteInfo } from "../redux/selectors";
import { shapeArrayOperations } from "../utils/helpers";

const MapRouteDetails = (): JSX.Element => {
  const currentRouteDetails: RouteDetails = useAppSelector<RouteDetails>(selectRouteInfo);
  const chosenAgencyFromRedux: Agency = useAppSelector<Agency>(selectChosenAgency);
  // console.log("currentRouteDetails:", currentRouteDetails);

  const [shape, setShape] = React.useState<Array<Line>>([]);
  // console.log("shape:", shape);

  React.useEffect(() => {
    if (currentRouteDetails && Object.keys(currentRouteDetails)?.length && chosenAgencyFromRedux) {
      const { agencyId } = chosenAgencyFromRedux;
      const selectedDirection = currentRouteDetails?.selectedDirection;
      // console.log({ selectedDirection });

      //* SweetWater only
      if (agencyId === ("33174" as string)) {
        const lineShape: Location[] = currentRouteDetails?.lineShape;
        // console.log({ lineShape, lineStops });
        const flatArrayLineShape: Line[] = shapeArrayOperations(lineShape);
        setShape(flatArrayLineShape);
      }

      //* Other agencies
      if (selectedDirection && agencyId !== ("33174" as string)) {
        const lineShape: Location[] = currentRouteDetails.lineShape.filter(
          (shape: { directionId: string }) => shape.directionId === selectedDirection
        );
        // console.log({ lineShape, lineStops });
        const flatArrayLineShape: Line[] = shapeArrayOperations(lineShape);
        setShape(flatArrayLineShape);
      }
    }
  }, [chosenAgencyFromRedux, currentRouteDetails]);

  //* Colors - no constants
  const directionOptions = { color: "slateblue", weight: 4 };
  const directionOptionsLight = { color: "brown", weight: 2 };

  const PathShape = (): JSX.Element => {
    return (
      <React.Fragment>
        {shape &&
          shape?.length >= 1 &&
          (shape.map((elem, index) => {
            return (
              <Polyline
                key={index}
                pathOptions={index === shape?.length - 1 ? directionOptions : directionOptionsLight}
                positions={elem}
              />
            );
          }) as JSX.Element[])}
      </React.Fragment>
    );
  };

  return <React.Fragment>{shape ? <PathShape /> : null}</React.Fragment>;
};

export default MapRouteDetails;
