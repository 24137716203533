import React from "react";
import styled from "styled-components";

import variableColors from "../_App.module.scss";

const { appBackgroundColor } = variableColors;

interface IUser {
  firstName: string;
  lastName: string;
  email: string;
}

interface IProps {
  user: IUser;
}

const Avatar = styled.div`
  color: ${appBackgroundColor};
  width: 4rem !important;
  height: 4rem !important;
  line-height: 4rem;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  border-radius: 100%;
  margin-left: auto;
  margin-right: auto;
`;

function mapHSL(value: number, start1: number, stop1: number, start2: number, stop2: number): number {
  return ((value - start1) / (stop1 - start1)) * (stop2 - start2) + start2;
}

const getByteLength = (string: string): number => {
  const byteLength = new TextEncoder().encode(string[0])[0];
  // console.log({ byteLength });
  return byteLength;
};

const minCharByteValue: number = getByteLength("a");
const maxCharByteValue: number = getByteLength("z");
// console.log({ minCharByteValue, maxCharByteValue });

const minRange: number = minCharByteValue / maxCharByteValue;
const maxRange: number = 1;
// console.log({ minRange, maxRange });

const initials = (_firstName: string, _lastName: string) => (_firstName[0] + _lastName[0]).toUpperCase();

const colorByUser = ({ firstName, lastName }: IUser): string => {
  const userValue = getByteLength(firstName[0].toLowerCase()) / getByteLength(lastName[0].toLowerCase());
  const avatarColor = `hsl(${mapHSL(userValue, minRange, maxRange, 0, 360)},50%,50%)`;
  // console.log({ userValue, avatarColor });
  return avatarColor;
};

const HomeAvatar: React.FC<IProps> = ({ user: { firstName, lastName, email } }): JSX.Element => {
  // console.log("initials(firstName, lastName:", initials(firstName, lastName));
  return (
    <Avatar style={{ backgroundColor: colorByUser({ firstName, lastName, email }) }}>{initials(firstName, lastName)}</Avatar>
  );
};

export default HomeAvatar;
