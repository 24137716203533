import React from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import { appName, logo } from "../config";

const Header = (): JSX.Element => {
  const [expanded, setExpanded] = React.useState<boolean>(false);
  // console.log({ expanded });

  const toggleExpanded = () => setExpanded(!expanded);
  const hideMenu = () => setExpanded(false);

  return (
    <React.Fragment>
      <Navbar expand="lg" bg="dark" variant="dark" expanded={expanded} onToggle={toggleExpanded}>
        <div className="container-fluid">
          <Navbar.Brand as={NavLink} to="/" onClick={hideMenu}>
            <img src={logo} alt="Company Logo" height={"23"} width={"auto"} style={{ marginRight: "0.5rem" }} />
            {appName}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbar-menu" />

          <Navbar.Collapse id="navbar-menu">
            <Nav className="me-auto">
              <Nav.Link as={NavLink} to="/" onClick={hideMenu}>
                Home
              </Nav.Link>
              <Nav.Link as={NavLink} to="/route_info" onClick={hideMenu}>
                Route Info
              </Nav.Link>

              <NavDropdown title="Post Data" id="navbar-menu-dropdown">
                <NavDropdown.Item as={NavLink} to="/pre_inspection" onClick={hideMenu}>
                  Initial inspection
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/leaving_yard" onClick={hideMenu}>
                  Leaving Yard
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/passenger_count" onClick={hideMenu}>
                  Passengers counting
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/return_to_yard" onClick={hideMenu}>
                  Return to Yard
                </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link as={NavLink} to="/timepoints" onClick={hideMenu}>
                Timepoints
              </Nav.Link>
              <Nav.Link as={NavLink} to="/map" onClick={hideMenu}>
                Map
              </Nav.Link>
              <Nav.Link as={NavLink} to="/settings" onClick={hideMenu}>
                Settings
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </React.Fragment>
  );
};

export default Header;
