import { createSelector } from "reselect";

//* selectAgencyInfo
const selectAgencyInfo = (state: RootState) => state?.agencyInfo;
// console.log("selectAgencyInfo:", selectAgencyInfo);

export const selectChosenAgency = createSelector([selectAgencyInfo], (agencyInfo) => {
  if (agencyInfo && agencyInfo?.chosenAgency && Object.keys(agencyInfo?.chosenAgency).length >= 1) {
    return agencyInfo.chosenAgency;
  }
});

export const selectAgencyId = createSelector([selectAgencyInfo], (agencyInfo) => {
  if (agencyInfo && agencyInfo?.chosenAgency && Object.keys(agencyInfo?.chosenAgency).length >= 1) {
    return agencyInfo.chosenAgency?.agencyId;
  }
});

export const selectMapExtents = createSelector([selectAgencyInfo], (agencyInfo) => {
  if (agencyInfo && agencyInfo?.extent && Object.keys(agencyInfo?.extent).length >= 1) {
    return agencyInfo.extent;
  }
});
// console.log("selectMapExtents:", selectMapExtents);

export const selectShowInformationModal = createSelector([selectAgencyInfo], (agencyInfo) => {
  return agencyInfo?.showInformationModal;
});

export const selectCredentials = createSelector([selectAgencyInfo], (agencyInfo) => {
  if (agencyInfo && agencyInfo?.credentials) {
    return agencyInfo.credentials;
  }
});

export const selectLoginStatus = createSelector([selectAgencyInfo], (agencyInfo) => {
  return agencyInfo?.loginStatus;
});

export const selectShowInitialModal = createSelector([selectAgencyInfo], (agencyInfo) => {
  return agencyInfo?.showInitialModal;
});

export const selectGrayMap = createSelector([selectAgencyInfo], (agencyInfo) => {
  return agencyInfo?.grayMap;
});

export const selectShowTooltip = createSelector([selectAgencyInfo], (agencyInfo) => {
  return agencyInfo?.showTooltip;
});

export const selectSetMapAutorotation = createSelector([selectAgencyInfo], (agencyInfo) => {
  return agencyInfo?.isMapAutoRotated;
});

export const selectDriverData = createSelector([selectAgencyInfo], (agencyInfo) => {
  if (
    agencyInfo &&
    Object.keys(agencyInfo).length >= 1 &&
    agencyInfo?.driverData &&
    Object.keys(agencyInfo?.driverData)?.length >= 1
  ) {
    return agencyInfo?.driverData;
  }
});

export const selectDriverRoutes = createSelector([selectAgencyInfo], (agencyInfo) => {
  if (agencyInfo && Object.keys(agencyInfo).length >= 1 && agencyInfo?.driverRoutes) {
    return agencyInfo?.driverRoutes;
  }
});

export const selectScheduleDetails = createSelector([selectAgencyInfo], (agencyInfo) => {
  if (agencyInfo && Object.keys(agencyInfo).length >= 1 && agencyInfo?.scheduleDetails) {
    return agencyInfo?.scheduleDetails;
  }
});

export const selectScheduleDriverToday = createSelector([selectAgencyInfo], (agencyInfo) => {
  if (
    agencyInfo &&
    Object.keys(agencyInfo).length >= 1 &&
    agencyInfo?.scheduleDriverData &&
    Object.keys(agencyInfo?.scheduleDriverData)?.length >= 1
  ) {
    return agencyInfo?.scheduleDriverData;
  }
});

export const selectDriverPinNumber = createSelector([selectAgencyInfo], (agencyInfo) => {
  if (agencyInfo && Object.keys(agencyInfo).length >= 1 && agencyInfo?.driverPIN_Number) {
    return agencyInfo?.driverPIN_Number;
  }
});

//* selectBusInfo
const selectBusesInfo = (state: RootState) => state?.busesInfo;

export const selectFromBusesActiveArray = createSelector([selectBusesInfo], (busesInfo) => {
  if (busesInfo && busesInfo?.busesArrayActive) {
    return busesInfo.busesArrayActive;
  }
});

export const selectFromBusesNonActiveArray = createSelector([selectBusesInfo], (busesInfo) => {
  if (busesInfo && busesInfo?.busesArrayNonActive) {
    return busesInfo.busesArrayNonActive;
  }
});

//* selectCurrentBus
const selectCurrentBus = (state: RootState) => state?.currentBusInfo;

export const selectCurrentBusId = createSelector([selectCurrentBus], (currentBusInfo) => {
  if (
    currentBusInfo &&
    Object.keys(currentBusInfo).length >= 1 &&
    currentBusInfo?.currentBusObject &&
    Object.keys(currentBusInfo?.currentBusObject)?.length >= 1
  ) {
    return currentBusInfo?.currentBusObject;
  }
});

export const selectCurrentBusData = createSelector([selectCurrentBus], (currentBusInfo) => {
  if (
    currentBusInfo &&
    Object.keys(currentBusInfo).length >= 1 &&
    currentBusInfo?.currentBusIdData &&
    Object.keys(currentBusInfo?.currentBusIdData)?.length >= 1
  ) {
    return currentBusInfo?.currentBusIdData;
  }
});

export const selectPreTripInspectionData = createSelector([selectCurrentBus], (currentBusInfo) => {
  if (
    currentBusInfo &&
    Object.keys(currentBusInfo).length >= 1 &&
    currentBusInfo?.preTripInspectionData &&
    currentBusInfo?.preTripInspectionData?.length >= 1
  ) {
    return currentBusInfo?.preTripInspectionData;
  }
});

export const selectPreInspectionResponseData = createSelector([selectCurrentBus], (currentBusInfo) => {
  if (
    currentBusInfo &&
    Object.keys(currentBusInfo).length >= 1 &&
    currentBusInfo?.preInspectionResponseData &&
    Object.keys(currentBusInfo?.preInspectionResponseData)?.length >= 1
  ) {
    return currentBusInfo?.preInspectionResponseData;
  }
});

export const selectCheckInTime = createSelector([selectCurrentBus], (currentBusInfo) => {
  if (currentBusInfo && Object.keys(currentBusInfo).length >= 1 && currentBusInfo?.checkInTime) {
    return currentBusInfo?.checkInTime;
  }
});

export const selectDriverManifestMovilId = createSelector([selectCurrentBus], (currentBusInfo) => {
  if (currentBusInfo && Object.keys(currentBusInfo).length >= 1 && currentBusInfo?.driverManifestMovilId) {
    return currentBusInfo?.driverManifestMovilId;
  }
});

//* selectRouteTripDetails
const selectRouteTripDetails = (state: RootState) => state?.currentRouteTripDetails;

export const selectRouteInfo = createSelector([selectRouteTripDetails], (currentRouteTripDetails) => {
  if (
    currentRouteTripDetails &&
    currentRouteTripDetails?.routeDetails &&
    Object.keys(currentRouteTripDetails?.routeDetails).length >= 1
  ) {
    return currentRouteTripDetails?.routeDetails;
  }
});

export const selectTripInfo = createSelector([selectRouteTripDetails], (currentRouteTripDetails) => {
  if (
    currentRouteTripDetails &&
    currentRouteTripDetails?.tripInfo &&
    Object.keys(currentRouteTripDetails?.tripInfo).length >= 1
  ) {
    return currentRouteTripDetails?.tripInfo;
  }
});

export const selectCalculatedTripStatus = createSelector([selectRouteTripDetails], (currentRouteTripDetails) => {
  if (
    currentRouteTripDetails &&
    currentRouteTripDetails?.calculatedTripStatus &&
    Object.keys(currentRouteTripDetails?.calculatedTripStatus).length >= 1
  ) {
    return currentRouteTripDetails?.calculatedTripStatus;
  }
});

export const selectTripProgressBarData = createSelector([selectRouteTripDetails], (currentRouteTripDetails) => {
  if (
    currentRouteTripDetails &&
    currentRouteTripDetails?.tripProgressBarData &&
    currentRouteTripDetails?.tripProgressBarData.length >= 1
  ) {
    return currentRouteTripDetails?.tripProgressBarData;
  }
});
