import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Button, ButtonGroup, Form, Table } from "react-bootstrap";

import { ComponentContainer } from "../Settings";
import TopInfoComponent from "../../layout/TopInfoComponent";
import useNetworkStatus from "../../services/useNetworkStatus";
import CommonTopInfoComponent, { CommonTopInfoComponentContainer } from "./CommonTopInfoComponent";
import { useAppSelector } from "../../redux/hooks";
import {
  selectCurrentBusData,
  selectDriverData,
  selectDriverManifestMovilId,
  selectScheduleDriverToday,
  selectTripInfo,
} from "../../redux/selectors";
import variableColors from "../../_App.module.scss";
import { createCountPassenger, infoNotify } from "../../utils/helpers";
import InternalReusableInputComponent from "./InternalReusableInputComponent";

const { dangerColor, successColor } = variableColors;

const PassengerCountContainer = styled(ComponentContainer)`
  background-color: inherit;
  table.table-sm.table-hover.table-striped.table-bordered {
    text-align: center;
    vertical-align: middle;
    thead {
      font-size: small;
    }
  }
`;

const PassengersCountFormContainer = styled(CommonTopInfoComponentContainer)`
  background-color: inherit;
  div.grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 0 1rem;
    max-width: 800px;
    margin: 0 auto;
    padding: 0;
    div.passengers-update {
      margin-top: 0;
      margin-bottom: 0.5rem !important;
      label.label-on {
        border-bottom: 1px solid ${successColor};
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        margin-bottom: 0.5rem !important;
        width: 100%;
        text-align: center;
        font-size: 85%;
      }
      label.label-off {
        border-bottom: 1px solid ${dangerColor};
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        margin-bottom: 0.5rem !important;
        width: 100%;
        text-align: center;
        font-size: 85%;
      }
      input {
        border-top: none !important;
        border-radius: 0;
      }
      input#current_stop_name_id {
        border-radius: var(--bs-border-radius);
      }
    }
  }
  div.btn-group-vertical {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;

const PassengersCount = (): JSX.Element => {
  const { isMobile } = useNetworkStatus();
  const navigate = useNavigate();

  const initialStopState: PassengersCreateUpdate = { amb: 0, str: 0, wc: 0, amb_down: 0, str_down: 0, wc_down: 0 };

  //* Data from Redux
  const driverData: DriverData = useAppSelector<DriverData>(selectDriverData); // Driver Data eg, id, name, email
  const scheduleDriverTodayFromRedux: RootState = useAppSelector<RootState>(selectScheduleDriverToday);
  const currentBusDataFromRedux: BusInfo = useAppSelector<BusInfo>(selectCurrentBusData); // Bus info from TC, eg: nextStopId, nextStopName
  const driverManifestMovilIdFromRedux: number = useAppSelector<number>(selectDriverManifestMovilId); // Id: 4-digits - the same
  const currentTripInfoFromRedux: TripInfo = useAppSelector<TripInfo>(selectTripInfo);

  //* Local State
  const [driverBusData, setDriverBusData] = React.useState<DriverBusData | null>(null); // {token, driverId, routeBlockId}
  const [stopState, setStopState] = React.useState<PassengersCreateUpdate>(initialStopState);
  const [driverManifestMovilId, setDriverManifestMovilId] = React.useState<number | null>(() => {
    if (localStorage.getItem("driverManifestMovilId")) {
      return Number(localStorage.getItem("driverManifestMovilId") as string);
    }
    return null;
  }); // Id: 4-digits - the same;
  const [tableData, setTableData] = React.useState<PassengersCreateUpdate[]>(() => {
    if (localStorage.getItem("tableData")) {
      const savedTableData = localStorage.getItem("tableData");
      return JSON.parse(savedTableData as string);
    }
    return [];
  }); // tableData
  // console.log("tableData:", tableData);

  // Button plus
  const handleIncrement = (key: keyof PassengersCreateUpdate): void => {
    setStopState((prevState: PassengersCreateUpdate) => ({
      ...prevState,
      [key]: (prevState[key] as number) + 1,
    }));
  };

  // Button minus
  const handleDecrement = (key: keyof PassengersCreateUpdate): void => {
    setStopState((prevState: PassengersCreateUpdate) => ({
      ...prevState,
      [key]: Math.max((prevState[key] as number) - 1, 0),
    }));
  };

  const saveStopsAndPostToApi = async (): Promise<void> => {
    const dataToSend: PassengersCreateUpdate = {
      ...stopState,
      created_at: new Date().toISOString(),
      driver_manifest_movil_id: driverManifestMovilId as number,
      id: currentBusDataFromRedux?.nextStopId ?? 0,
      latitude: String(currentBusDataFromRedux?.loc?.lat) || null,
      longitude: String(currentBusDataFromRedux?.loc?.lon) || null,
      stop:
        currentTripInfoFromRedux?.schedule?.findIndex(
          (stop: Schedule) => stop?.stopId === currentBusDataFromRedux?.nextStopId
        ) + 1 ||
        tableData?.length + 1 ||
        1,
    };
    // console.log("dataToSend:", dataToSend);
    await setTableData([...tableData, dataToSend]);

    try {
      const postResponse = await createCountPassenger(dataToSend, driverBusData?.token!);
      if (postResponse?.status === 200 || postResponse?.status === 201) {
        // console.log("postResponse?.status:", postResponse?.status);
        await infoNotify("success", "The data has been sent");
      } else {
        await infoNotify("error", "The data has NOT been sent");
      }
    } catch (error) {
      console.log("error:", error);
      await infoNotify("error", "The data has NOT been sent");
    } finally {
      setStopState(initialStopState);
    }
  };

  //* Set driverManifestMovilId to localStorage and localState
  React.useEffect(() => {
    if (driverManifestMovilIdFromRedux) {
      // console.log("driverManifestMovilIdFromRedux:", driverManifestMovilIdFromRedux);
      setDriverManifestMovilId(driverManifestMovilIdFromRedux);
      localStorage.setItem("driverManifestMovilId", String(driverManifestMovilIdFromRedux));
    }
  }, [driverManifestMovilIdFromRedux]);

  //* Initial info state set
  React.useEffect(() => {
    if (driverData && scheduleDriverTodayFromRedux && driverManifestMovilId) {
      const token = driverData?.token;
      const driverId = driverData?.id;
      const routeBlockId = Number(scheduleDriverTodayFromRedux?.routeblock?.route_id);
      setDriverBusData({ token, driverId, routeBlockId });
    }
  }, [driverData, driverManifestMovilId, scheduleDriverTodayFromRedux]);

  //* Set tableData to the localStorage
  React.useEffect(() => {
    if (tableData?.length) {
      localStorage.setItem("tableData", JSON.stringify(tableData));
    }
  }, [tableData]);

  //* Current Stop
  const CurrentStopInfo = (): JSX.Element => {
    return (
      <fieldset disabled>
        <Form.Group className="passengers-update">
          <Form.Label htmlFor="current_stop_name_id">Current Stop: Name (ID)</Form.Label>
          <Form.Control
            id="current_stop_name_id"
            placeholder="n/a"
            //* Must be to change defaultValue
            key={
              (currentBusDataFromRedux &&
                `${currentBusDataFromRedux?.nextStopName} (ID: ${currentBusDataFromRedux?.nextStopId})`) ||
              "n/a"
            }
            defaultValue={
              (currentBusDataFromRedux &&
                `${currentBusDataFromRedux?.nextStopName} (ID: ${currentBusDataFromRedux?.nextStopId})`) ||
              "n/a"
            }
            readOnly={true}
          />
        </Form.Group>
        <br />
      </fieldset>
    );
  };

  //* Buttons+/- and Form
  const ButtonsPlusMinus = (): JSX.Element => {
    return (
      <React.Fragment>
        <div className="grid-container">
          {/* //* First Row */}
          <InternalReusableInputComponent
            label="Amb On"
            value={Number(stopState?.amb)}
            onIncrement={() => handleIncrement("amb")}
            onDecrement={() => handleDecrement("amb")}
            minValue={0}
            className="label-on"
          />
          <InternalReusableInputComponent
            label="Amb Off"
            value={Number(stopState?.amb_down)}
            onIncrement={() => handleIncrement("amb_down")}
            onDecrement={() => handleDecrement("amb_down")}
            minValue={0}
            className="label-off"
          />

          {/* //* Second Row */}
          <InternalReusableInputComponent
            label="W/C On"
            value={Number(stopState?.wc)}
            onIncrement={() => handleIncrement("wc")}
            onDecrement={() => handleDecrement("wc")}
            minValue={0}
            className="label-on"
          />
          <InternalReusableInputComponent
            label="W/C Off"
            value={Number(stopState?.wc_down)}
            onIncrement={() => handleIncrement("wc_down")}
            onDecrement={() => handleDecrement("wc_down")}
            minValue={0}
            className="label-off"
          />

          {/* //* Third Row */}
          <InternalReusableInputComponent
            label="Bikes On (str)"
            value={Number(stopState?.str)}
            onIncrement={() => handleIncrement("str")}
            onDecrement={() => handleDecrement("str")}
            minValue={0}
            className="label-on"
          />
          <InternalReusableInputComponent
            label="Bikes Off (str)"
            value={Number(stopState?.str_down)}
            onIncrement={() => handleIncrement("str_down")}
            onDecrement={() => handleDecrement("str_down")}
            minValue={0}
            className="label-off"
          />
        </div>
      </React.Fragment>
    );
  };

  //* Table
  const PassengersCountTable = (): JSX.Element => {
    return (
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>Amb On</th>
            <th>W/C On</th>
            <th>Bikes On</th>
            <th>Amb Off</th>
            <th>W/C Off</th>
            <th>Bikes Off</th>
          </tr>
        </thead>
        <tbody>
          {tableData?.map((elem: PassengersCreateUpdate, index: number) => {
            return (
              <tr key={index}>
                <td className="span_bold">{index + 1}</td>
                <td>{elem?.amb}</td>
                <td>{elem?.wc}</td>
                <td>{elem?.str}</td>
                <td>{elem?.amb_down}</td>
                <td>{elem?.wc_down}</td>
                <td>{elem?.str_down}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  return (
    <React.Fragment>
      <PassengerCountContainer $isMobile={isMobile}>
        <TopInfoComponent fontSize="90%" />
        <CommonTopInfoComponent />

        <PassengersCountFormContainer>
          {CurrentStopInfo()}
          {ButtonsPlusMinus()}

          <ButtonGroup vertical={true} className="w-100">
            <Button type="button" variant="primary" onClick={saveStopsAndPostToApi} disabled={driverBusData ? false : true}>
              Proceed to Next Stop
            </Button>
            <Button
              type="button"
              onClick={() => navigate("/return_to_yard")}
              disabled={tableData?.length === 0 ? true : false}
              variant="warning"
            >
              Continue (go to Return to the Yard)
            </Button>
            <Button
              type="button"
              onClick={() => {
                localStorage.removeItem("tableData");
                localStorage.removeItem("driverManifestMovilId");
                setTableData([]);
              }}
              disabled={tableData?.length === 0 ? true : false}
              variant="danger"
            >
              Clear table
            </Button>
          </ButtonGroup>
        </PassengersCountFormContainer>

        {tableData?.length >= 1 ? PassengersCountTable() : null}
      </PassengerCountContainer>
    </React.Fragment>
  );
};

export default PassengersCount;
