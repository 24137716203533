import React from "react";
import styled from "styled-components";

import { dynamicTextColor } from "../utils/helpers";
import variableColors from "../_App.module.scss";

const { appBackgroundColor, appBackgroundColorWhiteSmoke, darkColor } = variableColors;

const OuterContainer = styled.div`
  width: 100%;
  height: 140px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

const ProgressContainer = styled.div`
  width: 140px;
  height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  transform: rotate(-90deg);
`;

const TextContainer = styled.div<{ $width: string; $alignItems: string; $transform: string }>`
  height: 140px;
  width: ${(props) => props.$width};
  background-color: ${appBackgroundColor};
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: ${(props) => props.$alignItems};
  align-content: center;
  transform: ${(props) => props.$transform};
  margin: -0.5rem;
  font-size: 90%;
`;

const TimePointsCustomProgressBar = ({
  bgColor,
  textColor,
  progressBarStops,
  percent,
}: {
  bgColor: string;
  textColor: string;
  progressBarStops: {
    previousStop: Schedule;
    nextStop: Schedule;
  };
  percent: number;
}): JSX.Element => {
  const { previousStop, nextStop } = progressBarStops;

  const ProgressBar = ({ bgColor, percent }: { bgColor: string; percent: number }): JSX.Element => {
    //* ProgressBar unfilled
    const containerStyles = {
      border: `1px solid ${darkColor}`,
      height: "1rem",
      width: "calc(100% - 2rem)",
      backgroundColor: appBackgroundColorWhiteSmoke,
      borderRadius: "0px",
      position: "relative" as const,
    };

    //* ProgressBar filled
    const fillerStyles = {
      height: "100%",
      width: `${percent}%`,
      backgroundColor: bgColor,
      borderRadius: "0px 280px 280px 0px",
      transition: "width 1s ease-in-out",
      display: "flex",
      flexDirection: "row" as const,
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
    };
    //* ProgressBar label
    const labelStyles = {
      color: `${dynamicTextColor(bgColor)}`,
      fontWeight: "bold",
      lineHeight: "1rem",
      fontSize: "1rem",
      zIndex: 9,
    };

    const ProgressCircle = ({
      location,
      textColor,
      background,
    }: {
      location: string;
      textColor: string;
      background: string;
    }): JSX.Element => {
      const circleStyles = {
        display: "flex",
        position: "absolute" as const,
        left: `calc(${location} - 1rem)`,
        width: "2rem",
        height: "2rem",
        background: background,
        top: "-0.5rem",
        borderRadius: "50px",
        justifyContent: "center",
        alignItems: "center",
        color: textColor,
        opacity: 0.75,
        transform: "rotate(90deg)",
      };
      return <div style={circleStyles}>{""}</div>;
    };

    return (
      <div style={containerStyles}>
        <div style={fillerStyles}>
          <ProgressCircle location={"0%"} textColor={textColor} background={bgColor} />
          <ProgressCircle
            location={"100%"}
            textColor={textColor}
            background={percent >= 90 ? bgColor : appBackgroundColorWhiteSmoke}
          />
          <span style={labelStyles}>{`${percent.toFixed(1)}%`}</span>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <OuterContainer>
        <ProgressContainer>
          <ProgressBar bgColor={bgColor} percent={percent} />
          <TextContainer $width="100%" $alignItems="flex-start" $transform="rotate(90deg) translateX(20px)">
            <div>{previousStop?.stopName || "n/a"}</div>
            <div>{nextStop?.stopName || "n/a"}</div>
          </TextContainer>

          <TextContainer $width="100%" $alignItems="flex-end" $transform="rotate(90deg) translateX(10px)">
            <div>
              ({previousStop?.stopId || "n/a"}) {previousStop?.departureTime || "n/a"}
            </div>
            <div>
              ({nextStop?.stopId || "n/a"}) {nextStop?.departureTime || "n/a"}
            </div>
          </TextContainer>
        </ProgressContainer>
      </OuterContainer>
    </React.Fragment>
  );
};

export default TimePointsCustomProgressBar;
