import React from "react";
import { Button, Modal } from "react-bootstrap";
import styled from "styled-components";
import { Select } from "antd";

import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { selectCurrentBusId, selectFromBusesActiveArray, selectFromBusesNonActiveArray } from "../redux/selectors";
import { showInitialModal } from "../redux/actions";

export const SelectLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  gap: 1rem;
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
`;

const InitialModal = ({
  showChangeBusModal,
  setCurrentBusId,
}: {
  showChangeBusModal: boolean;
  setCurrentBusId: React.Dispatch<React.SetStateAction<string>>;
}): JSX.Element => {
  const dispatch: Dispatch = useAppDispatch();

  const busesArrayActiveFromRedux: BusArrayObject[] = useAppSelector<BusArrayObject[]>(selectFromBusesActiveArray);
  const busesArrayNonActiveFromRedux: BusArrayObject[] = useAppSelector<BusArrayObject[]>(selectFromBusesNonActiveArray);
  const currentBusIdFromRedux: BusArrayObject = useAppSelector<BusArrayObject>(selectCurrentBusId);
  // console.log({ currentBusIdFromRedux });

  const [busesArrayActive, setBusesArrayActive] = React.useState<BusArrayObject[]>([]);
  const [busesArrayNonActive, setBusesArrayNonActive] = React.useState<BusArrayObject[]>([]);
  const [selectedBusId, setSelectedBusId] = React.useState<string>(currentBusIdFromRedux?.busId || "");
  // console.log("selectedBusId:", selectedBusId);

  const onBusIdChange = (busId: React.SetStateAction<string>) => {
    // console.log({ busId });
    setSelectedBusId(busId);
  };

  //* Close this modal
  const closeInitialModal = () => {
    dispatch(showInitialModal(false));
  };

  //* Set CurrentBusId from Redux
  React.useEffect(() => {
    if (currentBusIdFromRedux) {
      setSelectedBusId(currentBusIdFromRedux?.busId);
    }
  }, [currentBusIdFromRedux]);

  React.useEffect(() => {
    if (busesArrayActiveFromRedux && busesArrayNonActiveFromRedux) {
      setBusesArrayActive(busesArrayActiveFromRedux);
      setBusesArrayNonActive(busesArrayNonActiveFromRedux);
    }
  }, [busesArrayActiveFromRedux, busesArrayNonActiveFromRedux]);

  return (
    <React.Fragment>
      <Modal
        show={showChangeBusModal}
        fullscreen={"lg-down"}
        onHide={closeInitialModal}
        dialogClassName=""
        centered={true}
        backdrop={false}
        keyboard={true}
      >
        <Modal.Header closeButton={true} closeLabel="Close">
          <Modal.Title>Select Bus Id</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SelectLine>
            {selectedBusId ? "Selected:" : "Select a Vehicle:"}
            <Select
              status={!busesArrayActive.length && !busesArrayNonActive.length ? "error" : ""}
              showArrow={true}
              style={{ maxHeight: "500px", overflowY: "scroll", width: "200px" }}
              allowClear={true}
              showSearch={true}
              placeholder={`Select a Vehicle (${busesArrayActive.length}+${busesArrayNonActive.length})`}
              optionFilterProp="children"
              defaultValue={selectedBusId ? selectedBusId : null}
              value={selectedBusId ? selectedBusId : null}
              onChange={onBusIdChange}
              options={
                busesArrayActive.length
                  ? [
                      {
                        label: "Active Buses",
                        options: busesArrayActive,
                      },
                    ]
                  : [
                      {
                        label: "Active Buses",
                        options: busesArrayActive,
                      },
                      {
                        label: "Not Active Buses",
                        options: busesArrayNonActive,
                      },
                    ]
              }
            />
          </SelectLine>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={closeInitialModal}>
            Cancel
          </Button>
          <Button
            disabled={selectedBusId ? false : true}
            variant="primary"
            onClick={() => {
              closeInitialModal();
              setCurrentBusId(selectedBusId);
            }}
          >
            Select
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default InitialModal;
