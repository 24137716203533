import React from "react";
import { Outlet } from "react-router-dom";

import Header from "./Header";
import FooterInfoComponent from "./FooterInfoComponent";

//* Layout -> for all pages
const Layout = (): JSX.Element => {
  return (
    <React.Fragment>
      <Header />
      <Outlet />
      <FooterInfoComponent />
    </React.Fragment>
  );
};

export default Layout;
